import React from 'react'
import {
  BoxProps,
  makeStyles,
  Box,
} from '@material-ui/core'

import Theme from 'src/theme/theme'

const useStyles = makeStyles((theme: typeof Theme) => ({
  container: {
    width: '100%',
    maxHeight: `calc(100vh - ${theme.headerHeight} - ${theme.contentPadding} * 2px)`,
    borderRadius: '4px',
  },
}))

interface IProps extends BoxProps {
  children: React.ReactNode
  className?: string
}

const Container = ({
  children,
  className,
  ...boxProps
}: IProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Box
      boxShadow={3}
      className={
        window.isNullish(className)
          ? classes.container
          : `${classes.container} ${className}`
      }
      {...boxProps}
    >
      {children}
    </Box>
  )
}

export default React.memo(Container)
