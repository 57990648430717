/**
 * @field args [string | object]
 * string type value will have default isRequired validation,
 * to make different validation config or disable it
 * change type to object, {type: string, validation: [validation callbacks or empty to disable validation]}
 */

export const functionsDefinitions = {
  add: (type) => ({
    args: [type],
    returnType: type,
  }),
  subtract: (type) => ({
    args: [type],
    returnType: type,
  }),
  multiply: (type) => ({
    args: [type],
    returnType: type,
  }),
  divide: (type) => ({
    args: [type],
    returnType: type,
  }),
  concat: (type) => ({
    args: [type],
    returnType: type,
  }),
  slice: (type) => ({
    args: ['number', {
      type: 'number',
      validation: [],
    }],
    returnType: type,
  }),
  'add date': () => ({
    args: ['date-time'],
    returnType: 'date-time',
  }),
  'subtract date': () => ({
    args: ['date-time'],
    returnType: 'date-time',
  }),
}

export const functionTypes = {
  number: ['add', 'subtract', 'multiply', 'divide'],
  string: ['concat', 'slice'],
  object: {
    Coordinate: [],
  },
  date: ['add date', 'subtract date'],
}
