import Rete from '@obvious.tech/rete'

import {
  sockets,
  definitions,
} from '../../..'
import {
  getSocketFromType,
} from '../../../Sockets/utils'
import {
  getPropertiesKeysByType,
  isObjectType,
} from '../../../../../helpers/EntityHelper'
import SelectControl from '../../../Controls/SelectControl'

export default class ArraySortByComponent extends Rete.Component {
  constructor () {
    super('Sort')
    this.typeOrder = [
      {
        value: 'inc',
        label: 'Order Increase',
      },
      {
        value: 'dec',
        label: 'Order Decrease',
      },
      {
        value: 'by',
        label: 'Order By (Closest)',
      },
    ]
    this.contextMenuPath = ['Arrays']
    this.task = {
      outputs: {
        res: 'output',
      },
    }
    this.arrayInput = {
      inputKey: 'sortByList',
      get: (node) => node.inputs.get(this.arrayInput.inputKey),
      changeName: (node, name = 'Entity[]') =>
        (this.arrayInput.get(node).name = name),
      reteInput: (inputName = 'Entity[]') =>
        new Rete.Input(
          this.arrayInput.inputKey,
          inputName,
          sockets.arraySocket,
        ),
    }
  }

  handleTypeOrder = (node, entity) => (value) => {
    node.data.type = value
    if (!value || !entity) return
    node.controls.forEach((c) => {
      if (c.key === 'type') return
      node.removeControl(c)
    })
    if (value === 'by') {
      const defaultValue = node.data?.by
      const onChange = this.handleInputs(
        node,
        definitions.entities[entity].properties,
      )

      const options = definitions.entities[entity].properties
        .filter((i) => {
          const isArrayType = i.type === 'array'
          const isObject = isObjectType(i.type) && i.name !== 'location'
          return !isArrayType && !isObject
        })
        .map((p) => ({
          value: p.name,
          label: p.name,
        }))

      node.addControl(
        new SelectControl(
          this.editor,
          node,
          'by',
          'OrderBy',
          onChange,
          options,
          defaultValue,
          false,
        ),
      )

      if (defaultValue) {
        onChange(defaultValue)
      }
    } else {
      node.inputs.forEach((i) => {
        if (['iAct', this.arrayInput.inputKey].includes(i.key)) return
        i.connections.forEach((connection) =>
          this.editor.removeConnection(connection),
        )
        node.removeInput(i)
      })
      const options = getPropertiesKeysByType(definitions.entities, entity).map(
        (p) => ({
          value: p,
          label: p,
        }),
      )
      node.addControl(
        new SelectControl(
          this.editor,
          node,
          value === 'inc' ? 'inc' : 'dec',
          value === 'inc' ? 'OrderInc' : 'OrderDec',
          () => {},
          options,
        ),
      )
    }
    setTimeout(() => {
      node.update()
      this.editor.view.updateConnections({
        node,
      })
    })
  }

  handleInputs = (node, properties) => (value) => {
    node.data[node.data.type] = value
    const property = properties.find((p) => p.name === value)
    node.inputs.forEach((i) => {
      if (['iAct', this.arrayInput.inputKey].includes(i.key)) return
      i.connections.forEach((connection) =>
        this.editor.removeConnection(connection),
      )
      node.removeInput(i)
    })

    if (!property) return
    node.addInput(
      new Rete.Input(
        value,
        value,
        getSocketFromType(property.type, property.entity, property.format),
      ),
    )
    node.update()
    setTimeout(() => {
      this.editor.view.updateConnections({
        node,
      })
    })
  }

  builder (node) {
    if (!node.data.entity) {
      node.addInput(this.arrayInput.reteInput('Entity[]'))
    } else {
      const out2 = new Rete.Output(
        'res',
        'Result',
        sockets[node.data.entity + 'ArraySocket'],
      )

      node
        .addInput(this.arrayInput.reteInput(`${node.data.entity}[]`))
        .addOutput(out2)
        .addControl(
          new SelectControl(
            this.editor,
            node,
            'type',
            'TypeOrder',
            this.handleTypeOrder(node, node.data.entity),
            this.typeOrder,
            '',
            false,
          ),
        )

      const properties = definitions.entities[node.data.entity].properties
      this.handleInputs(node, properties)(node.data[node.data.type])
      this.handleTypeOrder(node, node.data.entity)(node.data.type)
    }
  }

  building = (node, entity) => {
    if (entity !== node.data.entity) {
      if (!sockets[entity + 'ArraySocket']) return
      this.arrayInput.changeName(node, `${entity}[]`)
      node.data.entity = entity
      node
        .addOutput(
          new Rete.Output('res', 'Result', sockets[entity + 'ArraySocket']),
        )
        .addControl(
          new SelectControl(
            this.editor,
            node,
            'type',
            'TypeOrder',
            this.handleTypeOrder(node, entity),
            this.typeOrder,
            '',
            false,
          ),
        )
      this.handleTypeOrder(node, entity)(node.data.type)
    }

    this.reDrawNode(node)
  }

  connected = (connection) => {
    if (connection.input.key === this.arrayInput.inputKey) {
      setTimeout(() => {
        this.building(
          connection.input.node,
          connection.output.socket.name.split('Array')[0],
        )
      })
    }
  }

  disconnected = (connection) => {
    if (connection.input.key === this.arrayInput.inputKey) {
      setTimeout(() => {
        this.resetNode(connection.input.node)
      })
    }
  }

  removeEntityFromData (node) {
    delete node.data.entity
  }

  resetControls (node) {
    node.controls.forEach((o) => {
      node.removeControl(o)
    })
    this.removeEntityFromData(node)
  }

  resetOutputs (node) {
    node.outputs.forEach((o) => {
      if (['action', 'done'].includes(o.key)) return
      o.connections.forEach((connection) =>
        this.editor.removeConnection(connection),
      )
      node.removeOutput(o)
    })
    this.removeEntityFromData(node)
  }

  resetNode (node) {
    this.resetInputs(node)
    this.resetControls(node)
    this.resetOutputs(node)
    this.reDrawNode(node)
  }

  resetInputs (node) {
    this.arrayInput.changeName(node, 'Entity[]')
    node.inputs.forEach((input) => {
      input.connections.forEach((connection) =>
        this.editor.removeConnection(connection),
      )
      if (input.key === this.arrayInput.inputKey) return
      node.removeInput(input)
    })
  }

  reDrawNode (node) {
    setTimeout(() => {
      node.update()
      this.editor.view.updateConnections({
        node,
      })
    }, 1)
  }
}
