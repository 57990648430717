import Rete from '@obvious.tech/rete'
import React from 'react'
import {
  FormControl,
  List,
  ListItem,
  TextField,
  IconButton,
  InputAdornment,
  Box,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import {
  makeStyles,
} from '@material-ui/core/styles'

import NodeButton from '../../../../theme/components/NodeButton'
import AddRemoveBlock from '../../../../theme/components/AddRemoveBlock'
import flowClasses from '../../../../theme/classes'
import {
  withThemeProvider,
} from 'src/utils/hocs'

const useStyles = makeStyles((theme) => ({
  flowTextContainer: {
    'label + &': {
      marginTop: 0,
    },
  },
  flowInput: {
    color: 'white',
    fontSize: '11pt',
    padding: '7px 0 6px',
    textAlign: 'center',
  },
  squareIcon: {
    padding: '3px',
    borderRadius: '3px',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
  },
  focusedUnderline: {
    '&::after': {
      borderBottom: '2px solid black',
    },
  },
  flowTextInput: {
    padding: 0,
    textAlign: 'center',
  },
  optiontitleContainer: {
    textAlign: 'center',
    position: 'relative',
  },
  verticalAlign: flowClasses.verticalAlign,
}))

const UserTaskOptions = ({ label, values, onChange, onAdd, onRemove }) => {
  const classes = useStyles()
  const [inValues, setInValues] = React.useState(values)

  const updateInValues = (value, valueId) => {
    const newInValues = inValues.slice()
    const index = newInValues.findIndex((el) => el.id === valueId)
    if (index > -1) {
      newInValues[index].option = value
    }
    setInValues(newInValues)
  }

  React.useEffect(() => {
    setInValues(values)
  }, [values])

  const changeValue = (value, valueId) => {
    updateInValues(value, valueId)
    onChange(value, valueId)
  }

  return (
    <FormControl variant="outlined" fullWidth margin="dense" noValidate>
      <AddRemoveBlock onAdd={onAdd} label={label} />
      <List>
        {values.map((value, i) => (
          <ListItem key={value.id}>
            <Box mr={1}>
              <TextField
                key={i}
                value={value.option}
                onChange={(e) => {
                  changeValue(e.target.value, value.id)
                }}
                inputProps={{
                  className: classes.flowTextInput,
                }}
                InputProps={{
                  classes: {
                    root: classes.flowInput,
                    focused: classes.focusedUnderline,
                  },
                  className: classes.flowTextContainer,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          changeValue('', value.id)
                        }}
                        className={classes.squareIcon}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <NodeButton onClick={() => onRemove(value.id)}>
              <DeleteIcon style={{
                fontSize: '15pt',
              }} />
            </NodeButton>
          </ListItem>
        ))}
      </List>
    </FormControl>
  )
}

export default class UserTaskOptionsControl extends Rete.Control {
  component = withThemeProvider(UserTaskOptions)

  constructor (
    emitter,
    node,
    key,
    label,
    onChange,
    onRemove,
    defaultValues,
    run = true,
  ) {
    super(key)
    this.key = key
    this.emitter = emitter
    const initial = node.data[key] || defaultValues || []
    node.data[key] = initial

    this.props = {
      label: label || key,
      values: initial,
      onChange: (e, i) => {
        const newValues = this.setValue(e, i)
        onChange && onChange(newValues)
      },
      onAdd: () => {
        const newValues = this.addValue()
        onChange && onChange(newValues)
      },
      onRemove: (idx) => {
        this.removeValue(idx)
        onRemove && onRemove(idx)
      },
    }
  }

  setValue (val, index) {
    const newValues = this.props.values.map((value, i) =>
      index === value.id
        ? {
            id: value.id,
            option: val,
          }
        : value,
    )
    this.props.values = newValues
    this.putData(this.key, newValues)
    return newValues
  }

  addValue () {
    let id = 0
    const idExist = (value) => value.id === id.toString()
    while (this.props.values.find(idExist)) {
      id += 1
    }
    const newValues = [...this.props.values, {
      id: id.toString(),
      option: '',
    }]
    this.props.values = newValues
    this.putData(this.key, newValues)
    this.update()
    return newValues
  }

  removeValue (idx) {
    const newValues = this.props.values.filter((value) => value.id !== idx)
    this.props.values = newValues
    this.putData(this.key, newValues)
    this.update()
    return newValues
  }
}
