import {
  getDaysInMonth,
} from 'date-fns'

type TTimeUnitToMS = (timeUnit: string, amount?: number) => number | Error

const convertTimeUnitToMilliseconds: TTimeUnitToMS = (timeUnit, amount = 1) => {
  const MILLISECONDS_PER_DAY = 86400000
  const daysInMonth = getDaysInMonth(new Date())

  const timeUnits = {
    second: 1000,
    minute: 1000 * 60,
    hour: 1000 * 60 * 60,
    day: MILLISECONDS_PER_DAY,
    week: MILLISECONDS_PER_DAY * 7,
    month: MILLISECONDS_PER_DAY * 7 * daysInMonth,
  }

  const convertedTimeUnit = timeUnits[timeUnit]

  if (!convertedTimeUnit) throw new Error('Error: Invalid time unit')

  return convertedTimeUnit * amount
}

export default convertTimeUnitToMilliseconds
