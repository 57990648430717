import {
  TEntityOverride,
} from 'src/types'

const override: TEntityOverride = {
  titleField: 'id',
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
    },
  },
  create: {
    properties: {
      markerId: {
        referenceTo: 'geolocation/api/Marker',
      },
    },
  },
  edit: {
    properties: {
      markerId: {
        referenceTo: 'geolocation/api/Marker',
      },
    },
  },
}

export default override
