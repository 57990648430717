import React from 'react'
import {
  Control,
} from 'rete-react-render-plugin'

import Socket from '../Sockets/Socket'

const OutputsWithDecomposeButton = ({
  outputs,
  bindControl,
  bindSocket,
  node,
}) => {
  const hasError = (outputKey) => node.validation?.errors?.[outputKey]
  const hasWarning = (outputKey) => node.validation?.warnings?.[outputKey]

  return outputs.map((output) => {
    return (
      <div className="output" key={output.key}>
        <div className="input-title">{output.name}</div>
        {output.showControl && output.showControl() && (
          <Control
            className="output-control-inline"
            control={output.control}
            innerRef={bindControl}
          />
        )}
        <Socket
          type="output"
          error={hasError(output.key)}
          warning={hasWarning(output.key)}
          socket={output.socket}
          io={output}
          innerRef={bindSocket}
        />
      </div>
    )
  })
}

export default OutputsWithDecomposeButton
