// @ts-nocheck
import {
  makeStyles,
  Theme,
} from '@material-ui/core'

import {
  TContainerStyleProps,
} from './DataGridContainer'
import getScrollbarWidth from './DataGridContainer.utils'
import {
  SIZES,
  SHADOW_SETTINGS,
  scrollShadowStyle,
} from './DataGridContainer.constants'

const SCROLLBAR_WIDTH = getScrollbarWidth()

const useStyles = makeStyles((theme: Theme) => ({
  container: ({ contentHeight, scrollable, fixedFirstCol }: TContainerStyleProps) => {
    const { left, right, down, up } = scrollable
    const vertical = up || down
    const horizontal = left || right
    const vScrollbarWidth = vertical ? SCROLLBAR_WIDTH : 0
    const hScrollbarWidth = horizontal ? SCROLLBAR_WIDTH : 0
    const scrollableY = SIZES.TABLE_HEADER_HEIGHT
    const scrollableX = SIZES.TABLE_FIXED_COL_HEIGHT
    const footerHeight = SIZES.TABLE_FOOTER_HEIGHT
    const scrollableWidthPx = `100% - ${scrollableX + vScrollbarWidth}px`
    const scrollableHeightPx = `100% - ${scrollableY + footerHeight + hScrollbarWidth}px`
    const offset = SHADOW_SETTINGS.SHADOW_SIZE * 2
    const boxShadow = `inset 0 0 ${SHADOW_SETTINGS.SHADOW_SIZE}${SHADOW_SETTINGS.SHADOW_UNIT} #333`
    const unmaskIf = (cond: boolean) => (cond ? 0 : offset)

    const hScrollShadowStyle = Object.assign(
      {
        top: `calc(0px - ${offset}${SHADOW_SETTINGS.SHADOW_UNIT})`,
        left: `${scrollableX}px`,
        width: `calc(${scrollableWidthPx})`,
        height: `calc(${scrollableHeightPx} + ${offset * 2}${SHADOW_SETTINGS.SHADOW_UNIT} + ${scrollableY}px)`,
        boxShadow: horizontal ? boxShadow : 'none',
        clipPath: `inset(${offset}${SHADOW_SETTINGS.SHADOW_UNIT} ${unmaskIf(right)}${
          SHADOW_SETTINGS.SHADOW_UNIT
        } ${offset}${SHADOW_SETTINGS.SHADOW_UNIT} ${unmaskIf(left)}${SHADOW_SETTINGS.SHADOW_UNIT})`,
      },
      scrollShadowStyle,
    )

    const vScrollShadowStyle = Object.assign(
      {
        top: `${scrollableY}px`,
        left: `calc(0px - ${offset}${SHADOW_SETTINGS.SHADOW_UNIT})`,
        width: `calc(${scrollableWidthPx} + ${offset * 2}${SHADOW_SETTINGS.SHADOW_UNIT} + ${scrollableX}px)`,
        height: `calc(${scrollableHeightPx})`,
        boxShadow: vertical ? boxShadow : 'none',
        clipPath: `inset(${unmaskIf(up)}${SHADOW_SETTINGS.SHADOW_UNIT} ${offset}${
          SHADOW_SETTINGS.SHADOW_UNIT
        } ${unmaskIf(down)}${SHADOW_SETTINGS.SHADOW_UNIT} ${offset}${SHADOW_SETTINGS.SHADOW_UNIT})`,
      },
      scrollShadowStyle,
    )

    if (!fixedFirstCol) {
      return {
        maxHeight: `${contentHeight}px`,
      }
    }

    return {
      maxHeight: `${contentHeight}px`,
      '& td:first-child,& th:first-child': {
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      '& tr, & tr td:first-child': {
        borderBottom: 'none',
      },
      '& th:first-child': {
        zIndex: 3,
        borderBottom: 'none',
      },
      '& th:not(:first-child) > span:not([data-sort])': {
        color: 'rgba(0, 0, 0, 0.46)',
      },
      '& thead th, & td:first-child': {
        backgroundColor: '#eee',
      },
      '& tr:hover td:first-child': {
        filter: 'brightness(90%)',
      },
      '&:before': hScrollShadowStyle,
      '&:after': vScrollShadowStyle,
    }
  },
  row: {
    '& tbody tr:hover': {
      cursor: ({ hasEdit }: TContainerStyleProps) => {
        if (window.isNullish(hasEdit)) return 'pointer'

        return hasEdit ? 'pointer' : 'default'
      },
    },
  },
}))

export default useStyles
