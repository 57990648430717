export default {
  nodes: {
    trigger: {
      contrastColor: '#fff',
      color: 'rgba(53, 186, 155, 1)',
      socketBackground: '#rgba(70, 206, 172, 1)',
    },
    module: {
      contrastColor: '#fff',
      color: 'rgba(245, 185, 69, 1)',
      socketBackground: 'rgba(253, 205, 86, 1)',
    },
    default: {
      contrastColor: '#fff',
      color: 'rgba(0, 121, 218, 1)',
      socketBackground: 'rgba(172, 146, 234, 1)',
    },
  },
}
