import * as React from 'react'
import {
  AppBar, 
} from 'react-admin'
import {
  Typography,
  makeStyles,
} from '@material-ui/core';

import UserMenu from 'src/components/UserMenu'

const useStyles = makeStyles((theme) => ({
  spacer: {
    flex: 1,
  },
  menuButton: {
    marginLeft: 0,
    padding: theme.spacing(1),
  },
  toolbar: {
    padding: `0 ${theme.spacing(2)}px`,
  },
}))

const CustomAppBar = (props) => {
  const classes = useStyles()

  return (
    <AppBar
      {...props}
      classes={{
        toolbar: classes.toolbar,
        menuButton: classes.menuButton, 
      }}
      userMenu={<UserMenu />}
      color="primary"
    >
      <Typography variant="h6" id="react-admin-title" />
      <span className={classes.spacer} />
    </AppBar>
  )
}
export default CustomAppBar
