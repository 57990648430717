// @ts-nocheck
import React from 'react'
import {
  get,
} from 'lodash'
import {
  makeStyles,
} from '@material-ui/core/styles'
import ImagePreview from 'src/components/ImagePreview'
import { Record } from 'react-admin'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    listStyleType: 'none',
    backgroundColor: 'grey',
    justifyContent: 'center',
  },
  image: {
    margin: '0.1rem',
    maxHeight: '5rem',
    maxWidth: '8rem',
  },
})

type Props = Pick<React.ComponentProps<'div'>, 'className'> & {
  record: Record
  source: string
  title?: string
}

const ImageField = ({ className, record, source, title = 'Image' }: Props) => {
  const classes = useStyles()
  const imageB64: string | undefined = get(record, source)
  if (!imageB64) {
    return <div className={className} />
  }

  const titleValue: string = get(record, title, title)

  return (
    <div className={classes.root}>
      <ImagePreview
        alt={titleValue}
        src={`data:image/png;base64,${imageB64}`}
        className={classes.image}
      />
    </div>
  )
}

export default ImageField
