// @ts-nocheck
import React, {
  useState,
} from 'react'
import styled from 'styled-components'
import {
  HttpError,
  Record,
} from 'react-admin'
import {
  Typography,
  Button,
  Box,
} from '@material-ui/core'

import useErrorFetch from 'src/hooks/useErrorFetch'

interface IImageFieldProps {
  name: string
  record: Record
  resource: string
}

const ImageField = ({
  name,
  record,
  resource,
}: IImageFieldProps): JSX.Element => {
  const fetchError = useErrorFetch()

  const uploadedImage = record?.[name]

  const [imageFile, setImageFile] = useState<string | null>(null)
  const [fetching, setFetching] = useState(false)

  const fetchImageFile = async (): Promise<void> => {
    const url = `${window._env_.API_URL}/${resource}/${record.id}/image/download`
    const options = {
      headers: {
        authorization: localStorage.getItem('token') ?? '',
      },
      method: 'GET',
    }

    try {
      setFetching(true)
      const response = await fetch(url, options)
      if (!response.ok) {
        const error = await response.json()
        const errorMessage = window.isNullish(error.errorMessage)
          ? 'Failed to fetch image.'
          : error.errorMessage

        throw new HttpError(errorMessage, response.status)
      }

      const imageBlob = await response.blob()
      const imageUrl = window.URL.createObjectURL(imageBlob)

      setImageFile(imageUrl)
    } catch (e) {
      await fetchError(e)
    } finally {
      setFetching(false)
    }
  }

  if (window.isNullish(uploadedImage)) return <Typography>-</Typography>

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {!window.isNullish(imageFile)
        ? (
        <Image src={imageFile} alt={record.name} />
          )
        : (
        <Button disabled={fetching} onClick={fetchImageFile}>
          Preview
        </Button>
          )}
    </Box>
  )
}

const Image = styled.img`
  max-width: 100%;
  max-height: 40px;
`

export default ImageField
