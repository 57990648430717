import {
  withStyles,
} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const BootstrapButton = withStyles({
  root: {
    boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.49);',
    textTransform: 'none',
    padding: '2px',
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'white',
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'white',
      border: 'none',
    },
    '&:active': {
      backgroundColor: 'white',
      border: 'none',
    },
    '&:focus': {
      backgroundColor: 'white',
      border: 'none',
    },
  },
})(Button)

export default BootstrapButton
