import {
  TEntityOverride,
} from 'src/types'
import Flow from 'src/adminPanel/Flow'

import ListBulkActions from '../Diagram/Actions/ListBulkActions'
import EditDiagramToolbar from '../Diagram/Actions/EditDiagramToolbar'
import CreateDiagram from '../Diagram/Actions/CreateDiagram'
import StatusInput from 'src/components/flow/StatusInput'

const override: TEntityOverride = {
  titleField: 'name',
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
    },
  },
  list: {
    displayedListToolbar: ListBulkActions,
    properties: {
      status: {
        type: 'DiagramStatus',
      },
    },
  },
  create: {
    displayedComponent: CreateDiagram,
    properties: {
      id: {
        hidden: true,
      },
      createdAt: {
        hidden: true,
      },
      lastUpdate: {
        hidden: true,
      },
      status: {
        displayedComponent: StatusInput,
        defaultValue: 'stopped',
      },
    },
  },
  edit: {
    // @ts-expect-error
    redirect: false,
    displayedComponent: Flow,
    displayedFormToolbar: EditDiagramToolbar,
    properties: {
      events: {
        hidden: true,
      },
      lastUpdate: {
        hidden: true,
      },
      status: {
        displayedComponent: StatusInput,
      },
    },
  },
}

export default override
