import Rete from '@obvious.tech/rete'
import {
    sockets,
} from '../..'
import SelectControl from '../../Controls/SelectControl'
import SwitchCaseControl from './SwitchCaseControl'
import {
    getPossibleComparisons,
    comparisonFunctions,
} from '../../../../helpers/ComparisonHelper'
import DefaultNode from '../../Components/CustomNode'
import NodeTypizer from '../../Components/NodeTypizer'
import SwichInputComponent from '../../Components/SwichInputComponent'
import {
    isObjectType,
} from '../../../../helpers/EntityHelper'

export default class SwitchCaseComponent extends Rete.Component {
    constructor() {
        super('Switch')
        this.contextMenuPath = ['Actions']
        this.task = {
            outputs: {
                result: 'output',
            },
        }
        this.data.component = NodeTypizer(DefaultNode, {
            InputBlockComponent: SwichInputComponent,
        })
        this.filters = []
    }

    builder(node) {
        const inp1 = new Rete.Input('value', 'Value', sockets.anyTypeSocket)
        const inp2 = new Rete.Input(
            'defaultValue',
            'Default',
            sockets.anyTypeSocket,
        )
        const out1 = new Rete.Output('result', 'Result', sockets.anyTypeSocket)

        node
            .addInput(inp1)
            .addInput(inp2)
            .addOutput(out1)

        if (node.data.switchInputs) {
            node.data.switchInputs.forEach((input, index) => {
                if (input.inputLabel === 'key') {
                    this.addKeyInput(
                        node,
                        input.inputName,
                        input.inputSocket,
                        input.inputFunction,
                    )
                } else if (input.inputLabel === 'result') {
                    const resultInput = new Rete.Input(
                        input.inputName,
                        'Result',
                        sockets.anyTypeSocket,
                    )
                    node.addInput(resultInput)
                } else if (['true', 'false'].includes(input.inputName)) {
                    const resultInput = new Rete.Input(
                        input.inputName,
                        input.inputLabel,
                        sockets.anyTypeSocket,
                    )
                    node.addInput(resultInput)
                }
            })
            node.update()
            setTimeout(() => {
                this.editor.view.updateConnections({
                    node,
                })
            }, 1)
        }
    }

    saveToSwitchInputs(node, switchInput) {
        const prevInputs = node.data.switchInputs || []
        const inputs = [...prevInputs, switchInput]
        node.data.switchInputs = inputs
    }

    addKeyInput(node, inputKeyName, connectedSocketType, inputFunction) {
        let socketType
        switch (connectedSocketType) {
            case 'string':
                socketType = 'stringSocket'
                break
            case 'number':
                socketType = 'numberSocket'
                break
            case 'object':
                socketType = 'anyTypeSocket'
                break
            case 'Coordinate':
                socketType = 'CoordinateSocket'
                break
            default:
                break
        }
        const input = comparisonFunctions[inputFunction](
            isObjectType(connectedSocketType) ? 'object' : connectedSocketType,
            connectedSocketType,
        )

        const keyInput = new Rete.Input(
            inputKeyName,
            'key',
            sockets[
                input.args.length > 1 ?
                `${socketType}CallBackSocket` :
                input.args.length ?
                `${input.args[0]}Socket` :
                'anyTypeSocket'
            ],
        )
        const comparisons = getPossibleComparisons(
            isObjectType(connectedSocketType) ? 'object' : connectedSocketType,
            connectedSocketType,
        )
        const filters = comparisons.map((comparison) => ({
            value: comparison,
            label: comparison,
        }))
        keyInput.addControl(
            new SelectControl(
                this.editor,
                node,
                'key',
                '',
                (value) => {
                    if (node.data.switchInputs) {
                        const inpKey = node.data.switchInputs.find(
                            (inp) => inp.inputName === inputKeyName,
                        )
                        if (inpKey) {
                            inpKey.inputFunction = value
                        }
                    }
                    const { args: comparisonFunctionArguments } = comparisonFunctions[
                        value
                    ](
                        isObjectType(connectedSocketType) ? 'object' : connectedSocketType,
                        connectedSocketType,
                    )
                    keyInput.socket =
                        sockets[
                            comparisonFunctionArguments.length > 1 ?
                            `${connectedSocketType}CallbackSocket` :
                            comparisonFunctionArguments.length ?
                            `${comparisonFunctionArguments[0]}Socket` :
                            'anyTypeSocket'
                        ]
                    node.update()
                    setTimeout(() => {
                        this.editor.view.updateConnections({
                            node,
                        })
                    }, 1)
                },
                filters,
                inputFunction,
            ),
        )
        node.addInput(keyInput)
    }

    building = (node, connectionType) => {
        if (connectionType) {
            node.data.entity = connectionType
            if (connectionType !== 'boolean') {
                const startNumberOfInputs = node.inputs.size - 2
                const addCase = (isInit) => {
                    if (isInit && startNumberOfInputs) return
                    const inputKeyName = (node.inputs.size - 2).toString()
                    const inputResultName = (node.inputs.size - 1).toString()
                    const defaultFunction = 'exist'
                    this.addKeyInput(node, inputKeyName, connectionType, defaultFunction)
                    this.saveToSwitchInputs(node, {
                        inputName: inputKeyName,
                        inputLabel: 'key',
                        inputSocket: connectionType,
                        inputFunction: defaultFunction,
                    })

                    const resultInput = new Rete.Input(
                        inputResultName,
                        'result',
                        sockets.anyTypeSocket,
                    )
                    node.addInput(resultInput)

                    this.saveToSwitchInputs(node, {
                        inputName: inputResultName,
                        inputLabel: 'result',
                        inputSocket: 'anyTypeSocket',
                    })

                    node.update()
                    setTimeout(() => {
                        this.editor.view.updateConnections({
                            node,
                        })
                    }, 1)
                }

                const removeCase = () => {
                    const inputLength = node.data.switchInputs.length
                    if (inputLength > 1) {
                        const inputFctToRemove = node.inputs.get(
                            node.data.switchInputs[inputLength - 2].inputName,
                        )
                        const inputValueToRemove = node.inputs.get(
                            node.data.switchInputs[inputLength - 1].inputName,
                        )
                        inputFctToRemove.connections.forEach((connection) =>
                            this.editor.removeConnection(connection),
                        )
                        node.removeInput(inputFctToRemove)
                        inputValueToRemove.connections.forEach((connection) =>
                            this.editor.removeConnection(connection),
                        )
                        node.removeInput(inputValueToRemove)
                        node.data.switchInputs = node.data.switchInputs.slice(0, -2)
                    }
                    node.update()
                    setTimeout(() => {
                        this.editor.view.updateConnections({
                            node,
                        })
                    }, 1)
                }

                const SwitchControl = new SwitchCaseControl(
                    'switches',
                    'Cases',
                    addCase,
                    removeCase,
                )
                node.addControl(SwitchControl)

                setTimeout(() => {
                    node.update()
                    this.editor.view.updateConnections({
                        node,
                    })
                }, 1)
            } else {
                const booleanInputs = [{
                        name: 'true',
                        label: 'True',
                    },
                    {
                        name: 'false',
                        label: 'False',
                    },
                ]

                booleanInputs.forEach((input) => {
                    if (!node.inputs.has(input.name)) {
                        const inputObj = new Rete.Input(
                            input.name,
                            input.label,
                            sockets.anyTypeSocket,
                        )
                        node.addInput(inputObj)
                        this.saveToSwitchInputs(node, {
                            inputName: input.name,
                            inputLabel: input.label,
                        })
                    }
                })

                setTimeout(() => {
                    node.update()
                    this.editor.view.updateConnections({
                        node,
                    })
                }, 1)

                const defValCon = node.inputs.get('defaultValue')
                defValCon.connections.forEach((connection) =>
                    this.editor.removeConnection(connection),
                )
                node.removeInput(defValCon)

                setTimeout(() => {
                    node.update()
                    this.editor.view.updateConnections({
                        node,
                    })
                }, 1)
            }
        } else {
            const defValCon = node.inputs.get('defaultValue')
            if (!defValCon) {
                const inp = new Rete.Input(
                    'defaultValue',
                    'Default',
                    sockets.anyTypeSocket,
                )
                node.addInput(inp)
            }
            node.data.switchInputs = []
            node.controls.forEach((c) => {
                node.removeControl(c)
            })
            node.inputs.forEach((i) => {
                if (['value', 'defaultValue'].includes(i.key)) return
                i.connections.forEach((connection) =>
                    this.editor.removeConnection(connection),
                )
                node.removeInput(i)
            })
        }
        setTimeout(() => {
            node.update()
            this.editor.view.updateConnections({
                node,
            })
        }, 1)
    }

    connected = (connection) => {
        if (connection.input.key === 'value') {
            setTimeout(() => {
                this.building(connection.input.node, connection.output.socket.name)
            })
        }
    }

    disconnected = (connection) => {
        if (connection.input.key === 'value') {
            setTimeout(() => {
                this.building(connection.input.node)
            })
        }
    }

    worker(node, inputs) {}
}