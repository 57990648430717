export const comparisonFunctions = {
  '<': (type) => ({
    args: [type],
  }),
  '>': (type) => ({
    args: [type],
  }),
  '<=': (type) => ({
    args: [type],
  }),
  '>=': (type) => ({
    args: [type],
  }),
  '!=': (type) => ({
    args: [type],
  }),
  startWith: (type) => ({
    args: [type],
  }),
  different: (type) => ({
    args: [type],
  }),
  contains: (type) => ({
    args: [type],
  }),
  'does not contain': (type) => ({
    args: [type],
  }),
  'start with': (type, object) => ({
    args: [type === 'string' ? 'string' : object],
  }),
  'end with': (type, object) => ({
    args: [type === 'string' ? 'string' : object],
  }),
  closerThan: (type) => ({
    type: 'callback',

    args: [type, 'Coordinate'],
  }),
  'contains key': (type) => ({
    args: [],
  }),
  "doesn't contain key": (type) => ({
    args: [],
  }),
  equals: (type, entity) => ({
    args: [entity || type],
  }),
  exist: (type, entity) => ({
    args: [],
  }),
  'do not exist': (type) => ({
    args: [],
  }),
  true: (type) => ({
    args: [],
  }),
  false: (type) => ({
    args: [],
  }),
  AND: (type) => ({
    args: [type],
  }),
  OR: (type) => ({
    args: [type],
  }),
}

const commonComparisonTypes = ['exist', 'do not exist']
const containsComparisonTypes = ['contains key', "doesn't contain key"]

export const ComparisonTypes = {
  number: ['equals', '<', '>', '<=', '>=', '!=', ...commonComparisonTypes],
  string: [
    'equals',
    'different',
    'contains',
    'does not contain',
    'start with',
    'end with',
    ...commonComparisonTypes,
  ],
  object: {
    Coordinate: ['equals', 'closerThan', ...commonComparisonTypes],
  },
  boolean: ['AND', 'OR', 'true', 'false'],
}

export const getPossibleComparisons = (entity, object) => {
  const result =
    entity === 'object'
      ? ComparisonTypes.object[object]
      : ComparisonTypes[entity]

  if (!result) return [...commonComparisonTypes, ...containsComparisonTypes]

  return [...result, ...containsComparisonTypes]
}
