import Rete from '@obvious.tech/rete'
import {
    isValid,
    getTime,
} from 'date-fns'

import {
    sockets,
} from '../..'
import DateControl from '../../Controls/DateControl'
import SelectControl from '../../Controls/SelectControl'
import DefaultNode from '../../Components/CustomNode'
import NodeTypizer from '../../Components/NodeTypizer'
import {
    convertTimeUnitToMilliseconds,
} from 'src/utils/date'

export default class ScheduleWithIntervalComponent extends Rete.Component {
    constructor(name = 'Schedule with interval') {
        super(name)
        this.contextMenuPath = ['Triggers']
        this.task = {
            outputs: {
                act: 'option',
            },
        }
        this.data.component = NodeTypizer(DefaultNode, {
            type: 'trigger',
        })
    }

    builder(node) {
        const options = [{
                value: convertTimeUnitToMilliseconds('second'),
                label: 'Second',
            },
            {
                value: convertTimeUnitToMilliseconds('minute'),
                label: 'Minute',
            },
            {
                value: convertTimeUnitToMilliseconds('hour'),
                label: 'Hour',
            },
            {
                value: convertTimeUnitToMilliseconds('day'),
                label: 'Day',
            },
            {
                value: convertTimeUnitToMilliseconds('week'),
                label: 'Week',
            },
            {
                value: convertTimeUnitToMilliseconds('month'),
                label: 'Month',
            },
        ]

        const inp2 = new Rete.Input('interval', 'Interval', sockets.numberSocket)
        const onChangeInterval = () => {}
        inp2.addControl(
            new SelectControl(
                this.editor,
                node,
                'unit',
                'Interval',
                onChangeInterval,
                options,
                1000,
                false,
            ),
        )

        const onChange = (key, value) => {
            node.data[key] = value

            const startDate = new Date(`${node.data?.date}T${node.data?.time}:00`)
            if (!isValid(startDate)) return
            node.data.startTime = String(getTime(startDate))
        }

        const dateControl = new DateControl(
            this.editor,
            node,
            'date',
            'Date',
            onChange,
        )
        const timeControl = new DateControl(
            this.editor,
            node,
            'time',
            'Time',
            onChange,
        )
        node
            .addControl(dateControl)
            .addControl(timeControl)
            .addInput(inp2)
            .addOutput(new Rete.Output('act', 'Action', sockets.actionSocket))
    }
}