import {
  Style
} from '@react-pdf/types'
import {
  TCellStyle,
} from './Table'

export const toStyleArray = (
  value: TCellStyle
): Style[] => {
  return (
    Array.isArray(value) ? value : [value]
  ).filter(
    <A,>(it: A): it is Exclude<A, undefined> => it !== undefined
  )
}