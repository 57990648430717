import React from 'react'
import styled from 'styled-components'
import {
  Box,
} from '@material-ui/core'

const Socket = ({
  innerRef,
  type,
  io,
  socket,
  error,
  warning,
  alignSelf,
  ...rest
}) => {
  const createRef = (el) => {
    el && innerRef(el, type, io)
  }

  const kebab = (str) => {
    if (!str) return ''

    const replace = (s) => s.toLowerCase().replace(/ /g, '-')
    return Array.isArray(str) ? str.map(replace) : replace(str)
  }

  const handleSocketBorder = () => {
    const ERROR_COLOR = '#ec5757'
    const WARN_COLOR = '#dfc027'

    if (error) return ERROR_COLOR
    if (warning) return WARN_COLOR

    return 'transparent'
  }

  return (
    <SocketWrapper
      borderColor={handleSocketBorder()}
      type={type}
      alignSelf={alignSelf}
      className={`socket ${type} ${kebab(socket.name)}`}
    >
      <StyledSocket
        title={socket.name}
        output={kebab(socket.name)}
        ref={(el) => createRef(el)} // force update for new IO with a same key
        {...rest}
      />
    </SocketWrapper>
  )
}

const SocketWrapper = styled(Box)`
  /* to override default socket styles */
  &.socket {
    transition: border-color 0.2s;
    display: inline-block;
    align-self: ${({ alignSelf }) => alignSelf};
    margin: 6px;
    margin-left: ${({ type }) => type === 'input' && '-14px'};
    margin-right: ${({ type }) => type === 'output' && '-14px'};
    cursor: pointer;
    border: 2px solid ${({ borderColor }) => borderColor};
    border-radius: 14px;
    width: 26px;
    height: 26px;
    vertical-align: middle;
    background: none;
    z-index: 2;

    &:hover {
      border-width: 2px;
    }
  }
`

const StyledSocket = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 6px solid #3f454f;
  display: ${({ output }) => (output === 'no-type' ? 'none' : 'flex')};
  background-color: ${({ output }) =>
    output === 'action' ? '#ff00ff' : ' #00ffff'};
`

export default Socket
