import Rete from '@obvious.tech/rete'
import React from 'react'
import AddIcon from '@material-ui/icons/Add'

import NodeButton from '../../../theme/components/NodeButton'

export default class AddButton extends Rete.Control {
  component = ({ onClick }) => {
    return (
      <NodeButton onClick={onClick}>
        <AddIcon fontSize="small" />
      </NodeButton>
    )
  }

  constructor (key, editor) {
    super(key)
    this.props = {
      onClick: () => {
        this.onClick(this.parent, editor)
      },
    }
  }

  onClick (output, editor) {
    if (
      output.socket.compatible.findIndex(
        (c) => c.name.toLowerCase() === 'object',
      ) !== -1
    ) {
      editor.components
        .get('Decompose')
        .createNode()
        .then((newDecompose) => {
          editor.addNode(newDecompose)
          editor.connect(output, newDecompose.inputs.get('iData'))
        })
        .catch((e) => console.error(e))
      return false
    }
    return true
  }
}
