import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePickerProps,
  KeyboardTimePickerProps,
} from '@material-ui/pickers'
import React, {
  useRef,
  useEffect,
} from 'react'

import {
  DateInput,
  TimeInput,
} from 'src/components/flow/DT'

type TDateTimeProps = KeyboardDatePickerProps &
KeyboardTimePickerProps & {
  type: 'date' | 'time'
}

const DateTimeInput = ({ type, ...InputProps }: TDateTimeProps) => {
  const inputRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = inputRef?.current

    if (element != null) {
      const preventNodeMove = (e: MouseEvent) => e.stopPropagation()
      element.addEventListener('pointerdown', preventNodeMove)

      return () => {
        element.removeEventListener('pointerdown', preventNodeMove)
      }
    }
  }, [inputRef])

  const DateTypeInput = {
    date: <DateInput {...InputProps} />,
    time: <TimeInput {...InputProps} />,
  }

  const Picker = DateTypeInput[type]

  if (!Picker) return null

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div ref={inputRef}>{Picker}</div>
    </MuiPickersUtilsProvider>
  )
}

export default DateTimeInput
