import {
  TEntityOverride,
} from 'src/types'

const override: TEntityOverride = {
  titleField: 'id',
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
    },
  },
  create: {
    properties: {
      alarmId: {
        hidden: false,
        readOnly: false,
        referenceTo: 'alarms/api/Alarm',
      },
    },
  },
}

export default override
