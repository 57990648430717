import Rete from '@obvious.tech/rete'

import {
    sockets,
} from '../..'

export default class LocationCallbackComponent extends Rete.Component {
    constructor() {
        super('Location rule (closerThan)')
        this.contextMenuPath = ['Callbacks']
        this.task = {
            outputs: {
                callback: 'output',
            },
        }
    }

    builder(node) {
        const inp1 = new Rete.Input(
            'input1',
            'Coordinates',
            sockets.CoordinateSocket,
        )
        const inp2 = new Rete.Input('input2', 'Distance (m)', sockets.numberSocket)
        const out1 = new Rete.Output(
            'callback',
            'Callback',
            sockets.CoordinateCallbackSocket,
        )

        return node.addInput(inp1).addInput(inp2).addOutput(out1)
    }
}