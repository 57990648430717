import Rete from '@obvious.tech/rete'

import {
    sockets,
} from '../..'
import {
    getControl,
} from '../../../../helpers/ControlHelper'
import ConstellationEventComponent from '../Triggers/ConstellationEventComponent'

export default class ConstellationWaitEventComponent extends ConstellationEventComponent {
    constructor(options) {
        super(options)
        this.contextMenuPath = [
            'Actions',
            'Constellation',
            options.microservice,
            options.entity,
        ]
    }

    builder(node) {
        const timeoutInput = new Rete.Input(
            'timeout',
            'Timeout',
            sockets.numberSocket,
        )
        timeoutInput.addControl(
            getControl(
                this.editor,
                node,
                'number',
                'timeout',
                'Timeout (seconds)',
                () => {
                    this.handleTimeoutOutput(node)
                    this.handleReExecuteInput(node)
                },
            ),
        )

        node
            .addInput(new Rete.Input('act', 'Execute', sockets.actionSocket, true))
            .addInput(timeoutInput)

        super.builder(node)
        this.building(node, this.entity)

        this.handleTimeoutOutput(node)
        this.handleReExecuteInput(node)
    }

    handleTimeoutOutput(node) {
        const timeoutOutput = node.outputs.get('timeout')

        if (this.isTimeoutInputActive(node)) {
            if (timeoutOutput) return

            node.addOutput(
                new Rete.Output('timeout', 'Timeout', sockets.actionSocket),
            )

            this.updateNode(node)
            return
        }

        if (!timeoutOutput) return

        timeoutOutput.connections.forEach((connection) =>
            this.editor.removeConnection(connection),
        )
        node.removeOutput(timeoutOutput)
        this.updateNode(node)
    }

    handleReExecuteInput = (node) => {
        const reExecuteInput = node.inputs.get('reExecute')

        if (this.isTimeoutInputActive(node)) {
            if (reExecuteInput) return

            this.renderReExecuteInput(node)
            this.updateNode(node)
            return
        }

        if (!reExecuteInput) return

        reExecuteInput.connections.forEach((connection) =>
            this.editor.removeConnection(connection),
        )
        node.removeInput(reExecuteInput)

        this.updateNode(node)
    }

    isTimeoutInputActive(node) {
        const timeoutInput = node.inputs.get('timeout')
        const isTimeoutInpConnected = timeoutInput.connections.length
        const timeoutInpValue = timeoutInput.control.props.value

        return isTimeoutInpConnected || timeoutInpValue
    }

    updateNode(node) {
        setTimeout(() => {
            node.update()
            this.editor.view.updateConnections({
                node,
            })
        })
    }

    connected = (connection) => {
        if (connection.input.key === 'timeout') {
            setTimeout(() => {
                this.handleReExecuteInput(connection.input.node)
                this.handleTimeoutOutput(connection.input.node)
            })
        }
    }

    disconnected = (connection) => {
        if (connection.input.key === 'timeout') {
            setTimeout(() => {
                this.handleReExecuteInput(connection.input.node)
                this.handleTimeoutOutput(connection.input.node)
            })
        }
    }

    renderReExecuteInput(node) {
        const reExecuteInput = new Rete.Input(
            'reExecute',
            'Re-execute',
            sockets.booleanSocket,
        )
        const reExecuteControl = getControl(
            this.editor,
            node,
            'boolean',
            'reExecute',
            'Re-execute',
        )

        reExecuteControl && reExecuteInput.addControl(reExecuteControl)

        node.addInput(reExecuteInput)
    }
}