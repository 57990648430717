import React from 'react'
import {
  BooleanField,
} from 'react-admin'

const CustomBooleanField = ({ property, name, ...props }) => {
  return (
    <BooleanField
      source={name}
      label={property.displayedName || name}
      {...props}
    />
  )
}

export default CustomBooleanField
