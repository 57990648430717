import Rete from '@obvious.tech/rete'
import {
    sockets,
} from '../..'

export default class LogComponent extends Rete.Component {
    constructor() {
        super('End of process')
        this.task = {
            outputs: {},
        }
        this.contextMenuPath = []
    }

    builder(node) {
        const actionInput = new Rete.Input(
            'act',
            'Execute',
            sockets.actionSocket,
            true,
        )

        return node.addInput(actionInput)
    }
}