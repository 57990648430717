import ObjectInput from './ObjectInput'
import BooleanInput from './BooleanInput'
import NumberInput from './NumberInput'
import ImageInput from './ImageInput'
import CoordinatesInput from './CoordinatesInput'
import ReferenceArrayInput from './arrays/ReferencedArrayInput'
import ReferenceInput from './ReferencedInput'
import ArrayInput from './arrays'
import MapManifestInput from './MapManifestInput'
import GroupsInput from './GroupsInput'
import BaseOnInput from './BasedOnInput'
import ColorInput from './ColorInput'
import DateTimeInput from './DateTimeInput'
import CustomFileInput from './FileInput'
import EnumInput from './EnumInput'
import SoundInput from './SoundInput'
import StringInput from './StringInput'
import {
  BirthDateInput,
} from './DT'

export default {
  object: ObjectInput,
  enum: EnumInput,
  string: StringInput,
  number: NumberInput,
  boolean: BooleanInput,
  Image: ImageInput,
  sound: SoundInput,
  Coordinate: CoordinatesInput,
  array: ArrayInput,
  MapManifest: MapManifestInput,
  groups: GroupsInput,
  color: ColorInput,
  basedOn: BaseOnInput,
  'date-time': DateTimeInput,
  'birth-date': BirthDateInput,
  file: CustomFileInput,
  referenceTo: {
    string: ReferenceInput,
    array: ReferenceArrayInput,
  },
} as const
