import Rete from '@obvious.tech/rete'
import {
    sockets,
} from '../..'
import UserTaskOptionsControl from './UserTaskOptionsControl'
import {
    getControl,
} from '../../../../helpers/ControlHelper'

export default class UserTaskComponent extends Rete.Component {
    constructor() {
        super('User Task')
        this.waitForResponseOn = []
        this.task = {
            outputs: {
                response: 'output',
            },
        }
    }

    builder(node) {
        const actInp = new Rete.Input('iAct', 'Then', sockets.actionSocket, true)
        const withDescriptionInp = new Rete.Input(
            'withNotes',
            'With notes',
            sockets.booleanSocket,
        )
        const withNotesControl = getControl(
            this.editor,
            node,
            'boolean',
            'withNotes',
            'With notes',
        )
        withNotesControl && withDescriptionInp.addControl(withNotesControl)
        const withFilesInp = new Rete.Input(
            'withFiles',
            'With files',
            sockets.booleanSocket,
        )
        const withFilesControl = getControl(
            this.editor,
            node,
            'boolean',
            'withFiles',
            'With files',
        )
        withFilesControl && withFilesInp.addControl(withFilesControl)
        const isPopupInp = new Rete.Input(
            'isPopup',
            'Is popup',
            sockets.booleanSocket,
        )
        const isPopupControl = getControl(
            this.editor,
            node,
            'boolean',
            'isPopup',
            'Is popup',
        )
        isPopupControl && isPopupInp.addControl(isPopupControl)
        const alarmIdInp = new Rete.Input(
            'alarmId',
            'Alarm id',
            sockets.stringSocket,
        )
        const descInp = new Rete.Input(
            'description',
            'Description',
            sockets.stringSocket,
        )
        descInp.addControl(
            getControl(this.editor, node, 'string', 'description', 'Description'),
        )
        const multipleAnswersInp = new Rete.Input(
            'multipleAnswersAuthorized',
            'Accept multiple answers',
            sockets.booleanSocket,
        )

        const idInp = new Rete.Input('id', 'Id', sockets.stringSocket)
        idInp.addControl(getControl(this.editor, node, 'string', 'id', 'Id'))
        const timeOutInp = new Rete.Input(
            'timeout',
            'Timeout',
            sockets.numberSocket,
        )
        timeOutInp.addControl(
            getControl(
                this.editor,
                node,
                'number',
                'timeout',
                'Timeout (seconds)',
                () => this.handleTimeoutOutput(node),
            ),
        )
        const resOut = new Rete.Output(
            'response',
            'Response',
            sockets.UserTaskSocket,
        )
        node.addOutput(resOut)
        const options = []

        const handleOptionsChange = (newOptions) => {
            newOptions.forEach((option) => {
                if (node.outputs.has(option.id)) {
                    const output = node.outputs.get(option.id)
                    if (option.option.length) {
                        output.name = option.option
                    } else {
                        output.connections.forEach((connection) =>
                            this.editor.removeConnection(connection),
                        )
                        node.removeOutput(output)
                    }
                } else if (option.option.length) {
                    const output = new Rete.Output(
                        option.id,
                        option.option,
                        sockets.actionSocket,
                    )
                    node.addOutput(output)
                }
            })
            node.update()
            setTimeout(() => {
                this.editor.view.updateConnections({
                    node,
                })
            }, 1)
        }

        const handleOptionRemoved = (id) => {
            const output = node.outputs.get(id)
            if (output) {
                output.connections.forEach((connection) =>
                    this.editor.removeConnection(connection),
                )
                node.removeOutput(output)
            }
            node.update()
            setTimeout(() => {
                this.editor.view.updateConnections({
                    node,
                })
            }, 1)
        }

        const optionsControl = new UserTaskOptionsControl(
            this.editor,
            node,
            'options',
            'Options',
            handleOptionsChange,
            handleOptionRemoved,
            options,
        )

        node
            .addInput(actInp)
            .addInput(descInp)
            .addInput(multipleAnswersInp)
            .addInput(idInp)
            .addInput(timeOutInp)
            .addInput(withDescriptionInp)
            .addInput(withFilesInp)
            .addInput(isPopupInp)
            .addInput(alarmIdInp)
            .addControl(optionsControl)

        this.handleTimeoutOutput(node)

        node.data.options.forEach((option) => {
            if (node.outputs.has(option.id)) {
                const output = node.outputs.get(option.id)
                if (option.option.length) {
                    output.name = option.option
                } else {
                    output.connections.forEach((connection) =>
                        this.editor.removeConnection(connection),
                    )
                    node.removeOutput(output)
                }
            } else if (option.option.length) {
                const output = new Rete.Output(
                    option.id,
                    option.option,
                    sockets.actionSocket,
                )
                node.addOutput(output)
            }
        })
        return node
    }

    handleTimeoutOutput(node) {
        const timeoutOutput = node.outputs.get('timeout')
        const timeoutInput = node.inputs.get('timeout')
        const isTimeoutInpConnected = timeoutInput.connections.length
        const timeoutInpValue = timeoutInput.control.props.value

        if (isTimeoutInpConnected || timeoutInpValue) {
            if (timeoutOutput) return

            node.addOutput(
                new Rete.Output('timeout', 'Timeout', sockets.actionSocket),
            )

            this.updateNode(node)
            return
        }

        if (!timeoutOutput) return

        timeoutOutput.connections.forEach((connection) =>
            this.editor.removeConnection(connection),
        )
        node.removeOutput(timeoutOutput)
        this.updateNode(node)
    }

    updateNode(node) {
        setTimeout(() => {
            node.update()
            this.editor.view.updateConnections({
                node,
            })
        })
    }

    connected = (connection) => {
        if (connection.input.key === 'timeout') {
            setTimeout(() => {
                this.handleTimeoutOutput(connection.input.node)
            })
        }
    }

    disconnected = (connection) => {
        if (connection.input.key === 'timeout') {
            setTimeout(() => {
                this.handleTimeoutOutput(connection.input.node)
            })
        }
    }
}