const OODA_SHORT_UUID_LENGTH = 5
const OODA_SHORT_UUID_PREFIX = '# '

/**
 * Transforms a regular UUID to the short UUID that is used cross-apps
 * based on OODA Hypervisor's implementation
 */
export default (uuid: string) => {
  const transformedUUID = uuid
    .slice(-OODA_SHORT_UUID_LENGTH)
    .toUpperCase()

  return [
    OODA_SHORT_UUID_PREFIX,
    transformedUUID,
  ].join('')
}