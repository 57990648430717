import React from 'react'
import {
  makeStyles,
} from '@material-ui/core'
import {
  SimpleForm,
} from 'react-admin'

import FormToolbar from 'src/adminPanel/screens/Actions/FormToolbar/FormToolbar'
import EntityPropertiesList from 'src/components/EntityPropertiesList'
import Input from './ConnectedInput'

const sanitizeFormProps = ({
  hasCreate,
  hasShow,
  hasEdit,
  hasList,
  dispatch,
  ...rest
}) => rest

const sanitizeInputProps = ({
  basePath,
  classes,
  saving,
  save,
  dispatch,
  name,
  ...rest
}) => sanitizeFormProps(rest)

const useStyles = makeStyles(() => ({
  flex: {
    flex: 1,
  },
  inputStyles: {
    margin: 4,
  },
}))

export default ({ entity, ...rest }) => {
  const classes = useStyles()
  return (
    <SimpleForm
      className={classes.fulfill}
      toolbar={
        entity.displayedFormToolbar
          ? (
              React.createElement(entity.displayedFormToolbar)
            )
          : (
          <FormToolbar />
            )
      }
      {...sanitizeFormProps(rest)}
    >
      <EntityPropertiesList
        entity={entity}
        childProps={rest}
        render={({ propertyKey, property, entity, childProps }) => {
          return (
            <Input
              id={propertyKey}
              key={propertyKey}
              name={propertyKey}
              property={property}
              required={property.required}
              classes={classes.inputStyles}
              {...sanitizeInputProps(childProps)}
            />
          )
        }}
      />
    </SimpleForm>
  )
}
