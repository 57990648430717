export default (type, baseUrl, endpoint, functionDefinition) => (...args) => {
  let url = baseUrl + endpoint + '?';
  let options = {
    method: type,
    headers: {
      Accept: 'application/json',
    },
  };

  functionDefinition.parameters.forEach((parameter, i) => {
    if (parameter.in === 'query' && !!args[i]) {
      url += `${parameter.name}=${encodeURIComponent('' + args[i])}&`;
    } else if (parameter.in === 'header') {
      options.headers[parameter.name] = args[i];
    } else if (parameter.in === 'body') {
      options['body'] = JSON.stringify(args[i]);
    } else if (parameter.in === 'path') {
      if (args[i] === undefined || args[i] === null)
        throw new Error(`The parameter ${parameter.name} must be defined.`);
      url = url.replace(
        `{${parameter.name}}`,
        encodeURIComponent('' + args[i])
      );
    } else if (parameter.in === 'formData' && args[i]) {
      if (!options['body']) {
        options['body'] = new FormData();
      }
      options['body'].append(parameter.name, args[i]);
    }
  });
  url = url.replace(/[?&]$/, '');
  return {
    url,
    options,
  };
};
