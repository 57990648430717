import React from 'react';
import { required, useInput } from 'react-admin';

import GenericInput from 'src/components/inputs/GenericInput';
import { validationFunctions } from './validationHelpers';

const defaultCoordinate = {
    longitude: 0,
    latitude: 0,
    altitude: 0,
};

const defaultValues = {
    boolean: false,
    number: 0,
    date: new Date(),
    'birth-date': new Date(),
    'date-time': new Date(),
    Coordinate: defaultCoordinate,
};

const handleValidation = (property, defaultValidations = []) => {
    const {
        type,
        isEnum,
        basedOn,
        referencedField,
        referenceTo,
        displayedName,
        hidden,
        readOnly,
        ...restProperty
    } = property;

    const res = Object.keys(restProperty).reduce(
        (acc, cur) => (validationFunctions[cur] ? [...acc, validationFunctions[cur](restProperty[cur])] : acc),
        defaultValidations
    );
    return res;
};

const ConnectedInput = ({ property, name, record = {}, required: isRequired = undefined, classes, resource, ...rest }) => {
    const handleDefaultValue = () => {
        if (property?.defaultValue !== undefined) return property.defaultValue;

        if (defaultValues[property?.type] !== undefined) return defaultValues[property.type];

        return null;
    };

    const {
        input: { onChange, value },
        meta: { error, touched, invalid, initial },
    } = useInput({
        defaultValue: handleDefaultValue(),
        allowNull: true,
        source: name,
        required: isRequired,
        validate: handleValidation(property, isRequired ? [required(), ...property.validations] : property.validations),
        ...rest,
    });

    return ( <
        GenericInput {... {
                onChange,
                value,
                property,
                required: isRequired,
                name,
                record,
                classes,
                error,
                invalid,
                touched,
                initialValue: initial,
                resource,
            }
        }
        />
    );
};

export default ConnectedInput;