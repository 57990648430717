import overrides from './entities'

const defaultOverride = {
  global: {
    properties: {
    },
  },
  list: {
    properties: {
    },
  },
  edit: {
    properties: {
    },
  },
  create: {
    properties: {
    },
  },
}

const getPropertyRef = (property) => {
  return property?.$ref ?? property?.allOf?.[0]?.$ref
}

export const getProperty = (name, property, override = {
}) => {
  const $ref = getPropertyRef(property)
  let type =
    override.type ||
    property.type ||
    ($ref && $ref.split('/')[2])

  if (type === 'string') {
    if (property.format === 'date-time') type = 'date-time'
  }

  return {
    ...property,
    defaultValue: override.defaultValue,
    required: override.required,
    $ref,
    type,
    isEnum: !!property.enum,
    basedOn: override.basedOn || null,
    displayedName: override.displayedName || name,
    referenceTo: override.referenceTo || null,
    referencedField: override.referencedField || null,
    readOnly: override.readOnly || property.readOnly || false,
    hidden: override.hidden || false,
    validations: override.validations || [],
  }
}

const addRequired = (view, requiredKeys) => {
  if (!view.properties) { return view }

  return {
    ...view,
    properties: Object.fromEntries(
      Object.entries(view.properties)
        .map(([key, value]) => {
          return [key, {
            ...value,
            required: (
              typeof value?.required === 'boolean'
                ? value.required
                : requiredKeys.includes(key)
            ),
          }]
        }),
    ),
  }
}

const getEntity = (entityName, {
  properties = {
  }, required = [], ...props
}) => {
  const {
    global = defaultOverride.global,
    list = defaultOverride.list,
    edit = defaultOverride.edit,
    create = defaultOverride.edit,
    ...paramsProps
  } = overrides[entityName] || {
  }

  return {
    ...props,
    ...paramsProps,
    required,
    global: addRequired({
      ...global,
      properties: Object.keys(properties).reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: getProperty(cur, properties[cur], global.properties[cur]),
        }),
        {
        },
      ),
    }, required),
    list,
    edit,
    create,
  }
}

export default (entities) => {
  const res = Object.keys(entities).reduce(
    (acc, entityKey) => ({
      ...acc,
      [entityKey]: getEntity(entityKey, entities[entityKey]),
    }),
    {
    },
  )
  return res
}
