import React from 'react'
import {
  Button,
  sanitizeListRestProps,
  useNotify,
} from 'react-admin'
import {
  saveAs,
} from 'file-saver'
import GetAppIcon from '@material-ui/icons/GetApp'

import ListActions from 'src/adminPanel/screens/List/Actions'

const ListBulkActions = (props) => {
  const { resource } = props
  const notify = useNotify()

  const fetchLicenceFile = async () => {
    const url = `${window._env_.API_URL}/${resource}/requestFile`
    const options = {
      headers: new Headers({
        authorization: localStorage.getItem('token'),
      }),
      method: 'GET',
    }

    try {
      const file = await fetch(url, options)
        .then((response) => {
          if (!response.ok) {
            throw new Error()
          }

          return response.blob()
        })
        .then((blob) => window.URL.createObjectURL(blob))
      saveAs(file, 'Licence.lic')
    } catch (e) {
      notify('Failed to load license file.', 'error')
    }
  }

  return (
    <ListActions {...sanitizeListRestProps(props)} hasExport={false}>
      <Button onClick={fetchLicenceFile} label={'Request file'}>
        <GetAppIcon />
      </Button>
    </ListActions>
  )
}

export default ListBulkActions
