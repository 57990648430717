import Rete from '@obvious.tech/rete'
import React from 'react'
import AddRemoveBlock from '../../../theme/components/AddRemoveBlock'

class PlusIconControl extends Rete.Control {
  component = ({ onClick }) => {
    return (
      <AddRemoveBlock
        label="Object Property"
        onAdd={() => onClick(true)}
        onRemove={() => onClick(false)} />

    )
  }

  constructor (emitter, node, key, onClick) {
    super(key)
    this.emitter = emitter
    this.props = {
      onClick: isAdding => {
        onClick && onClick(isAdding)
      },
    }
  }
}

export default PlusIconControl
