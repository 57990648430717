const isEqual = require('lodash.isequal')

export default class History {
  constructor() {
    this.active = false;
    this.produced = [];
    this.reverted = [];
  }

  add(action) {
    if (this.active) return;
    this.produced.push(action);
    this.reverted = [];
  }

  clean() {
    this.active = true;
    this.produced = [];
    this.reverted = [];
    this.active = false;
  }

  isSameMultiDragAction(editor) {
    const lastMultiDragAction = this.last;

    const historySelection = lastMultiDragAction.selection.map(({ id }) => id);
    const editorSelection = editor.selected.list.map(({ id }) => id);

    if (historySelection.length !== editorSelection.length) return false;

    return isEqual(historySelection, editorSelection);
  }

  get last() {
    return this.produced[this.produced.length - 1];
  }

  _do(from, to, type) {
    const action = from.pop();
    if (!action) return;

    this.active = true;
    action[type]();
    to.push(action);
    this.active = false;
  }

  undo() {
    this._do(this.produced, this.reverted, 'undo');
  }

  redo() {
    this._do(this.reverted, this.produced, 'redo');
  }
}
