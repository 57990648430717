import StringControl from '../components/Flow/Controls/SocketControls/StringControl'
import NumberControl from '../components/Flow/Controls/SocketControls/NumberControl'
import BooleanControl from '../components/Flow/Controls/SocketControls/BooleanControl'
// TODO: object instead of args
export const getControl = (
  editor,
  node,
  type,
  key,
  name,
  onChange = () => {},
  onBlur,
) => {
  switch (type) {
    case 'string':
      return new StringControl(editor, node, key, name, onChange, onBlur)
    case 'number':
      return new NumberControl(editor, node, key, name, onChange, onBlur)
    case 'boolean':
      return new BooleanControl(editor, node, key, name, onChange)
    default:
  }
}
