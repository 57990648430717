import React from 'react'
import {
  FieldTitle,
} from 'react-admin'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import IconClose from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import {
  useTranslation,
} from 'react-i18next'

interface IColumn {
  label?: string
  source: string
}

interface IProps {
  columns: IColumn[]
  selection: {
    [key: string]: boolean
  }
  onClose: () => void
  onColumnClicked: (columnName: string) => void
}

const SelectionDialog = ({
  columns = [],
  selection,
  onClose,
  onColumnClicked,
}: IProps): JSX.Element => {
  const { t } = useTranslation()

  const handleToggleColumn = ({ target: { value: columnName } }): void => {
    onColumnClicked(columnName)
  }

  return (
    <Dialog maxWidth='xs' onClose={onClose} open>
      <DialogTitle>{t('menu.configuration')}</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map(({ source, label }) => (
            <FormControlLabel
              key={label}
              control={
                <Checkbox
                  checked={selection[source]}
                  onChange={handleToggleColumn}
                  value={source}
                />
              }
              label={<FieldTitle label={label} source={source} />}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          <IconClose />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectionDialog
