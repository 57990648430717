import { parser } from '@obvious.tech/constellation-swagger-parser-admin'
import { swaggerfiles } from '@obvious.tech/constellation'
import EntitiesCustomization from './EntitiesCustomization'
import DefinitionsCustomization from './DefinitionsCustomization'

export default (API_URL) => {
  return parser({
    endpoint: API_URL,
    fetch,
    swaggerfiles,
    options: {
      entities: EntitiesCustomization,
      definitions: DefinitionsCustomization,
    },
  })
}
