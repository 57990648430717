import {
  isHexColor,
} from 'src/adminPanel/screens/inputs/validationHelpers'
import {
  TEntityOverride,
} from 'src/types'

import ImageInput from './Inputs/ImageInput'
import ImageField from './ListFields/ImageField'
import SoundField from './ListFields/SoundField'
import CreateNotificationType from './CreateNotificationType'
import NotificationTypeToolbar from './Actions/EditNotificationTypeToolbar'

const override: TEntityOverride = {
  titleField: 'name',
  global: {
    properties: {
      id: {
        displayedName: 'Id',
      },
      imageFile: {
        type: 'Image',
      },
      color: {
        type: 'color',
        validations: [isHexColor],
      },
      groups: {
        type: 'groups',
      },
    },
  },
  list: {
    properties: {
      imageFile: {
        displayedComponent: ImageField,
      },
      soundFile: {
        displayedComponent: SoundField,
      },
    },
  },
  create: {
    displayedComponent: CreateNotificationType,
    properties: {
      imageFile: {
        hidden: true,
      },
      soundFile: {
        hidden: true,
      },
    },
  },
  edit: {
    displayedFormToolbar: NotificationTypeToolbar,
    properties: {
      imageFile: {
        type: 'Image',
        displayedComponent: ImageInput,
      },
      soundFile: {
        type: 'sound',
      },
    },
  },
}

export default override
