import React from 'react'
import {
  withStyles,
} from '@material-ui/styles'
import {
  IconButton,
} from '@material-ui/core'

const styles = theme => ({
  flowSquareIconButton: {
    borderRadius: '3px',
    fontSize: 15,
    padding: '6px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
  },
})

class FlowSquareIconButton extends React.Component {
  render () {
    const { classes, children, additionalIconClass, ...other } = this.props
    return (
      <IconButton
        {...other}
        className={`${classes.flowSquareIconButton} ${additionalIconClass}`}
      >
        {children}
      </IconButton>
    )
  }
}

export default withStyles(styles)(FlowSquareIconButton)
