import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next';

import * as theme from './theme'

const styles = StyleSheet.create({
  container: {
    padding: theme.padding(2),
  },
  title: {
    ...theme.styles.title,
  },
  content: {
    padding: theme.padding(1),
  },
});

export type TProps = React.PropsWithChildren<{
  title: string,
  bgMain?: string,
}>

export default ({
  title,
  bgMain = theme.colors.sectionOddBg,
  children
}: TProps) => {
  const { t } = useTranslation()

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: bgMain
        },
      ]}
    >
      <Text style={styles.title}>
        {`${t(title)} :`}
      </Text>
      <View style={styles.content}>
        {children}
      </View>
    </View>
  )
}