import React, {
  useState,
} from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'
import {
  useDataProvider,
  useNotify,
} from 'react-admin'
import {
  useDispatch,
  useSelector,
} from 'react-redux'

import * as editorSelectors from 'src/redux/editor/editor.selectors'
import * as editorActions from 'src/redux/editor/editor.actions'
import useErrorFetch from 'src/hooks/useErrorFetch'
import {
  editor,
} from 'src/adminPanel/Flow/components/Flow'
import SimulationForm from './SimulationForm'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    margin: 0,
    padding: '8px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

const getEntity = (): string => {
  const blueprint = editor.toJSON()
  const events = Object.keys(blueprint.nodes)
    .filter(
      (node) =>
        blueprint.nodes[node].name.indexOf('- On ') !== -1 ||
        blueprint.nodes[node].name === 'Schedule',
    )
    .map((node) => blueprint.nodes[node].name)

  if (events.length > 0) {
    const event = events[0].split(' ')
    return event[3]
  }
  return 'Alarm'
}

const SimulationDialog = ({ diagram }): JSX.Element => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const fetchError = useErrorFetch()

  const simulationDialogVisible = useSelector(editorSelectors.simulationDialog)

  const [simulationData, setSimulationData] = useState<{ [key: string]: any }>(
    {
    },
  )

  const onSimulateProcess = async (): Promise<void> => {
    try {
      const passportId = localStorage.getItem('passportId')
      const group = passportId !== null ? `/passport/${passportId}/` : '/'

      const params = {
        data: {
          groups: [group],
          diagramId: diagram?.id,
          isSimulated: true,
          simulationData,
          entity: simulationData,
        },
      }

      await dataProvider.create('flow/api/process', params)

      notify('Simulation process has started.')
      hideSimulationDialog()
    } catch (e) {
      // @ts-expect-error
      await fetchError(e)
    }
  }

  const updateSimulationData = (data): void => {
    setSimulationData({
      ...data,
    })
  }

  const hideSimulationDialog = (): void => {
    dispatch(editorActions.toggleSimulationDialog(false))
  }

  return (
    <Dialog
      onClose={hideSimulationDialog}
      open={simulationDialogVisible}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6">Simulation</Typography>
        <Button onClick={onSimulateProcess} variant="contained" color="primary">
          RUN
        </Button>
      </DialogTitle>
      <DialogContent>
        <SimulationForm
          entity={getEntity()}
          value={simulationData}
          onChange={updateSimulationData}
        />
      </DialogContent>
    </Dialog>
  )
}

export default SimulationDialog
