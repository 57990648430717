export const SET_TEMPLATE = 'app/editor/setTemplate'
export const SAVE_DIAGRAM = 'app/editor/saveDiagram'
export const ADD_BLUEPRINT_TO_LIST_OF_OPENED = 'app/editor/addOpened'
export const UPDATE_OPENED_BLUEPRINT = 'app/editor/updateOpened'
export const REMOVE_OPENED_BLUEPRINT = 'app/editor/removeOpened'
export const MARK_BLUEPRINT_AS_CURRENT = 'app/editor/currentBlueprint'
export const RENAME_BLUEPRINT = 'app/editor/renameBlueprint'
export const SHOW_NEWBLUEPRINT_DIALOG = 'app/editor/showNewBlueprintDialog'
export const SHOW_CHANGE_TITLE_DIALOG = 'app/editor/showChangeTitleDialog'
export const SAVE_WITH_NAME_DIALOG = 'app/editor/saveWithNameDialog'
export const HIDE_NEWBLUEPRINT_DIALOG = 'app/editor/hideNewBlueprintDialog'
export const UPDATE_BLUEPRINT = 'app/editor/updateBlueprint'
export const ADD_BLUEPRINT = 'app/editor/addBlueprint'
export const TOGGLE_SIMULATION_DIALOG = 'app/editor/toggleSimulationDialog'
export const TOGGLE_CONF_DIALOG = 'app/editor/toggleConfDialog'
export const SET_CONF_DIALOG = 'app/editor/setConfDialog'
export const SET_CURRENT_BLUEPRINT = 'app/editor/setCurrentBlueprint'
export const REPLACE_OPENED_WITH = 'app/editor/replaceOpenedWith'
export const CLEAN_OPENED = 'app/editor/cleanOpened'
export const SET_TO_CLOSE_DIAGRAMID = 'app/editor/setToCloseDiagramId'
export const SET_EDITOR_ZOOM_VALUE = 'app/editor/setEditorZoomValue'
export const SET_FLOW_DEFINITIONS = 'app/editor/setFlowDefinitions'
export const ADD_FETCHED_BLUEPRINT = 'app/editor/addFetchedBlueprint'
export const UPDATE_FETCHED_BLUEPRINT = 'app/editor/updateFetchedBlueprint'
