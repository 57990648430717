export const getTypeKeyFromDefinition = (definition) => {
  if (!!definition.enum) {
    return 'enums';
  } else {
    return 'entities';
  }
};

export default async (apiPromise, options = {}) => {
  const api = await apiPromise;
  const { entities, enums } = Object.keys(api.definitions).reduce(
    (acc, key) => {
      const type = getTypeKeyFromDefinition(api.definitions[key]);
      return {
        ...acc,
        [type]: {
          ...acc[type],
          [key]: api.definitions[key],
        },
      };
    },
    {
      enums: {},
      entities: {},
      objects: {},
    }
  );
  return {
    entities: options.entities ? options.entities(entities) : entities,
    enums: options.enums ? options.entities(enums) : enums,
  };
};
