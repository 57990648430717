import React from 'react'
import {
  FileInput,
} from 'react-admin'
import {
  makeStyles,
} from '@material-ui/core'

import {
  IInputProps,
} from 'src/types'
import SoundField from 'src/components/fields/SoundField'

interface IPropertySound {
  id: string
  name: string
}

const useStyles = makeStyles({
  removeButton: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
})

const SoundInput = ({
  name,
  record,
  value,
  resource,
}: IInputProps<IPropertySound>): JSX.Element => {
  const styles = useStyles()

  return (
    <FileInput
      multiple={false}
      classes={{
        removeButton: styles.removeButton,
      }}
      accept="audio/*"
      source={name}
    >
      <SoundField value={value} resource={resource} recordId={record.id} />
    </FileInput>
  )
}

export default SoundInput
