// @ts-nocheck
import {
  UpdateParams,
  CreateParams,
  UpdateResult,
  CreateResult,
  Record as RaRecord
} from 'react-admin'

import dataProvider from './dataProvider'
import { toDataURL } from 'src/utils/file'

function upload (m: 'update', r: string, p: UpdateParams): Promise<UpdateResult<RaRecord>>
// eslint-disable-next-line
function upload (m: 'create', r: string, p: CreateParams): Promise<CreateResult<RaRecord>>
// eslint-disable-next-line
function upload (m: any, r: string, p: any) {
  return dataProvider[m](r, p)
}

function uploadMethodFactory (
  m: 'update'
): (r: string, p: UpdateParams) => Promise<UpdateResult<RaRecord>>
// eslint-disable-next-line
function uploadMethodFactory (
  m: 'create'
): (r: string, p: CreateParams) => Promise<CreateResult<RaRecord>>
// eslint-disable-next-line
function uploadMethodFactory (method: any) {
  return async (resource: string, params: any) => {
    if (resource !== 'resources/api/Image') {
      return await upload(method, resource, params)
    }

    const image = params.data.imageData
    const dataURL = typeof image === 'string' ? image : await toDataURL(image.rawFile)
    const base64 = dataURL?.replace('data:image/png;base64,', '')

    return await upload(method, resource, {
      ...params,
      data: {
        ...params.data,
        imageData: base64
      }
    })
  }
}

const addUploadFeature = {
  ...dataProvider,
  update: uploadMethodFactory('update'),
  create: uploadMethodFactory('create')
}

export default addUploadFeature