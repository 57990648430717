import {
  removeDuplicateWhitespaces,
} from 'src/utils/str'

const makeCSSCheckerboard = (options = {
}) => {
  options = {
    color1: '#ccc',
    color2: '#fff',
    size: options.unit === '%' ? 50 : 1,
    unit: 'px',
    ...options,
  }
  const { color1, color2, size, unit } = options
  const checkerSVGData = encodeURIComponent(
    removeDuplicateWhitespaces(`
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 2 2'
      >
        <path
          fill='${color1}'
          fill-rule='evenodd'
          d='M0,0 h1v1H0V0 z m1,1 h1v1H1V1 z'
        />
      </svg>
    `),
  )

  return {
    background: color2,
    backgroundImage: `url("data:image/svg+xml,${checkerSVGData}")`,
    backgroundSize: `${size * 2}${unit} ${size * 2}${unit}`,
    backgroundPosition: '0 0',
  }
}

export default makeCSSCheckerboard
