import React, { useState } from 'react';
import { Button, useNotify } from 'react-admin';
import ListActions from 'src/adminPanel/screens/List/Actions';
import { Add } from '@material-ui/icons';

const TemplateListActions = (props) => {
  const { resource } = props;
  const notify = useNotify();

  const [, setFetching] = useState(false);

  const uploadTemplate = async (e) => {
    if (!e?.target?.files) return;
    const url = `${window._env_.API_URL}/${resource}/upload`;
    const form = new FormData();
    form.append('file', e.target.files[0]);
    const options = {
      headers: new Headers({
        authorization: localStorage.getItem('token'),
      }),
      method: 'POST',
      body: form,
    };

    try {
      setFetching(true);
      await fetch(url, options).then((response) => response.json());
    } catch (e) {
      notify('Failed to upload Template file.', 'error');
    } finally {
      setFetching(false);
    }
  };

  return (
    <ListActions {...props} hasExport={false} hasCreate={false}>
      <Button color="primary" component="label" label={'Upload File'}>
        <>
          <Add />
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={uploadTemplate}
          />
        </>
      </Button>
    </ListActions>
  );
};

export default TemplateListActions;