import {
  useEffect,
  useCallback,
} from 'react'
import {
  useDispatch,
} from 'react-redux'

import {
  setMicroservices,
} from 'src/redux/microservices/actions'
import {
  setDefinitions,
} from 'src/redux/definitions/definitions.actions'
import {
  setFlowDefinitions,
} from 'src/redux/editor/editor.actions'
import {
  setAPI,
} from 'src/redux/api/api.actions'
import parser from 'src/parser/parser'

const useLocalSwaggerFiles = () => {
  const dispatch = useDispatch()

  const initData = useCallback(async () => {
    const {
      microservices: microservicesPromise,
      paths: pathsPromise,
      definitions: definitionsPromise,
    } = parser(window._env_.API_URL)

    const [microservices, paths, definitions] = await Promise.all([
      microservicesPromise,
      pathsPromise,
      definitionsPromise,
    ])

    const { swaggerDefinitions, ...customizedDefinitions } = definitions

    dispatch(setAPI(paths))
    dispatch(setDefinitions(customizedDefinitions))
    dispatch(setFlowDefinitions(swaggerDefinitions))
    dispatch(setMicroservices(microservices))
  }, [dispatch])

  useEffect(() => {
    initData().finally(() => {})
  }, [initData])
}

export default useLocalSwaggerFiles
