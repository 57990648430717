export const defaultClasses = {
  selected: {
    backgroundColor: 'rgba(26, 68, 255, 0.8) !important',
    borderColor: 'rgb(0, 28, 153) !important',
  },
}

export const TriggerClasses = {
  default: {
    backgroundColor: 'rgba(127, 127, 255, 0.8) !important',
    borderColor: 'rgb(0, 28, 153) !important',
  },
  selected: {
    backgroundColor: 'rgba(63, 63, 127, 0.8) !important',
  },
}

export const ModuleClasses = {
  selected: {
    backgroundColor: 'rgba(26, 68, 255, 0.8) !important',
    borderColor: 'rgb(0, 28, 153) !important',
  },
}
