import Rete from '@obvious.tech/rete';

import Input from 'src/components/flow/Input';
import { validateTouched } from '../../../../helpers/validationHelper';
import compareDiagramChangesHelper from '../../../../helpers/CompareDiagramChangesHelper';

class StringControl extends Rete.Control {
    component = Input;

    constructor(emitter, node, key, label, onChange) {
        super(key);
        this.emitter = emitter;
        this.key = key;

        const initial = node.data[key] ?? '';

        node.data[key] = initial;
        this.props = {
            label,
            value: initial,
            onChange: (value) => {
                this.setValue(value);
                onChange && onChange(value);
            },
            onBlur: () => {
                compareDiagramChangesHelper.compare(this.emitter.toJSON());
                if (!node.validation) return;

                node.validation = {...validateTouched(node) };
                node.update();
            },
            type: 'text',
            multiline: true,
            rows: 1,
            rowsMax: 4,
        };
    }

    setValue(val) {
        this.props.value = val;
        this.putData(this.key, val);
        this.update();
    }
}

export default StringControl;