// @ts-nocheck
import React, {
  useState,
  useEffect,
  useRef,
} from 'react'
import {
  Box,
} from '@material-ui/core'
import {
  useEditController,
  EditContextProvider,
  Record,
} from 'react-admin'
import {
  IRoleTemplate,
} from '@obvious.tech/constellation'

import {
  TEntityOverrideView,
} from 'src/types'
import {
  TAuthorizationTypedData,
} from 'src/api/authorizationTypes'
import EditAuthorizationForm from 'src/components/Authorizations/EditAuthorizationForm'
import Container from 'src/components/Container'

import Authorizations from '../Authorizations'

interface IProps {
  resource: string
  entity: TEntityOverrideView<'edit', IRoleTemplate>
  basePath: string
  id: string
}

const EditRoleTemplate = ({
  entity,
  basePath,
  id,
  resource,
}: IProps): JSX.Element => {
  const controllerProps = useEditController({
    basePath,
    id,
    resource,
  })
  const authorizationsRef = useRef<TAuthorizationTypedData[]>([])
  const { record } = controllerProps

  const [recordAuthorizations, setRecordAuthorizations] = useState<
  TAuthorizationTypedData[]
  >([])

  useEffect(() => {
    if (window.isNullish(record)) return

    setRecordAuthorizations(record?.authorizations)
  }, [record])

  useEffect(() => {
    // react-admin transform uses ref, to pass actual authorizations using ref
    authorizationsRef.current = recordAuthorizations
  }, [recordAuthorizations])

  const transformRoleTemplate = (record: Record): Record => ({
    ...record,
    authorizations: authorizationsRef.current,
  })

  return (
    <EditContextProvider value={controllerProps}>
      <Box display="flex" alignItems="flex-start">
        <EditAuthorizationForm
          recordNameField="name"
          title="Role template"
          transform={transformRoleTemplate}
          resource={resource}
          entity={entity}
          id={id}
          basePath={basePath}
        />
        <Container minWidth="300px" data-app="CustomDatagridContainer">
          <Authorizations
            recordAuthorizations={recordAuthorizations}
            setRecordAuthorizations={setRecordAuthorizations}
          />
        </Container>
      </Box>
    </EditContextProvider>
  )
}

export default EditRoleTemplate
