import React from 'react'
import {
  Filter,
  TextInput,
} from 'react-admin'
import {
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const CustomFilter = ({ entity, name, microservice, ...props }) => {
  const classes = useStyles()
  const filters = Object.keys(entity.properties)
    .filter(
      (propertyKey) =>
        !entity.properties[propertyKey].hidden &&
        entity.properties[propertyKey].type === 'string',
    )
    .map((propertyKey) => {
      const { type } = {
        ...entity.properties[propertyKey],
        ...(entity?.filter?.[propertyKey] || {
        }),
      }
      return type === 'string'
        ? (
        <TextInput
          label={`${propertyKey.charAt(0).toUpperCase()}${propertyKey.slice(
            1,
          )}`}
          key={propertyKey}
          source={propertyKey}
        />
          )
        : (
        <span key={propertyKey} />
          )
    })

  return (
    <Filter className={classes.root} key="filter" {...props}>
      <TextInput key="simple_search" label="Search" source="q" alwaysOn />
      {filters}
    </Filter>
  )
}
export default CustomFilter
