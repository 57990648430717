import React, {
  useEffect,
  useState,
} from 'react'
import {
  useRedirect,
} from 'react-admin'
import parse from 'html-react-parser'
import axios from 'axios'

import {
  TEntityOverrideView,
} from 'src/types'
import CustomLoader from 'src/adminPanel/screens/Loader/CustomLoader'
import useErrorFetch from 'src/hooks/useErrorFetch'
import LicenseFields from './LicenseFields'
import TitlePortal from 'src/components/TitlePortal'
import CustomTitle from 'src/adminPanel/screens/Title/Title'

type TLicensedValues = 'yes' | 'no'
export interface IParsedLicense {
  // TODO: to change into literals after TS 4.4 update
  [key: string]: TLicensedValues | string

  id: string
  type: string
  expiration: string
  name: string
  email: string
  signature: string
}

interface IProps {
  resource: string
  entity: TEntityOverrideView<'edit'>
  basePath: string
  id: string
}

const EditLicense = ({
  resource,
  entity,
  basePath,
  id,
}: IProps): JSX.Element | null => {
  const fetchError = useErrorFetch()
  const redirect = useRedirect()

  const [license, setLicense] = useState<IParsedLicense | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (window.isNullish(id) || window.isNullish(resource)) return

    const parseLicense = (licenseMarkup: JSX.Element): IParsedLicense => {
      const { children } = licenseMarkup.props
      if (!Array.isArray(children)) { throw new Error('Cannot parse license. Wrong license format.') }

      return children
        .map((child) => {
          if (Array.isArray(child.props.children)) {
            return child.props.children
          }

          return child
        })
        .flat()
        .reduce((acc, { type, props }) => {
          acc[props.name ?? type] = props.children
          return acc
        }, {
        })
    }

    const fetchLicense = async (): Promise<void> => {
      try {
        setLoading(true)
        const { data } = await axios.get(
          `${window._env_.API_URL}/${resource}/${id}`,
          {
            headers: {
              authorization: localStorage.getItem('token'),
            },
          },
        )

        const licenseMarkup = parse(data, {
          trim: true,
          htmlparser2: {
            xmlMode: true,
          },
        })
        if (typeof licenseMarkup === 'string') return

        setLicense(
          parseLicense(
            Array.isArray(licenseMarkup) ? licenseMarkup[0] : licenseMarkup,
          ),
        )
      } catch (e) {
        const error = axios.isAxiosError(e)
          ? {
              message: e.response?.data.errorMessage,
              status: e.response?.status,
            }
          : e

        // @ts-expect-error
        fetchError(error).finally(() => {})
        redirect(basePath)
      } finally {
        setLoading(false)
      }
    }

    fetchLicense().finally(() => {})
  }, [id, resource, fetchError, basePath, redirect])

  if (loading) {
    return (
      <CustomLoader
        loadingPrimary="Fetching License"
        loadingSecondary="License is being parsed."
      />
    )
  }

  if (window.isNullish(license)) return null

  return (
    <>
      <TitlePortal
        title={<CustomTitle record={null} entity={entity} disableCapitalize />}
      />
      <LicenseFields resource={resource} license={license} />
    </>
  )
}

export default EditLicense
