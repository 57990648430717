// @ts-nocheck
import { EDITOR_CONTAINER_CLASS } from 'src/adminPanel/Flow/Editor'

import Editor from './Editor'

export interface ITranslate {
    x: number
    y: number
}

export interface ITranslateEvent {
    translated: ITranslate,
        delta: ITranslate,
        mouse: ITranslate,
}

const DEFAULT_PAN_SPEED = 500 // pixels per second
const TRANSLATE_INTERVAL = 50 // milliseconds

/**
 * Maximum pixels distance from editor edge where the pan should be triggered
 */
const DEFAULT_EDGE_SIZE: ITranslate = { x: 100, y: 100 }

export default abstract class PanEditor {
    editor: Editor
    edgeSize: ITranslate
    isTranslating: boolean = false
    translateInterval: number | undefined
    translated: ITranslate | null = null
    container: HTMLElement | null = document.querySelector(`.${EDITOR_CONTAINER_CLASS}`)
    private _panSpeed: number = 0

    constructor({
        editor,
        edgeSize = DEFAULT_EDGE_SIZE,
        /** pixels per second */
        panSpeed = DEFAULT_PAN_SPEED
    }) {
        this.edgeSize = edgeSize
        this.editor = editor

        this.panSpeed = panSpeed
    }

    set panSpeed(speed: number) {
        this._panSpeed = speed * (TRANSLATE_INTERVAL / 1000)
    }

    get panSpeed(): number {
        return this._panSpeed
    }

    get computedContainerStyles(): null | CSSStyleDeclaration {
        if (window.isNullish(this.container)) return null

        return window.getComputedStyle(this.container)
    }

    getSpeed(mouse: ITranslate): ITranslate {
        if (window.isNullish(this.computedContainerStyles)) return {
            x: 0,
            y: 0,
        }

        const width = parseInt(this.computedContainerStyles.width)
        const height = parseInt(this.computedContainerStyles.height)
        const insideEdgeSize: ITranslate = {
            x: width - this.edgeSize.x * 2,
            y: height - this.edgeSize.y * 2,
        }
        const clampedPos: ITranslate = {
            x: Math.min(Math.max(0, mouse.x), width),
            y: Math.min(Math.max(0, mouse.y), height),
        }
        const distFromCenter: ITranslate = {
            x: clampedPos.x - width / 2,
            y: clampedPos.y - height / 2,
        }

        return {
            x: (
                Math.abs(distFromCenter.x) > insideEdgeSize.x / 2 ?
                (Math.abs(distFromCenter.x) - insideEdgeSize.x / 2) / this.edgeSize.x *
                (distFromCenter.x > 0 ? 1 : -1) *
                this.panSpeed :
                0
            ),
            y: (
                Math.abs(distFromCenter.y) > insideEdgeSize.y / 2 ?
                (Math.abs(distFromCenter.y) - insideEdgeSize.y / 2) / this.edgeSize.y *
                (distFromCenter.y > 0 ? 1 : -1) *
                this.panSpeed :
                0
            )
        }
    }

    handleTranslate(mouse: ITranslate): void {
        this.translated = {...this.editor.view.area.transform }

        if (window.isNullish(this.container)) {
            console.error(`couldn't find editor container by class - ${EDITOR_CONTAINER_CLASS}`)
            return
        }

        if (!this.isTranslating) return

        const delta = this.getSpeed(mouse)

        this.translated.x -= delta.x
        this.translated.y -= delta.y

        this.onTranslate?.({
            translated: {...this.translated },
            delta,
            mouse,
        })
    }

    abstract onTranslate(
        event: ITranslateEvent
    ): void

    handleTranslateStart(mouse: ITranslate): void {
        this.isTranslating = true

        clearInterval(this.translateInterval)
        this.translateInterval = window.setInterval(() => {
            this.handleTranslate(mouse)
        }, TRANSLATE_INTERVAL)
    }

    handleTranslateEnd(): void {
        if (!this.isTranslating) return

        clearInterval(this.translateInterval)
        this.isTranslating = false
    }
}