export function drag (el, getInitial = () => {}, onDrag = () => {}) {
  let start = null
  let initial = null

  const startProcess = (e) => {
    initial = getInitial()
    start = [e.pageX, e.pageY]
  }

  const translate = (e) => {
    if (!start) return
    const [dx, dy] = [start[0] - e.pageX, start[1] - e.pageY]

    onDrag(dx, dy, initial)
  }

  const finishProcess = () => {
    start = null
  }

  el.addEventListener('mousedown', startProcess)
  window.addEventListener('mousemove', translate)
  window.addEventListener('mouseup', finishProcess)

  const removeListeners = () => {
    el.removeEventListener('mousedown', startProcess)
    window.removeEventListener('mousemove', translate)
    window.removeEventListener('mouseup', finishProcess)
  }

  return { removeListeners }
}
