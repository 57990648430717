import {
  HttpError,
} from 'react-admin'

// TO-DO fix dataProvider's ts-ignores when typed properly
export const fetchJson = async (url, options = {
}) => {
  const requestHeaders =
    options.headers ||
    new Headers({
      Accept: 'application/json',
    })
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json')
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token)
  }
  const response = await fetch(url, {
    ...options,
    headers: requestHeaders,
  })
  const text = await response.text()

  const resObject = {
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
    json: text && JSON.parse(text),
  }

  if (resObject.status < 200 || resObject.status >= 300) {
    let errorMessage = resObject.json && resObject.json.errorMessage
    try {
      const invalidDataMessages = Object.values(
        JSON.parse(JSON.parse(errorMessage)),
      )
        .filter((errorArr) => errorArr?.length)
        .map((err) => err[0])
      console.log('invalidDataMessages', invalidDataMessages)
      errorMessage = invalidDataMessages?.length
        ? invalidDataMessages[0]
        : errorMessage
    } catch {}
    return Promise.reject(
      new HttpError(
        errorMessage || resObject.statusText,
        resObject.status,
        resObject.json,
      ),
    )
  }

  return Promise.resolve(resObject)
}
