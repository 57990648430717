import {
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, {
  FunctionComponent,
} from 'react'
import {
  IInputProps,
} from 'src/types'
import GenericInput from './GenericInput'

const useStyles = makeStyles({
  flex: {
    display: 'flex',
  },
  coordinateLabel: {
  },
  CoordinatesInput: {
  },
  coordinateMap: {
  },
  coordinateInputs: {
    margin: 8,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  fullfill: {
  },
})

const ObjectInput: FunctionComponent<IInputProps<Object>> = ({
  onChange,
  value,
  name,
  property,
}: IInputProps<Object>) => {
  const {
    properties = {
    }, required, additionalProperties,
  } = property
  let additionalData
  if (additionalProperties) {
    additionalData = Object.keys(value).filter(
      (key) => !Object.keys(properties).includes(key),
    )
  }
  const classes = useStyles()

  const handleInputProperty = (
    propertyKey: string,
  ): { [key: string]: any } => ({
    required: required?.includes(propertyKey) ?? false,
    readOnly: property?.readOnly ?? false,
    ...properties[propertyKey],
  })

  return (
    <div>
      <Typography variant="body1">{property.displayedName ?? name}</Typography>
      <div className={classes.coordinateInputs}>
        {Object.keys(properties).map((propertyKey) => {
          return (
            <GenericInput
              key={propertyKey}
              // @ts-expect-error
              property={handleInputProperty(propertyKey)}
              name={propertyKey}
              onChange={(e) => {
                onChange({
                  ...value,
                  [propertyKey]: e,
                })
              }}
              value={value && value[propertyKey]}
              label={propertyKey}
              placeholder={propertyKey}
            />
          )
        })}
        {additionalData &&
          additionalData.map((propertyKey) => {
            return (
              <GenericInput
                key={propertyKey}
                // @ts-expect-error
                property={property.additionalProperties}
                name={propertyKey}
                onChange={(e) => {
                  onChange({
                    ...value,
                    [propertyKey]: e,
                  })
                }}
                value={value && value[propertyKey]}
                label={propertyKey}
                placeholder={propertyKey}
              />
            )
          })}
      </div>
    </div>
  )
}

export default ObjectInput
