// @ts-nocheck
import React from 'react'
import {
  CircularProgress,
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Record,
} from 'ra-core'
import {
  useTranslate,
} from 'react-admin'

type TAutocompleteInputProps = {
  referencedField: string
  input: string
  onChange: any
  choices: Record[]
  loading: boolean
  freeSolo: boolean
  label: string
  disabled: boolean
  onInputChange: any
  placeholder: string
  getOptionLabel?: (record: Record) => string
  onChangeValue?: string
  error: any
  refreshing?: boolean
}

const AutocompleteInput = ({
  choices = [],
  referencedField = 'id',
  loading,
  refreshing,
  onChange,
  onInputChange,
  input,
  label,
  disabled,
  getOptionLabel,
  onChangeValue = 'id',
  error,
  ...props
}: TAutocompleteInputProps) => {
  const translate = useTranslate()

  const choice = choices.find((choice: Record) => {
    return choice[referencedField] === input
  })

  const handleOptionLabel = (getOptionLabel != null) ? getOptionLabel : (option: Record) => option[referencedField] || ''

  const handleHelperText = () => {
    if (!error) return ''

    return translate(error?.message ? error.message : error)
  }

  const handleMatchingOption = (input: string) => {
    const option = choices.find((choice) => choice[referencedField] === input)
    const value = (option != null) ? option[onChangeValue] : input
    onChange(value)
  }

  return (
    <Autocomplete
      {...props}
      options={choices}
      onChange={(e, value: any) => {
        onChange(value ? value[onChangeValue] : undefined)
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (loading) return
        if (reason === 'reset' && !newInputValue) return

        onInputChange(newInputValue || '')
        handleMatchingOption(newInputValue)
      }}
      openOnFocus
      loading={loading}
      fullWidth
      freeSolo
      loadingText="Loading..."
      getOptionLabel={handleOptionLabel}
      inputValue={input || ''}
      getOptionSelected={(option, value) => {
        if (value === null || typeof value !== 'object') return false

        return value.id === option.id
      }}
      value={(choice != null) || null}
      id="disable-close-on-select"
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={disabled}
          value={input}
          label={label}
          helperText={handleHelperText()}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {(loading || refreshing) && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default AutocompleteInput
