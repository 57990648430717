// @ts-nocheck
import React, {
  useState,
  useEffect,
  useRef,
} from 'react'
import {
  Box,
} from '@material-ui/core'
import {
  useCreateController,
  Record,
  CreateContextProvider,
} from 'react-admin'

import Authorizations from '../Authorizations'

import CreateForm from './CreateForm'
import {
  TEntityOverrideView,
} from 'src/types'
import {
  TAuthorizationTypedData,
} from 'src/api/authorizationTypes'

type TProps = {
  resource: string
  entity: TEntityOverrideView<'create'>
  basePath: string
}

const CreateRoleTemplate = ({ entity, basePath, resource }: TProps) => {
  const controllerProps = useCreateController({
    basePath,
    resource,
  })
  const authorizationsRef = useRef<TAuthorizationTypedData[]>([])

  const [recordAuthorizations, setRecordAuthorizations] = useState<
  TAuthorizationTypedData[]
  >([])

  useEffect(() => {
    // react-admin transform uses ref, to pass actual authorizations using ref
    authorizationsRef.current = recordAuthorizations
  }, [recordAuthorizations])

  const transformRoleTemplate = (record: Record) => ({
    ...record,
    authorizations: authorizationsRef.current,
  })

  return (
    <CreateContextProvider value={controllerProps}>
      <Box display="flex" alignItems="flex-start">
        <Box boxShadow={3} mr={2} minWidth="300px">
          <CreateForm
            entity={entity}
            transform={transformRoleTemplate}
            resource={resource}
            basePath={basePath}
          />
        </Box>
        <Box
          boxShadow={3}
          width="100%"
          minWidth="300px"
          data-app="CustomDatagridContainer"
        >
          <Authorizations
            recordAuthorizations={recordAuthorizations}
            setRecordAuthorizations={setRecordAuthorizations}
          />
        </Box>
      </Box>
    </CreateContextProvider>
  )
}

export default CreateRoleTemplate
