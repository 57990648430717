import React from 'react'
import {
  Box,
} from '@material-ui/core'
import {
  useEditController,
  EditContextProvider,
} from 'react-admin'
import {
  IPassport,
} from '@obvious.tech/constellation'

import {
  TEntityOverrideView,
} from 'src/types'
import Container from 'src/components/Container'

import User from './User'
import Authorizations from './Authorizations'

interface IProps {
  resource: string
  entity: TEntityOverrideView<'edit', IPassport>
  basePath: string
  id: string
}

const EditPassport = ({
  entity,
  basePath,
  id,
  resource,
}: IProps): JSX.Element => {
  const controllerProps = useEditController({
    basePath,
    id,
    resource,
  })

  return (
    <EditContextProvider value={controllerProps}>
      <Box display="flex" alignItems="flex-start">
        <User resource={resource} entity={entity} id={id} basePath={basePath} />

        <Container minWidth="400px" data-app="CustomDatagridContainer">
          <Authorizations id={id} />
        </Container>
      </Box>
    </EditContextProvider>
  )
}

export default EditPassport
