import React from 'react'
import {
  makeStyles,
} from '@material-ui/core'
import {
  DatePicker,
} from '@material-ui/pickers'
import {
  useInput,
} from 'react-admin'

const useStyles = makeStyles((theme: any) => ({
  datePicker: theme.inputs.textfield,
}))

const DateInput = ({ property, name, ...props }) => {
  const classes = useStyles()
  const { input: { onChange, value } } = useInput({
    source: name,
    ...props,
  })
  return (
    <DatePicker
      className={classes.datePicker}
      value={value}
      label={property.displayedName}
      onChange={(e) => {
        onChange(e.toUTCString())
      }}
    />
  )
}

export default DateInput
