import React from 'react'
import {
  BulkDeleteButton,
} from 'react-admin'

const BulkActionButtons = ({
  children,
  hasList,
  hasShow,
  withDelete = true,
  ...props
}) => {
  return (
    <div>
      {children}
      {withDelete && <BulkDeleteButton {...props} />}
    </div>
  )
}

export default BulkActionButtons
