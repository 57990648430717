import React from 'react'
import {
  useSelector,
} from 'react-redux'
import {
  Edit,
  useEditController,
  EditContextProvider,
} from 'react-admin'
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
  capitalize,
  Box,
  withStyles,
} from '@material-ui/core'
import {
  IDiagram,
  IModule,
} from '@obvious.tech/constellation'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  TRootState,
} from 'src/redux'
import {
  TEntityOverrideView,
} from 'src/types'

import InputList from '../screens/inputs/InputList'
import CustomTitle from '../screens/Title/Title'
import EditorComponent from './EditorComponent'
import DiagramMenu from './DiagramMenu'

import './theme/mains.scss'

const Accordion = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {
  },
}))(MuiAccordion)

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  accordionDetails: {
    paddingTop: 0,
  },
  editFormWrapper: {
    width: '100%',
  },
}))

interface IEditorProps {
  resource: string
  entity: TEntityOverrideView<'edit', IDiagram | IModule>
  basePath: string
  id: string
}

const Editor = ({
  entity,
  basePath,
  resource,
  id,
  ...props
}: IEditorProps): JSX.Element => {
  const classes = styles()
  const controllerProps = useEditController({
    basePath,
    id,
    resource,
  })

  const editor = useSelector((state: TRootState) => state.editor)

  const currentBlueprint = editor.openedBlueprints?.find(
    ({ id }) => id === editor.currentBlueprint,
  )
  const diagramName = window.isNullish(currentBlueprint?.name)
    ? 'blueprint'
    : currentBlueprint.name

  return (
    <EditContextProvider value={controllerProps}>
      <Box
        className={classes.root}
        boxShadow={3}
        borderRadius={4}
        overflow="hidden"
      >
        <DiagramMenu />

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">{capitalize(diagramName)}</Typography>
              <Typography color="primary">DETAILS</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Edit
              title={<CustomTitle record={null} entity={entity} />}
              basePath={basePath}
              resource={resource}
              className={classes.editFormWrapper}
              id={id}
              key={id}
            >
              <InputList entity={entity} {...props} />
            </Edit>
          </AccordionDetails>
        </Accordion>
        <EditorComponent />
      </Box>
    </EditContextProvider>
  )
}

export default Editor
