import Rete from '@obvious.tech/rete'
import {
    capitalize,
} from '@material-ui/core'

import {
    sockets,
} from '../../..'
import {
    isRequired,
} from '../../../../../helpers/validationHelper'

export default class ArrayForEachComponent extends Rete.Component {
    validation = {
        errorRules: {
            act: [isRequired('connection is required')],
            out: [isRequired('connection is required')],
            forEachList: [isRequired('connection is required')],
        },
        warnRules: {
            action: [isRequired('output is not connected')],
        },
    }

    constructor() {
        super('ForEach')
        this.contextMenuPath = ['Arrays']
        this.task = {
            outputs: {
                out: 'output',
                action: 'option',
                done: 'output',
            },
        }
        this.filters = []
        this.arrayInput = {
            inputKey: 'forEachList',
            get: (node) => node.inputs.get(this.arrayInput.inputKey),
            changeName: (node, name = 'Entity[]') =>
                (this.arrayInput.get(node).name = name),
            reteInput: (inputName = 'Entity[]') =>
                new Rete.Input(
                    this.arrayInput.inputKey,
                    inputName,
                    sockets.arraySocket,
                ),
        }
    }

    builder(node) {
        const actInput1 = new Rete.Input('act', 'Execute', sockets.actionSocket)

        const actOutput1 = new Rete.Output('action', 'Loop', sockets.actionSocket)
        const actOutput2 = new Rete.Output('done', 'Done', sockets.actionSocket)

        node
            .addInput(actInput1)
            .addInput(this.arrayInput.reteInput('Entities[]'))
            .addOutput(actOutput1)
            .addOutput(actOutput2)

        this.addEntityOutput(node)
    }

    addEntityOutput(node) {
        if (!node.data.entity) {
            const output = new Rete.Output('out', 'Entity', sockets.anyTypeSocket)
            node.addOutput(output)
            return output
        }

        const nodeNamesToChange = ['array']

        function getSocketType() {
            return !node.data.entity ?
                sockets.anyTypeSocket :
                sockets[node.data.entity + 'Socket']
        }

        const entityOutput = new Rete.Output(
            'out', !node.data.entity || nodeNamesToChange.includes(node.data.entity) ?
            'Item' :
            node.data.entity,
            getSocketType(),
        )
        node.addOutput(entityOutput)

        return entityOutput
    }

    reDrawNode(node) {
        node.update()
        node && this.editor.view.updateConnections({
            node,
        })
    }

    updateNodeForEntity = (node, entity) => {
        if (node.data.entity !== entity) {
            this.resetOutputs(node)
            this.saveEntityToData(node, entity)
            this.addEntityOutput(node)
        }

        this.arrayInput.changeName(
            node,
            `${entity ? capitalize(entity) : 'Array'}[]`,
        )
        this.reDrawNode(node)
    }

    saveEntityToData(node, entity) {
        node.data.entity = entity
    }

    removeEntityFromData(node) {
        delete node.data.entity
    }

    resetNode(node) {
        this.resetOutputs(node)
        this.arrayInput.changeName(node, 'Entity[]')
        this.reDrawNode(node)
    }

    resetOutputs(node) {
        let input
        node.outputs.forEach((o) => {
            if (['action', 'done'].includes(o.key)) return
            o.connections.forEach((connection) => {
                this.editor.removeConnection(connection)
                input = connection.input
            })
            node.removeOutput(o)
        })
        this.removeEntityFromData(node)
        return input
    }

    connected = (connection) => {
        if (connection.input.key === this.arrayInput.inputKey) {
            setTimeout(() => {
                this.updateNodeForEntity(
                    connection.input.node,
                    connection.output.socket.name.split('Array')[0],
                )
            })
        }
    }

    disconnected = (connection) => {
        if (connection.input.key === this.arrayInput.inputKey) {
            setTimeout(() => {
                this.updateNodeForEntity(connection.input.node)
            })
        }
    }
}