import Rete from '@obvious.tech/rete'
import {
    sockets,
} from '../..'
import FileInputControl from './FileInputControl'
import {
    FileHelper,
} from '../../../../helpers/FileHelper'

export default class FileUploadComponent extends Rete.Component {
    constructor() {
        super('File Upload')
        this.task = {
            outputs: {
                res: 'output',
            },
        }
        this.contextMenuPath = ['Inputs']
    }

    builder(node) {
        return node
            .addControl(
                new FileInputControl(
                    this.editor,
                    node,
                    'file',
                    'Script file (.js)',
                    null, {
                        accept: '.js',
                    },
                ),
            )
            .addOutput(new Rete.Output('res', 'Content(text)', sockets.stringSocket))
    }

    async worker(node) {
        if (node.data.file && node.data.file[0] && node.data.file[0].type) {
            // const script = document.createElement("script");
            // // script.setAttribute("src", FileHelper.fileToUrl(node.data.file[0]));
            // // script.setAttribute("type", "module");
            // FileHelper.fileToString(node.data.file[0]).then((res) => {
            //     script.append(res);
            //     head.appendChild(script);
            // });

            // return {
            //     res: FileHelper.fileToUrl(node.data.file[0]),
            // };
            return await FileHelper.fileToString(node.data.file[0]).then((res) => {
                return {
                    res,
                }
            })
        }
        return {
            res: undefined,
        }
    }
}