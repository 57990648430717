/*
  TO-DO:
  Extract snippet to be able to compute the scrollbar width for any component.
  This doesn't take into account the styles that may be applied to the scrollbar container...
*/
const getScrollbarWidth = () => {
  const scrollDiv = document.createElement('div')
  scrollDiv.setAttribute(
    'style',
    'width: 100px;height: 100px;overflow: scroll;position: absolute;top: -9999px;',
  )
  document.body.appendChild(scrollDiv)
  const SCROLLBAR_WIDTH = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)

  return SCROLLBAR_WIDTH
}

export default getScrollbarWidth
