import {
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'

import {
  IInputProps,
} from 'src/types'

const EnumInput = ({
  value,
  onChange,
  property,
  id,
  loading = false,
  name,
}: IInputProps<string | null>) => {
  const mapEnumToString = (arr: Array<string | number>) =>
    arr.map((el) => (typeof el === 'string' ? el : el.toString()))

  const options = Array.isArray(property.enum)
    ? mapEnumToString(property.enum)
    : []

  return (
    <Autocomplete
      options={options}
      onChange={(e, value) => {
        onChange(value)
      }}
      fullWidth
      value={value}
      id={id}
      loading={loading}
      openOnFocus
      renderInput={(params) => (
        <TextField
          {...params}
          value={value}
          label={property.displayedName ?? name}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default EnumInput
