import React from 'react'
import {
  EditButton,
  List,
} from 'react-admin'
import EnhancedImageField from '../../../components/fields/ImageField'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

const cardStyle = {
  flex: 1,
  width: 300,
  minHeight: 100,
  margin: '0.5em',
  display: 'inline-block',
  verticalAlign: 'top',
}

const ImageTitle = ({ record }) => {
  return <span>Image {record ? `"${record.name}"` : ''}</span>
}

const ImageCard = ({ id, data, basePath, ...props }) => (
  <Card key={id} style={cardStyle}>
    <CardHeader title={<ImageTitle record={data} source="name" />} />
    <CardContent>
      <EnhancedImageField record={data} source="imageData" />
    </CardContent>
    <CardActions style={{
      textAlign: 'right',
    }}>
      <EditButton resource="images" basePath={basePath} record={data} />
    </CardActions>
  </Card>
)

const ImageList = ({ ids, data, basePath, ...rest }) => {
  return (
    <div style={{
      margin: '1em',
    }}>
      {ids.map((id) => (
        <ImageCard data={data[id]} basePath={basePath} id={id} />
      ))}
    </div>
  )
}

export default ({ entity, name, ...props }) => {
  return (
    <List {...props}>
      <ImageList />
    </List>
  )
}
