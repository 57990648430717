import { useState, useEffect, useMemo } from 'react'
import { ILog, IAlarm, IPassport } from '@obvious.tech/constellation'

import api, { TApiError, TEntity } from 'src/utils/api'
import Loadable, { TLoadable } from 'src/utils/Loadable'

const getPassportsByIDs = ({ ids, token}: {
  ids: string[],
  token?: string
}) => api.getResourcesWithFilters<IPassport>('authentication/api/Passport')({
  filters: [
    ['id', api.Filter.Eq, ids]
  ],
  token,
})

const getLogsByAlarmID = ({ id, token}: {
  id: string,
  token?: string
}) => api.getResourcesWithFilters<ILog>('alarms/api/Log')({
  filters: [
    ['alarmId', api.Filter.Eq, id]
  ],
  token,
})

export type TParsedLogs = Array<TEntity<ILog>>

export type TUseLogsResult = TLoadable<TParsedLogs, TApiError>

export type TProps = {
  alarm: TEntity<IAlarm>
  authorization?: string
}

const useLogs = ({ alarm, authorization }: TProps): TUseLogsResult => {
  const [alarmLogs, getAlarmLogs] = api.useApiAction(getLogsByAlarmID)
  const [passports, getPassports] = api.useApiAction(getPassportsByIDs)
  const [parsedLogs, setParsedLogs] = useState<TParsedLogs | null>(null)
  const passportIDs = useMemo(
    () => alarmLogs.data?.map(log => log.origin) ?? []
  , [alarmLogs])

  useEffect(() => {
    return getAlarmLogs({
      id: alarm.id,
      token: authorization,
    })
  }, [alarm, authorization, getAlarmLogs])

  useEffect(() => {
    if (!Loadable.isSuccess(alarmLogs)) return
    if (passportIDs.length === 0) return
    
    return getPassports({
      ids: passportIDs,
      token: authorization,
    })
  }, [passportIDs, alarmLogs, authorization, getPassports])

  useEffect(() => {
    if (!Loadable.isSuccess(alarmLogs)) return
    if (!Loadable.isSuccess(passports) && passportIDs.length !== 0) return

    setParsedLogs(
      alarmLogs.data.map(
        log => {
          const username = (passports.data ?? [])
            .find(e => e.id === log.origin)?.username

          return {
            ...log,
            origin: username ?? log.origin
          }
        }
      )
    )
  }, [alarmLogs, passports, passportIDs])

  if (Loadable.isFailure(alarmLogs))
    return Loadable.failure(alarmLogs.error)
  if (Loadable.isFailure(passports))
    return Loadable.failure(passports.error)
  if ([alarmLogs, passports].some(e => e.loading))
    return Loadable.loading()
  if (parsedLogs !== null)
    return Loadable.success(parsedLogs)
  return Loadable.idle()
}

export default useLogs
