import React, {
  useCallback,
} from 'react'
import {
  useFormState,
} from 'react-final-form'
import {
  SaveButton,
  useRedirect,
  useNotify,
} from 'react-admin'
import {
  connect,
} from 'react-redux'

const PostLicenseButton = ({ postLicense, basePath, redirect, ...props }) => {
  const redirectTo = useRedirect()
  const notify = useNotify()
  // get values from the form
  const formState = useFormState()
  const { licenseText: licenceFile } = formState.values

  const handleClick = useCallback(() => {
    postLicense &&
      postLicense
        .call(localStorage.getItem('token'), licenceFile)
        .then((res) => {
          notify('License file is uploading', 'info', {
            smart_count: 1,
          })
          redirectTo('/license/api/License')
        })
  }, [licenceFile, notify, postLicense, redirectTo])

  return (
    <SaveButton
      {...props}
      label="Post Licence"
      redirect="list"
      submitOnEnter={false}
      variant="text"
      handleSubmitWithRedirect={handleClick}
      disabled={false}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    postLicense: state.api?.License?.functions?.upload,
  }
}

const mapDispatchToProps = (dispatch) => ({
})

const ConnectedSaveButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PostLicenseButton)

export default ConnectedSaveButton
