// @ts-nocheck
import React, {
  useEffect,
  useState,
  useRef,
} from 'react'
import {
  styled,
} from '@material-ui/core/styles'
import {
  HttpError,
  Record,
} from 'react-admin'
import {
  Typography,
  IconButton,
  Box,
  CircularProgress,
} from '@material-ui/core'

import PauseIcon from '@material-ui/icons/Pause'
import PlayIcon from '@material-ui/icons/PlayArrow'

import useErrorFetch from 'src/hooks/useErrorFetch'

interface ISoundFieldProps {
  name: string
  record: Record
  resource: string
}

const SoundField = ({
  name,
  record,
  resource,
}: ISoundFieldProps): JSX.Element => {
  const fetchError = useErrorFetch()
  const uploadedSound = record?.[name]
  const soundRef = useRef<HTMLAudioElement>(null)

  const [soundFile, setSoundFile] = useState<string>()
  const [isPlaying, setIsPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    const audio = soundRef.current
    if (window.isNullish(audio)) return

    audio.addEventListener('pause', () => {
      setIsPlaying(false)
    })

    audio.addEventListener('play', () => {
      setIsPlaying(true)
    })

    audio.addEventListener('timeupdate', handleProgress)
  }, [])

  const fetchSoundFile = async (): Promise<string | undefined> => {
    const url = `${window._env_.API_URL}/${resource}/${record.id}/sound/download`
    const options = {
      headers: {
        authorization: localStorage.getItem('token') ?? '',
      },
      method: 'GET',
    }

    try {
      setFetching(true)
      const response = await fetch(url, options)
      if (!response.ok) {
        const error = await response.json()
        const errorMessage = window.isNullish(error.errorMessage)
          ? 'Failed to fetch audio.'
          : error.errorMessage

        throw new HttpError(errorMessage, response.status)
      }

      const audioBlob = await response.blob()
      const audioUrl = window.URL.createObjectURL(audioBlob)

      setSoundFile(audioUrl)
      return audioUrl
    } catch (e) {
      await fetchError(e)
    } finally {
      setFetching(false)
    }
  }

  const handleSound = async (): Promise<void> => {
    if (window.isNullish(soundFile)) {
      const audioUrl = await fetchSoundFile()

      if (window.isNullish(audioUrl)) return
      if (window.isNullish(soundRef.current)) return

      await soundRef.current.play()
      return
    }

    if (window.isNullish(soundRef.current)) return
    const method = soundRef.current?.paused ? 'play' : 'pause'

    await soundRef.current[method]()
  }

  const handleProgress = (): void => {
    const sound = soundRef.current
    if (window.isNullish(sound)) return

    const percent = (sound.currentTime / sound.duration) * 100
    setProgress(percent)
  }

  if (window.isNullish(uploadedSound)) return <Typography>-</Typography>

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      my={1}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <Box position="relative" zIndex={1}>
        <IconButton onClick={handleSound}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </IconButton>
      </Box>
      <Progress
        variant={fetching ? 'indeterminate' : 'determinate'}
        size={48}
        value={progress}
      />

      <audio ref={soundRef} src={soundFile} />
    </Box>
  )
}

const Progress = styled(CircularProgress)({
  position: 'absolute',
  top: 0,
})

export default SoundField
