// @ts-nocheck
import React from 'react'
import {
  Box,
  Chip,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import {
  Record,
  useRedirect,
} from 'react-admin'

import {
  API_ENDPOINTS,
} from 'src/api'

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    marginRight: theme.spacing(1),
  },
}))

type TProps = {
  source: string[]
  rolePermissions: Record[] | null
}

const Source = ({ source, rolePermissions }: TProps) => {
  const redirect = useRedirect()
  const classes = useStyles()

  const handleRoleRedirect = (role: Record) => {
    const roleId = role.bearer?.bearerId
    if (!roleId) return

    redirect('edit', `/${API_ENDPOINTS.ROLE}`, roleId)
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start">
      {source.map((el, i) => {
        const role = rolePermissions?.find((role) => role.source === el)

        if (role) {
          return (
            <Chip
              key={i}
              className={classes.chip}
              label={el}
              onClick={() => handleRoleRedirect(role)}
            />
          )
        }

        return <Typography key={i}>{el}</Typography>
      })}
    </Box>
  )
}

export default Source
