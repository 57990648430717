// @ts-nocheck
import React, {
  useState,
  useEffect,
} from 'react'
import {
  Identifier,
  useNotify,
} from 'react-admin'
import styled from 'styled-components'

interface IImageFieldProps {
  value: { id?: string, rawFile?: File } | null
  resource: string
  recordId: Identifier
}

const ImageField = ({ value, resource, recordId }: IImageFieldProps): JSX.Element | null => {
  const notify = useNotify()
  const [image, setImage] = useState<string | null>(null)

  const { id, rawFile } = value ?? {
  }

  useEffect(() => {
    const fetchInitialImage = async (): Promise<void> => {
      const url = `${window._env_.API_URL}/${resource}/${recordId}/image/download`
      const options = {
        headers: {
          authorization: localStorage.getItem('token') ?? '',
        },
        method: 'GET',
      }

      try {
        const imageUrl = await fetch(url, options)
          .then(async response => {
            if (response.ok) return await response.blob()

            throw new Error()
          })
          .then(blob => window.URL.createObjectURL(blob))

        setImage(imageUrl)
      } catch (e) {
        notify('Failed to load image file.', 'error')
      }
    }

    if (window.isNullish(id) && window.isNullish(rawFile)) {
      setImage(null)
      return
    }

    if (!window.isNullish(id)) {
      fetchInitialImage().finally(() => {})
      return
    }

    if (window.isNullish(rawFile)) return

    const blob = new Blob([rawFile], {
      type: rawFile.type,
    })
    const url = window.URL.createObjectURL(blob)
    setImage(url)
  }, [id, rawFile, notify, resource, recordId])

  if (window.isNullish(image)) return null
  return <Image src={image} />
}

const Image = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 400px;
`

export default ImageField
