import {
  makeStyles,
} from '@material-ui/core'
import {
  DateTimePicker,
} from '@material-ui/pickers'
import React from 'react'

const useStyles = makeStyles((theme: any) => ({
  datePicker: theme.inputs.textfield,
}))

const DateTimeInput = ({ property, name, value, onChange, ...props }) => {
  const classes = useStyles()

  return (
    <DateTimePicker
      className={classes.datePicker}
      value={value}
      label={property.displayedName ?? name}
      onChange={(e) => {
        onChange(e.toUTCString())
      }}
      // @ts-expect-error
      views={['year', 'month', 'date', 'hours', 'minutes', 'seconds']}
      format='yyyy/MM/dd hh:mm:ss'
    />
  )
}

export default DateTimeInput
