const EntityPropertiesList = ({ entity, render, childProps }) => {
  const order = entity?.order || []
  const unorderedPropertyKeys = Object.keys(entity.properties).filter(
    (propertyKey) => !order.includes(propertyKey),
  )
  const orderedPropertyKeys = [...order, ...unorderedPropertyKeys]
  const orderedProperties = orderedPropertyKeys.map((propertyKey, index) => {
    const property = entity.properties[propertyKey]
    if (!property) {
      throw new Error(
        `InvalidEntityPropertyKey: Entity doesn't have property key "${propertyKey}", specified in entity.order[${index}]. Possible values are: ${Object.keys(
          entity.properties,
        )}`,
      )
    }
    return [propertyKey, property]
  })

  return orderedProperties
    .filter(([_, property]) => !property.hidden)
    .map(([propertyKey, property], index) =>
      render({
        propertyKey,
        property,
        entity,
        index,
        childProps,
      }),
    )
}

export default EntityPropertiesList
