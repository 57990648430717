const extendGlobalEntity = (entity, viewKey) => {
  const { global, list, create, edit, ...entityWithoutViews } = entity
  const view = entity?.[viewKey]
  const {
    properties = {
    }, ...restViewProperty
  } = view
  const extended = {
    ...entityWithoutViews,
    ...global,
    ...restViewProperty,
    properties: Object.fromEntries(
      Object.entries(global.properties).map(([key, globalProperty]) => [
        key,
        {
          ...globalProperty,
          ...properties[key],
        },
      ]),
    ),
  }

  return extended
}

export default extendGlobalEntity
