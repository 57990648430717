import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import {
  useNotify,
  useEditContext,
  useDataProvider,
  setAutomaticRefresh,
} from 'react-admin';
import { Box } from '@material-ui/core';

import * as editorSelectors from '../../redux/editor/editor.selectors';
import * as editorActions from '../../redux/editor/editor.actions';

import { editor } from './components/Flow';
import Editor from './Editor';
import compareDiagramChangesHelper from './helpers/CompareDiagramChangesHelper';
import mountedEditorHelper from './helpers/MounterEditorHelper';
import CustomLoader from '../screens/Loader/CustomLoader';
// TODO: remove later
import packageJson from '../../../../../package.json';
import useErrorFetch from 'src/hooks/useErrorFetch';

const EditorContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const notify = useNotify();
  const editContext = useEditContext();
  const dataProvider = useDataProvider();
  const fetchError = useErrorFetch();

  const openedBlueprints = useSelector(editorSelectors.openedBlueprints);
  const currentBlueprint = useSelector(editorSelectors.currentBlueprint);
  const fetchedBlueprints = useSelector(editorSelectors.fetchedBlueprints);

  const [, , , urlType, diagramId] = location.pathname.split('/');
  const type = urlType.toLowerCase();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    mountedEditorHelper.loader = setIsLoading;
    dispatch(setAutomaticRefresh(false));

    return () => {
      mountedEditorHelper.exitDiagramEditor();
      if (editor) editor.trigger('destroy');
      dispatch(setAutomaticRefresh(true));
    };
  }, [dispatch]);

  useEffect(() => {
    // writing context loading value into state, because loading should finish after editor is rendered, not just fetched
    if (editContext.loading && !mountedEditorHelper.mounted) setIsLoading(true);
  }, [editContext.loading]);

  useEffect(() => {
    if (!editContext.record) return;

    const handleDrawBlueprint = () => {
      editor && editor.clear();
      const blueprintInStorage = openedBlueprints.find(
        (blueprint) => blueprint.id === diagramId
      );

      if (blueprintInStorage) {
        editor &&
          editor.fromJSON(JSON.parse(JSON.stringify(blueprintInStorage.data)));
        dispatch(editorActions.setCurrentBlueprint(blueprintInStorage.id));

        return;
      }

      const record = editContext.record;

      if (!record) return;

      const blueprintData = {
        id: record?.data?.id
          ? record.data.id
          : `${packageJson.reteApplicationName}@${packageJson.version}`,
        nodes: record.data?.nodes ? record.data.nodes : {},
      };

      const blueprint = {
        ...record,
        data: blueprintData,
        type,
      };

      dispatch(editorActions.addBlueprintToListOfOpened(blueprint));
      dispatch(editorActions.addFetchedBlueprint(blueprint));
    };

    handleDrawBlueprint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editContext.record?.id]);

  const onZoom = (e) => {
    dispatch(editorActions.setEditorZoomValue(e.zoom));
  };

  const getCurrentDiagram = () => {
    if (!currentBlueprint) return null;
    return openedBlueprints.find((bp) => bp.id === diagramId);
  };

  const getFetchedDiagram = () => {
    if (!currentBlueprint) return null;
    return fetchedBlueprints.find((bp) => bp.id === diagramId);
  };

  const handleNotifyEditorError = (err) =>
    notify(err, 'warning', { ClickAwayListenerProps: { mouseEvent: false } });

  const diagram = getCurrentDiagram();
  const fetchedDiagram = getFetchedDiagram();
  compareDiagramChangesHelper.savedDiagram = fetchedDiagram;
  mountedEditorHelper.diagramToRender = diagram;

  return (
    <EditorWrapper boxShadow={2} isLoading={isLoading} borderRadius="inherit">
      {diagram && (
        <Editor
          isLoading={isLoading}
          diagram={diagram}
          type={type}
          onZoom={onZoom}
          handleNotifyEditorError={handleNotifyEditorError}
          dataProvider={{
            apiRequest: dataProvider,
            resource: editContext.resource,
            fetchError,
          }}
        />
      )}

      {isLoading && (
        <LoaderWrapper>
          <CustomLoader
            height="100%"
            loadingPrimary="Loading"
            loadingSecondary="Blueprint is rendering."
          />
        </LoaderWrapper>
      )}
    </EditorWrapper>
  );
};

const EditorWrapper = styled(Box)`
  flex: 1;
  height: calc(100% - 48px); // 46px is the height of blueprint tabs
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const LoaderWrapper = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  z-index: 2;
`;

export default EditorContainer;