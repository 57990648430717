import {
  StyleSheet,
} from '@react-pdf/renderer'

export const colors = {
  bg: 'white',
  documentHeaderBg: '#229C82',
  documentHeader: 'white',
  documentHeaderSecondary: '#444444',
  documentSubHeaderBg: '#2ECFAD',
  documentSubHeader: 'white',
  sectionOddBg: '#FFFFFF',
  sectionOddSecondaryBg: '#E6E6E6',
  sectionEvenBg: '#E6E6E6',
  sectionEvenSecondaryBg: '#F6F6F6',
  bold: '#444444',
  text: '#6E6E6E',
}

export const spacing = (value: number = 1) => value * 8

export const padding = (value: number = 1) => value * spacing(.5)

export const fontSize = {
  pageTitle: 16,
  pageSubtitle: 12,
  text: 10,
}

export const fontFamily = 'Roboto'

export const styles = StyleSheet.create({
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: fontSize.text,
  },
  subtitle: {
    fontSize: fontSize.text,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    color: colors.documentHeaderSecondary,
  },
  text: {
    fontSize: fontSize.text,
    color: colors.text,
  },
  bold: {
    fontSize: fontSize.text,
    color: colors.bold,
    fontWeight: 'bold',
  },
  textSecondary: {
    fontSize: fontSize.text,
    color: colors.text,
    fontWeight: 'light',
  },
})
