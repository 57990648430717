import {
  definitions,
} from '../components/Flow'

export const getPropertiesKeysByType = (entities, entity: string, type: string = '') => {
  let res: any[] = []
  const { properties = [] } = definitions.entities[entity]
  properties.forEach((item) => {
    if ((!type || item.type === type) && !['object', 'array'].includes(item.type)) {
      res.push(item.name)
    }
    if (item.entity) {
      const subkeys = getPropertiesKeysByType(entities, item.entity, type)
      res = res.concat(subkeys.map((subkey) => item.name + '.' + subkey))
    }
  })
  return res
}

export const isObjectType = (type) => !['boolean', 'action', 'string', 'number', 'array'].includes(type)
