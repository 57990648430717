import React from 'react'
import {
  Text
} from '@react-pdf/renderer'

import NoContent from '../NoContent'
import * as theme from '../theme'
import { TSectionContent } from '../Sections'

const Description: TSectionContent = ({ alarm }) => {
  if (!alarm.description)
    return <NoContent />

  return (
    <Text style={theme.styles.text}>
      {alarm.description}
    </Text>
  )
}

export default Description
