import React from 'react'
import {
  makeStyles,
} from '@material-ui/core/styles'
import {
  Title,
} from 'react-admin'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}))

export default function DashBoard () {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Title title="DASHBOARD" />
      <iframe title="kibana" src={window._env_.DASHBOARD_URL} height="100%" width="100%"></iframe>
    </div>
  )
}
