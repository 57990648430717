import {
  IAgent,
} from '@obvious.tech/constellation'

import {
  TEntityOverride,
} from 'src/types'

const override: TEntityOverride < IAgent > = {
  title: ({
    lastname,
    firstname,
  }) => {
    if (typeof lastname === 'string' && lastname.length > 0) {
      return `${firstname} ${lastname}`
    }
    return firstname
  },
  global: {
    properties: {
      passportId: {
        displayedName: 'Passport',
        referenceTo: 'authentication/api/Passport',
        referencedField: 'username',
      },
      cameraIds: {
        displayedName: 'Cameras',
        referenceTo: 'videomanager/api/Camera',
        referencedField: 'name',
      },
      missionId: {
        displayedName: 'Mission',
        referenceTo: 'missions/api/Mission',
        readOnly: false,
      },
      markerId: {
        referenceTo: 'geolocation/api/Marker',
        referencedField: 'id',
      },
      isConnected: {
        type: 'boolean',
        displayedName: 'Is connected ?',
        readOnly: false,
      },
      location: {
        displayedName: 'Location',
      },
      photoId: {
        displayedName: 'Photo',
        referenceTo: 'resources/api/Image',
        referencedField: 'name',
      },
      markerImageId: {
        displayedName: 'Marker icon / Avatar',
        referenceTo: 'resources/api/Image',
        referencedField: 'name',
      },
      groups: {
        type: 'groups',
      },
      birthDate: {
        type: 'birth-date',
      },
    },
  },
  create: {
    properties: {
      isConnected: {
        hidden: true,
      },
    },
  },
  edit: {
    properties: {
      isConnected: {
        readOnly: false,
      },
    },
  },
  list: {
    properties: {
      markerId: {
        hidden: true,
      },
      location: {
        hidden: true,
      },
      cameraIds: {
        hidden: true,
      },
      missionId: {
        hidden: true,
      },
      photoId: {
        hidden: true,
      },
      markerImageId: {
        hidden: true,
      },
    },
  },
}

export default override
