import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import React from 'react'
import {
  createHashHistory,
} from 'history'
import {
  Provider,
} from 'react-redux'

import {
  UserMenuProvider,
} from 'src/components/UserMenu'

import authProvider from './providers/authProvider'
import createAdminStore from './redux/createAdminStore'
import AdminPanel from './adminPanel'
import dataProvider from './providers/addUploadFeature'
import i18nProvider from './i18n'

const history = createHashHistory()

export const store = createAdminStore({
  authProvider,
  dataProvider,
  i18nProvider,
  history,
})

export default () => {
  return (
    <Provider store={store}>
      <UserMenuProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AdminPanel
            history={history}
            authProvider={authProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
          />
        </MuiPickersUtilsProvider>
      </UserMenuProvider>
    </Provider>
  )
}
