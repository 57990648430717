import {
  useDataProvider,
  useNotify,
} from 'react-admin'
import {
  useState,
} from 'react'

const useDeleteGroup = (): [typeof call, typeof state] => {
  const dataProvider = useDataProvider()
  const [loading, setLoading] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)

  const notify = useNotify()

  const deleteGroup = (id: string) => {
    const previousData = {
      id,
    }

    return dataProvider.delete('authentication/api/Group', {
      id,
      previousData,
    })
  }

  const call = async (groupName: string) => {
    try {
      setLoading(true)
      setLoaded(false)
      await deleteGroup(groupName)
    } catch (e) {
      const error = 'Group deletion failed.'
      console.error(error)
      notify(error, 'error')
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }

  const state = {
    loading,
    loaded,
  }
  return [call, state]
}

export default useDeleteGroup
