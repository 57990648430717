import React from 'react'
import {
  Table as MUTable,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  TableProps,
  TableRowProps,
} from '@material-ui/core'

type TColumnType = {
  label: string
  key: string
  render?: (data: any) => React.ReactNode
}

type TRowData = {
  [key: string]: string | React.ReactNode
}

type TTableProps = TableProps & TableRowProps & {
  columns: TColumnType[]
  rowData: TRowData[]
  checkbox?: boolean
}

const Table = ({ columns, classes, rowData, checkbox, ...tableProps }: TTableProps) => {
  return (
    <MUTable {...tableProps}>
      <TableHead>
        <TableRow>
          {checkbox && (
            <TableCell padding="checkbox">
              <Checkbox color="primary" />
            </TableCell>
          )}

          {columns.map(({ label }, i) => (
            <TableCell key={i} align="left" padding="default">
              {label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rowData.map((data, i) => (
          <TableRow hover={tableProps.hover ?? true} role="checkbox" tabIndex={-1} key={i}>
            {checkbox && (
              <TableCell padding="checkbox">
                <Checkbox color="primary" />
              </TableCell>
            )}

            {Object.entries(data).map(([key, value], i) => {
              const customRender = columns.find((column) => column.key === key)?.render

              return (customRender != null)
                ? (
                <TableCell key={i}>{customRender(data)}</TableCell>
                  )
                : (
                <TableCell key={i}>{value}</TableCell>
                  )
            })}
          </TableRow>
        ))}
      </TableBody>
    </MUTable>
  )
}

export default Table
