import React, {
  useMemo,
  useCallback,
} from 'react'
import {
  Button,
  makeStyles,
  useTheme,
  Box,
} from '@material-ui/core'
import {
  useNotify,
} from 'react-admin'
import {
  TablePaginationActionsProps,
} from '@material-ui/core/TablePagination/TablePaginationActions'
import {
  useTranslation,
} from 'react-i18next'

import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'

const useStyles = makeStyles(theme => ({
  actions: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(3),
  },
  hellip: {
    padding: theme.spacing(1),
  },
}))

interface IPaginationActionsProps extends TablePaginationActionsProps {
  count: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void
  page: number
  rowsPerPage: number
  color: 'primary' | 'secondary'
  size: 'small' | 'medium' | 'large'
}

type TChangePageEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>

const DISPLAYED_PAGES_NUMBER = 4

const PaginationActions = ({
  page,
  rowsPerPage,
  count,
  onPageChange,
  color = 'primary',
  size = 'small',
}: IPaginationActionsProps): JSX.Element => {
  const classes = useStyles()
  const notify = useNotify()
  const { t } = useTranslation()
  const theme = useTheme()

  const totalPages = useMemo(() => Math.ceil(count / rowsPerPage), [count, rowsPerPage])

  const range = useCallback((from: number, to: number, step = 1): number[] => {
    return Array(to - from + 1)
      .fill(step)
      .map((_, i) => from + i * step)
  }, [])

  const getPages = useCallback((): Array<number | '.'> => {
    const nbPages = totalPages
    const currentPage = page + 1
    if (isNaN(page) || nbPages === 1) {
      return []
    }

    const hasLeftSpill = currentPage > DISPLAYED_PAGES_NUMBER
    const hasRightSpill = currentPage <= nbPages - DISPLAYED_PAGES_NUMBER

    // handle 1 ... 4 [5] 6 ... 10 case
    if (hasLeftSpill && hasRightSpill) {
      return [1, '.', currentPage - 1, currentPage, currentPage + 1, '.', nbPages]
    }

    // handle 1 ... [6] 7 8 9 10 case
    if (hasLeftSpill) {
      const pages = range(currentPage, nbPages)
      return [1, '.', currentPage - 1, ...pages]
    }

    // handle 1 2 3 4 [5] ... 10 case
    if (hasRightSpill) {
      const pages = range(1, currentPage)
      return [...pages, currentPage + 1, '.', nbPages]
    }

    return range(1, nbPages)
  }, [page, range, totalPages])

  const prevPage = useCallback(
    (event: TChangePageEvent): void => {
      if (page === 0) {
        notify(t('navigation.page_out_from_begin'), 'warning')
        return
      }

      onPageChange(event, page - 1)
    },
    [notify, t, onPageChange, page],
  )

  const nextPage = useCallback(
    (event: TChangePageEvent): void => {
      if (page > totalPages - 1) {
        notify(t('navigation.page_out_from_end'), 'warning')
        return
      }

      onPageChange(event, page + 1)
    },
    [page, notify, t, onPageChange, totalPages],
  )

  const gotoPage = useCallback(
    (event: TChangePageEvent): void => {
      const page = parseInt(event.currentTarget.dataset.page ?? '1', 10)
      if (page < 0 || page > totalPages - 1) {
        notify(
          t('navigation.page_out_of_boundaries', {
            page: page + 1,
          }),
          'warning',
        )
        return
      }

      onPageChange(event, page)
    },
    [totalPages, notify, t, onPageChange],
  )

  if (totalPages === 1) {
    return <Box className={classes.actions} />
  }

  return (
    <Box className={classes.actions}>
      {page > 0 && (
        <Button color={color} size={size} onClick={prevPage}>
          {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          {t('navigation.prev')}
        </Button>
      )}
      {getPages().map((pageNum, index) =>
        pageNum === '.'
          ? (
          <span key={`hyphen_${index}`} className={classes.hellip}>
            &hellip;
          </span>
            )
          : (
          <Button
            size={size}
            color={pageNum === page + 1 ? 'default' : color}
            key={pageNum}
            data-page={pageNum - 1}
            onClick={gotoPage}
          >
            {pageNum}
          </Button>
            ),
      )}
      {page !== totalPages - 1 && (
        <Button color={color} size={size} onClick={nextPage}>
          {t('navigation.next')}
          {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
        </Button>
      )}
    </Box>
  )
}

export default React.memo(PaginationActions)
