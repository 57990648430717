import {
  StyleSheet,
} from '@react-pdf/renderer'
import {
  Style,
} from '@react-pdf/types'

import * as theme from '../theme'

const styles = StyleSheet.create({
  table: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
  },
  cell: {
    padding: theme.padding(1),
    flex: 1,
  },
  headerCell: theme.styles.bold,
  contentCell: theme.styles.textSecondary,
})

export const getRowStyles = ({ rowIndex }): Style[] => {
  return [{
    ...(
      rowIndex % 2 === 0
      ? { backgroundColor: '#F6F6F6' }
      : {}
    )
  }]
}

export default styles