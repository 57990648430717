import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormState } from 'react-final-form';
import {
  SaveButton,
  useNotify,
  useRefresh,
  Toolbar,
  DeleteButton,
} from 'react-admin';
import {
  updateBlueprintInOpenedList,
  removeOpenedBlueprint,
} from '../../../../redux/editor/editor.actions';

import { makeStyles } from '@material-ui/core';
import {
  PreSaveModal,
  UsePreSaveValidation,
} from '../../../../adminPanel/Flow/PreSaveValidation';
import { editor } from '../../../../adminPanel/Flow/components/Flow';
import Spacer from 'src/components/Spacer';

const styles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
  },
  saveButton: {
    '& .MuiButton-label': {},
  },
}));

const EditDiagramToolbar = (props) => {
  const { resource, invalid } = props;
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const formState = useFormState();
  const { ...diagram } = formState.values;
  const [microservice, , entity] = resource.split('/');

  const [errors, setErrors, isEditorValid] = UsePreSaveValidation();

  const mapDiagramEvents = (diagram) =>
    Object.keys(diagram?.nodes || {})
      .filter(
        (node) =>
          diagram.nodes[node].name.indexOf('- On ') !== -1 ||
          diagram.nodes[node].name === 'Schedule with interval'
      )
      .map((node) => diagram.nodes[node].name);

  const updateDiagram = async (diagram, microservice, entity) => {
    const url = `${window._env_.API_URL}/${microservice}/api/${entity}/${diagram.id}`;
    const method = 'PUT';
    const editorDiagramView = editor.toJSON();
    const diagramEvents = mapDiagramEvents(editorDiagramView);
    const body = JSON.stringify({
      ...diagram,
      data: editorDiagramView,
      events: diagramEvents,
    });

    const options = {
      headers: new Headers({
        accept: 'application/json',
        'content-type': 'application/json-patch+json',
        authorization: localStorage.getItem('token'),
      }),
      body,
    };

    return fetch(url, { ...options, method }).then(async (res) => {
      if (res.ok) return res.json();
      const error = await res.json();
      let errorMessage = error.errorMessage;

      try {
        const invalidDataMessages = Object.values(
          JSON.parse(JSON.parse(error.errorMessage))
        )
          .filter((errorArr) => errorArr?.length)
          .map((err) => err[0]);

        errorMessage = invalidDataMessages?.length
          ? invalidDataMessages[0]
          : errorMessage;
      } catch {}

      notify(errorMessage, 'error', {
        smart_count: 1,
      });
    });
  };

  const handleSubmit = async () => {
    const updatedDiagram = await updateDiagram(diagram, microservice, entity);
    if (!updatedDiagram) return;

    await dispatch(updateBlueprintInOpenedList(updatedDiagram));
    refresh();
    notify(`Diagram ${updatedDiagram.name} updated.`, 'success', {
      smart_count: 1,
    });
  };

  const handleDeleteBlueprint = () => {
    dispatch(removeOpenedBlueprint(diagram.id));
  };

  const handlePreSubmitValidation = async () => {
    if (!isEditorValid()) return;

    await handleSubmit();
  };
  return (
    <Toolbar className={classes.toolbar} {...props} pristine={false}>
      <SaveButton
        label="Save Diagram"
        disabled={invalid}
        className={classes.saveButton}
        redirect={false}
        handleSubmitWithRedirect={handlePreSubmitValidation}
      />
      <Spacer />
      <DeleteButton onClick={handleDeleteBlueprint} />
      <PreSaveModal
        onSave={handleSubmit}
        open={Boolean(errors)}
        errors={errors}
        fixActionCallback={() =>
          // editor doesn't know that form accordion being opened
          window.scrollTo({
            left: 0,
            top: document.body.scrollHeight,
            behavior: 'smooth',
          })
        }
        onClose={() => setErrors(null)}
      />
    </Toolbar>
  );
};

export default EditDiagramToolbar;