import {
  ActionCreator,
} from 'redux'

import {
  actionTypes,
  TSetMicroservicesAction,
} from './types'

export const setMicroservices: ActionCreator<TSetMicroservicesAction> =
    (microservices) => ({
      type: actionTypes.SET_MICROSERVICES,
      payload: microservices,
    })
