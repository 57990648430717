// @ts-nocheck
import React from 'react'
import {
  CircularProgress,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Identifier,
  Record,
  useGetList,
} from 'react-admin'

import {
  IInputProps,
} from 'src/types'

interface IProperty {
  referenceTo: string
  displayedName?: string
  readOnly: boolean
  referencedField?: string
}

interface ISelectInputProps {
  [key: string]: any
  loading: boolean
  property: IProperty
  choices: Record[]
  optionText: string
  onChange: (newValue: Identifier[]) => void
  value: Array<Record | Identifier> | null
  name: string
}

const SelectInput = ({
  choices,
  optionText,
  loading,
  onChange,
  value,
  property,
  name,
  ...props
}: ISelectInputProps): JSX.Element => {
  const choice =
    choices?.filter((choice) =>
      window.isNullish(value) ? false : value.includes(choice.id),
    ) ?? null

  return (
    <Autocomplete
      {...props}
      disabled={property.readOnly || loading}
      options={choices}
      multiple
      onChange={(e, newValues) => {
        onChange(
          !window.isNullish(newValues)
            ? newValues.map((newValue) => newValue.id)
            : [],
        )
      }}
      loading={loading}
      fullWidth
      ChipProps={property.readOnly
        ? {
            onDelete: undefined,
          }
        : undefined}
      loadingText="Loading..."
      getOptionLabel={(option) => {
        return option[optionText] ?? option.id
      }}
      getOptionSelected={(option, value) => {
        if (!window.isNullish(value) && typeof value === 'object') {
          if (window.isNullish(option.id)) return false

          return !window.isNullish(value.id) && option.id === value.id
        }
        return option.id === value
      }}
      defaultValue={[]}
      value={choice}
      id="disable-close-on-select"
      renderInput={(params) => (
        <TextField
          {...params}
          label={property.displayedName ?? name}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading
                  ? (
                  <CircularProgress color="inherit" size={20} />
                    )
                  : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

interface IReferencedArrayInput extends IInputProps<Identifier[]> {
  property: IProperty
}

const ReferencedArrayInput = ({
  property,
  name,
  record,
  onChange,
  value,
}: IReferencedArrayInput): JSX.Element => {
  const { data, loading } = useGetList(
    property.referenceTo,
    {
    },
    {
      field: property.referencedField ?? 'id',
      order: 'ASC',
    },
    {
      [property.referencedField ?? 'id']: '',
    },
  )

  return (
    <SelectInput
      choices={window.isNullish(data) ? [] : Object.values(data)}
      property={property}
      optionText={property.referencedField ?? 'id'}
      record={record}
      loading={loading}
      onChange={onChange}
      value={value}
      name={name}
    />
  )
}

export default ReferencedArrayInput
