// @ts-nocheck
import React from 'react'
import { useTranslation } from 'react-i18next'

import NoContent from '../NoContent'
import Table from '../Table'
import { TSectionContent } from '../Sections'

const UserTasks: TSectionContent = ({ tasks }) => {
  const { t } = useTranslation()

  if (!tasks.length) return <NoContent />

  return (
    <Table
      columns={{
        date: {
          title: t('alarm_report.user_tasks.date'),
          render: createdAt => {
            return t(
              'alarm_report.user_tasks.date_format',
              { date: new Date(createdAt) },
            )
          },
          style: {
            flex: 0.2,
          },
        },
        description: {
          title: t('alarm_report.user_tasks.description'),
          render: v => v,
          style: {
            flex: 0.325,
          },
        },
        answers: {
          title: t('alarm_report.user_tasks.selected_answers'),
          render: answers => answers?.join(','),
          style: {
            flex: 0.15,
          },
        },
        notes: {
          title: t('alarm_report.user_tasks.notes'),
          render: v => v,
          style: {
            flex: 0.325,
          },
        },
      }}
      rows={tasks.map(task => ({
        date: task.createdAt,
        description: task.description,
        answers: task.selectedAnswers,
        notes: task.notes,
      }))}
      getRowKey={task => task.date.toString()}
    />
  )
}

export default UserTasks