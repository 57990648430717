import Rete from '@obvious.tech/rete'
import {
    sockets,
} from '../../..'
import {
    getSocketFromType,
} from '../../../Sockets/utils'
import GenericDecomposeControl from './GenericDecomposeController'

class GenericDecomposeComponent extends Rete.Component {
    constructor() {
        super('Generic Decompose')
        this.contextMenuPath = ['Objects']
        this.task = {
            outputs: {
                0: 'output',
                1: 'output',
                2: 'output',
                3: 'output',
                4: 'output',
                5: 'output',
                6: 'output',
                7: 'output',
                8: 'output',
                9: 'output',
                10: 'output',
            },
        }
    }

    updateNode = (node) => {
        node.update()
        setTimeout(() => {
            this.editor.view.updateConnections({
                node,
            })
        }, 1)
    }

    builder(node) {
        const inp = new Rete.Input('data', 'Any Obj', sockets.objectSocket)

        node.data.keysList &&
            node.data.keysList.forEach((objectAttribute) => {
                const output = new Rete.Output(
                    objectAttribute.id.toString(),
                    objectAttribute.key,
                    getSocketFromType(objectAttribute.type),
                )
                node.addOutput(output)
            })
        const handleRemoveKey = (id) => {
            const output = node.outputs.get(id.toString())
            if (output) {
                output.connections.map((connection) =>
                    this.editor.removeConnection(connection),
                )
                node.removeOutput(output)
                node.update()
                setTimeout(() => {
                    this.editor.view.updateConnections({
                        node,
                    })
                }, 1)
            }
        }
        const handleUpdateKey = (oldKey, key, id) => {
            if (node.outputs.has(id.toString())) {
                node.outputs.get(id.toString()).name = key
            }
            this.updateNode(node)
        }

        const handleUpdateType = (id, type) => {
            if (node.outputs.has(id.toString())) {
                const output = node.outputs.get(id.toString())
                output.socket = getSocketFromType(type)
            } else {
                const output = node.data.keysList.find((item) => item.id === id)
                node.addOutput(
                    new Rete.Output(id, output.key, getSocketFromType(output.type)),
                )
            }
            this.updateNode(node)
        }

        const handleAddKey = () => {}
        const controller = new GenericDecomposeControl(
            this.editor,
            node,
            'Object definition',
            'Edit object',
            handleRemoveKey,
            handleUpdateKey,
            handleUpdateType,
            handleAddKey,
            'object',
        )
        node.addInput(inp).addControl(controller)
        return node
    }

    worker(node, inputs) {
        if (inputs && inputs.data) {
            return {
                ...inputs.data[0],
            }
        }
    }
}

export default GenericDecomposeComponent