import React from 'react'

import ConnectedApp from 'src/ConnectedApp'
import useGlobalStyles from 'src/theme/useGlobalStyles'

const App = () => {
  useGlobalStyles()

  return (
    <div className="flow-cuztomize">
      <ConnectedApp />
    </div>
  )
}

export default App
