import React from 'react';
import { View, StyleSheet, Text,  } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next'

import * as theme from './theme'
import { TAlarmReportProps } from './Provider'

const styles = StyleSheet.create({
  root: {
    backgroundColor: theme.colors.documentSubHeaderBg,
    color: theme.colors.documentSubHeader,
    padding: theme.padding(2),
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
    flexDirection: 'column',
  },
  fieldTitle: {
    ...theme.styles.subtitle,
    color: 'white',
  },
  fieldContent: {
    ...theme.styles.text,
    color: 'white',
  },
});

const Field = ({ title, children }) => {
  return (
    <Text>
      <Text style={styles.fieldTitle}>
        {`${title}: `}
      </Text>
      <Text style={styles.fieldContent}>
        {children}
      </Text>
    </Text>
  )
}

export type TProps = TAlarmReportProps

export default ({ alarm, assignedPassport }: TProps) => {
  const { t } = useTranslation()

  return (
    <View style={styles.root}>
      <View style={styles.column}>
        <Field title={t('alarm_report.header.id')}>
          {alarm.id}
        </Field>
        <Field title={t('alarm_report.header.creation_date')}>
          {t(
            'alarm_report.header.creation_date_format',
            { date: new Date(alarm.creationDate) }
          )}
        </Field>
        <Field title={t('alarm_report.header.originator_system')}>
          {alarm.originatorSystem}
        </Field>
        <Field title={t('alarm_report.header.assigned_user')}>
          {assignedPassport?.username}
        </Field>
      </View>
      <View style={styles.column}>
        <Field title={t('alarm_report.header.status')}>
          {alarm.status}
        </Field>
        <Field title={t('alarm_report.header.category')}>
          {alarm.category}
        </Field>
        <Field title={t('alarm_report.header.severity')}>
          {alarm.severity}
        </Field>
        <Field title={t('alarm_report.header.certainty')}>
          {alarm.certainty}
        </Field>
      </View>
    </View>
  );
};
