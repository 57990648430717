// @ts-nocheck
import {
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux'
import {
  routerMiddleware,
  connectRouter,
} from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import {
  all,
  fork,
} from 'redux-saga/effects'
import {
  adminReducer,
  adminSaga,
  USER_LOGOUT,
} from 'react-admin'
import {
  composeWithDevTools,
} from 'redux-devtools-extension/developmentOnly'

import microservicesReducer from './microservices/reducer'
import definitionsReducer from './definitions/definitions.reducer'
import apiReducer from './api/api.reducer'
import editorReducer from './editor/editor.reducer'

const createRootReducer = (history: any) => {
  return combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    microservices: microservicesReducer,
    definitions: definitionsReducer,
    api: apiReducer,
    editor: editorReducer,
  })
}

export type TRootState = ReturnType<ReturnType<typeof createRootReducer>>

export default ({ authProvider, i18nProvider, dataProvider, history }) => {
  const reducer = createRootReducer(history)
  const resettableAppReducer = (state: any, action: any) =>
    reducer(action.type !== USER_LOGOUT ? state : undefined, action)

  const saga = function * rootSaga () {
    yield all([adminSaga(dataProvider, authProvider)].map(fork))
  }
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    resettableAppReducer,
    {
      /* set your initial state here */
    },
    composeWithDevTools(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        // add your own middlewares here
      ),
      // add your own enhancers here
    ),
  )
  sagaMiddleware.run(saga)
  return store
}
