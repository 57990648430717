import flowStyles from './styles'

export default {
  popupTitleContainer: {
    color: flowStyles.palette.primary.contrastText,
    backgroundColor: flowStyles.palette.primary.main,
  },
  popupTitle: {
    fontSize: '15pt',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  iconInHeaders: {
    color: flowStyles.palette.primary.contrastText,
  },
  flowInputText: {
    color: 'white',
    fontSize: '9pt',
    textAlign: 'center',
    borderBottom: '1px solid #000',
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  flowInputNumber: {
    padding: 0,
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  inputUnderline: {
    '&::after': {
      borderBottom: '1px solid #000',
    },
    '&::before': {
      borderBottom: '1px solid #000',
    },
  },
  verticalAlign: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '0px',
  },
  flowNodeTitle: {
    textTransform: 'uppercase',
    lineHeight: 2.66,
    fontSize: '10pt',
  },
  flowMenuItem: {
    '&:hover': {
      backgroundColor: 'rgb(0, 0, 0, 0.23)',
      color: 'white',
    },
  },
  thumbVertical: {
    backgroundColor: 'white',
    borderRadius: '4px',
    width: '60%',
    margin: '0 auto',
  },
  trackVertical: {
    position: 'absolute',
    width: '10px',
    right: '0px',
    bottom: '0px',
    padding: '2px 0',
    top: '0px',
    borderRadius: '5px',
    backgroundColor: 'black',
    textAlign: 'center',
    zIndex: 2,
  },
  dialogActionsContainer: {
    padding: '16px',
  },
}
