const API_ENDPOINTS = {
  GROUP: 'authentication/api/Group',
  AUTHORIZATION: 'authentication/api/Authorization',
  ROLE: 'authentication/api/Role',
  ROLE_TEMPLATE: 'authentication/api/RoleTemplate',
  AGENT: 'units/api/Agent',
  PASSPORT: 'authentication/api/Passport',
}

export default API_ENDPOINTS
