import React from 'react'
import {
  useDispatch,
} from 'react-redux'
import {
  GlobalHotKeys,
} from 'react-hotkeys'

import {
  removeConnections,
  copyPasteSelectedNodes,
  deleteSelectedNodes,
} from '../helpers/ReteHelpers'
import {
  editor,
} from '../components/Flow'
import {
  showNewBlueprintDialog,
} from '../../../redux/editor/editor.actions'
import {
  flowShortcuts,
} from 'src/shortcuts'

export default function EditorHotKeys ({
  showDialogForSaveAs,
  showDialogForChangeTitle,
  toggleMinimap,
  showSimulationDialog,
  zoom,
  centerBlueprint,
  openFileChooser,
  downloadObject,
  saveBlueprint,
}) {
  const dispatch = useDispatch()

  const handlers = {
    SHOW_NEW_BLUEPRINT_DIALOG: () => {
      dispatch(showNewBlueprintDialog())
    },
    SAVE_AS: showDialogForSaveAs,
    SAVE: saveBlueprint,
    DOWNLOAD: () => {
      downloadObject(editor.toJSON())
    },
    UPLOAD: openFileChooser,
    RENAME: showDialogForChangeTitle,
    CLEAN_BOARD: () => {
      editor.clear()
    },
    SELECT_ALL_NODES: () => {
      editor.trigger('selectAllNodes')
    },
    DELETE_ALL_LINKS: () => {
      removeConnections(editor)
    },
    DELETE_LINKS: () => {
      removeConnections(editor, true)
    },
    DUPLICATE_NODES: () => {
      copyPasteSelectedNodes(editor)
    },
    ZOOM_IN: () => zoom.zoomIn(1),
    ZOOM_OUT: () => zoom.zoomOut(-1),
    CENTER: centerBlueprint,
    DELETE_NODES: () => deleteSelectedNodes(editor),
    SIMULATE: showSimulationDialog,
    TOGGLE_MAP: toggleMinimap,
    UNDO: () => editor.trigger('undo'),
    REDO: () => editor.trigger('redo'),
  }

  return (
    <GlobalHotKeys
      key="GlobalHotKeys"
      keyMap={flowShortcuts}
      handlers={handlers}
      allowChanges
    />
  )
}
