import Rete from '@obvious.tech/rete'
import {
    sockets,
} from '../..'
import {
    getControl,
} from '../../../../helpers/ControlHelper'
import PlusIconControl from '../../Controls/PlusIconControl'
import DefaultNode from '../../Components/CustomNode'
import NodeTypizer from '../../Components/NodeTypizer'
import CustomObjectInputComponent from '../../Components/CustomObjectInputComponent'

export default class ObjectComponent extends Rete.Component {
    constructor() {
        super('Object')
        this.data.component = NodeTypizer(DefaultNode, {
            InputBlockComponent: CustomObjectInputComponent,
        })

        this.task = {
            outputs: {
                object: 'output',
            },
        }
        this.contextMenuPath = ['Objects']
    }

    builder(node) {
        const initialObjectInput = new Rete.Input(
            'initialObject',
            'Initial object',
            sockets.objectSocket,
        )
        node.addInput(initialObjectInput)
        const outputObject = new Rete.Output(
            'object',
            'Object',
            sockets.objectSocket,
        )
        if (node.data.inputs) {
            node.data.inputs.forEach((inputKey) => {
                const keyInput = new Rete.Input(
                    `key${inputKey}`,
                    'Key',
                    sockets.stringSocket,
                )
                const control = getControl(
                    this.editor,
                    node,
                    'string',
                    `key${inputKey}`,
                    'Key',
                )
                control && keyInput.addControl(control)
                const valueInput = new Rete.Input(
                    `value${inputKey}`,
                    'Value',
                    sockets.anyTypeSocket,
                )

                node.addInput(keyInput)
                node.addInput(valueInput)
            })
        }
        const handleClick = (isAdding) => {
            if (isAdding) {
                let newKey
                if (node.data.inputs && node.data.inputs.length) {
                    newKey = node.data.inputs[node.data.inputs.length - 1] + 1
                    node.data.inputs = [...node.data.inputs, newKey]
                } else {
                    newKey = 0
                    node.data.inputs = [0]
                }
                const keyInput = new Rete.Input(
                    `key${newKey}`,
                    'Key',
                    sockets.stringSocket,
                )
                const control = getControl(
                    this.editor,
                    node,
                    'string',
                    `key${newKey}`,
                    'Key',
                )
                control && keyInput.addControl(control)
                const valueInput = new Rete.Input(
                    `value${newKey}`,
                    'Value',
                    sockets.anyTypeSocket,
                )
                node.addInput(keyInput).addInput(valueInput)
            } else {
                if (node.data.inputs && node.data.inputs.length) {
                    const keyToRemove = node.data.inputs[node.data.inputs.length - 1]
                    const keyInputToRemove = node.inputs.get(`key${keyToRemove}`)
                    keyInputToRemove.connections.forEach((connection) =>
                        this.editor.removeConnection(connection),
                    )
                    node.removeInput(keyInputToRemove)
                    const valueInputToRemove = node.inputs.get(`value${keyToRemove}`)
                    valueInputToRemove.connections.forEach((connection) =>
                        this.editor.removeConnection(connection),
                    )
                    node.removeInput(valueInputToRemove)
                    node.data.inputs.pop()
                }
            }

            setTimeout(() => {
                node.update()
                this.editor.view.updateConnections({
                    node,
                })
            }, 1)
        }
        const plusIcon = new PlusIconControl(
            this.editor,
            node,
            'plusIcon',
            handleClick,
        )
        return node.addControl(plusIcon).addOutput(outputObject)
    }

    connected = (connection) => {
        if (connection && connection.input.key) {
            setTimeout(() => {
                connection.input.node.update()
                setTimeout(() => {
                    this.editor.view.updateConnections({
                        node: connection.input.node,
                    })
                }, 1)
            })
        }
    }

    disconnected = (connection) => {
        if (connection.input.key) {
            setTimeout(() => {
                if (connection.input.node) {
                    connection.input.node.data[connection.input.key] = null
                    connection.input.node.update()
                    setTimeout(() => {
                        this.editor.view.updateConnections({
                            node: connection.input.node,
                        })
                    }, 1)
                }
            })
        }
    }

    worker(node, inputs, outputs) {}
}