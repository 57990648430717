import {
  compare,
} from 'src/utils/str'

interface IOption {
  label: string
}

const compareNodeOptions = (a: IOption, b: IOption): number =>
  compare(a.label, b.label)

export default compareNodeOptions
