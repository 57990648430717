export default function filterArraysSockets (s) {
  if (s.includes('strOrArray')) {
    return false
  }
  if (s.includes('Array')) {
    return true
  }
  if (s.includes('array')) {
    return true
  }

  return false
}
