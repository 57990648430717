const getEntityNameFromResource = (resource: string): string | undefined => {
  const regex = /^\w+\/api\/(?<entity>\w+)$/
  const result = resource.match(regex)

  if (window.isNullish(result?.groups?.entity)) {
    console.error('Failed to extract entity from the resource: ', resource)
    return
  }

  return result?.groups?.entity
}

export default getEntityNameFromResource
