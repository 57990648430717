// @ts-nocheck
import { useState, useEffect } from 'react';
import { useDataProvider, Record } from 'react-admin';

import useErrorFetch from 'src/hooks/useErrorFetch';
import { API_ENDPOINTS, AUTHORIZATION_TYPES } from 'src/api';

type TPermission = {
    id: string;
    data: {
        Entity: string;
        level: number;
    };
    group: string;
    groups: string[];
    type: typeof AUTHORIZATION_TYPES.GROUP;
    bearer: {
        bearerId: string;
        bearerType: 'Role';
    };
};

const useFetchRolePermissions = (editContext): typeof state => {
    const dataProvider = useDataProvider();
    const fetchError = useErrorFetch();

    const [loading, setLoading] = useState < boolean > (false);
    const [loaded, setLoaded] = useState < boolean > (false);
    const [data, setData] = useState < Record[] > ([]);
    const [error, setError] = useState < typeof Error | null > (null);

    useEffect(() => {
        if (!editContext?.record) return;

        const fetchAuthorizations = async() => {
            try {
                const { record } = editContext;

                if (!record?.roleIds?.length) return;
                setLoading(true);

                const rolesIdsPromises = record.roleIds.map((roleId: string) =>
                    dataProvider.getList(
                        `${API_ENDPOINTS.ROLE}/${roleId}/Authorization`,
                        // @ts-ignore
                        {}
                    )
                );
                const rolesPromises = record.roleIds.map((roleId: string) =>
                    dataProvider.getOne(API_ENDPOINTS.ROLE, { id: roleId })
                );

                const roles: Record[] = await Promise.all(rolesPromises);
                const rolesPermissions: Record[] = await Promise.all(rolesIdsPromises);

                const mappedPermissions = rolesPermissions
                    // @ts-ignore
                    .reduce((acc, { data }) => [...acc, ...data], [])
                    .filter((permission: TPermission) => permission.type === AUTHORIZATION_TYPES.GROUP)
                    .map((permission: TPermission) => ({
                        ...permission,
                        source: roles.find(({ data }) => data.id === permission.bearer.bearerId)?.data?.name,
                    }));

                setData(mappedPermissions);
            } catch (e) {
                fetchError(e);
                setError(e);
            } finally {
                setLoaded(true);
                setLoading(false);
            }
        };

        fetchAuthorizations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editContext?.loaded]);

    const state = { loading, loaded, data, error };

    return state;
};

export default useFetchRolePermissions;