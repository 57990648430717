// @ts-nocheck
import React, {
  useRef,
  useEffect,
} from 'react'
import {
  Record,
} from 'react-admin'
import {
  useSelector,
} from 'react-redux'
import {
  IPassport,
} from '@obvious.tech/constellation'

import {
  TRootState,
} from 'src/redux'
import {
  TEntityOverrideView,
} from 'src/types'
import EditAuthorizationForm from 'src/components/Authorizations/EditAuthorizationForm'
interface IUserProps {
  resource: string
  entity: TEntityOverrideView<'edit', IPassport>
  id: string
  basePath: string
}

const User = ({ resource, entity, id, basePath }: IUserProps): JSX.Element => {
  const cachedPassport = useSelector(
    (state: TRootState) => state.admin.resources[resource]?.data?.[id],
  )
  const prevPassword = useRef<string>()

  useEffect(() => {
    if (window.isNullish(cachedPassport)) return

    prevPassword.current = cachedPassport.password
  }, [cachedPassport])

  const transform = (record: Record): Record => {
    const { password: newPassword, ...data } = record

    if (window.isNullish(prevPassword.current)) return data
    if (!window.isNullish(newPassword) && newPassword !== prevPassword.current) { return record }

    return data
  }

  return (
    <EditAuthorizationForm
      title="User"
      recordNameField="username"
      transform={transform}
      resource={resource}
      entity={entity}
      id={id}
      basePath={basePath}
    />
  )
}

export default User
