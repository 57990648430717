import {
  makeStyles,
  TextField,
  Tooltip,
  Zoom,
} from '@material-ui/core'
import React, {
  FunctionComponent,
} from 'react'
import {
  useTranslate,
} from 'react-admin'
import {
  IInputProps,
} from 'src/types'

const useStyles = makeStyles((theme) => ({
  textfield: {
    marginBottom: theme.spacing(1),
  },
}))

const StringInput: FunctionComponent<IInputProps<string>> = ({
  property,
  name,
  onChange,
  value,
  ...props
}: IInputProps<string>) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Tooltip
      TransitionComponent={Zoom}
      placement="right"
      title={property.description || ''}
      arrow
    >
      <TextField
        fullWidth
        className={classes.textfield}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        placeholder={props.placeholder ?? property.displayedName ?? name}
        label={property.displayedName ?? name}
        value={value || property.defaultValue || ''}
        helperText={
          props.invalid
            ? typeof props.error === 'string'
              ? translate(props.error)
              : translate(props.error.message, props.error.args)
            : ''
        }
        disabled={property && property.readOnly}
        multiline={property.multiline}
        InputLabelProps={props.InputLabelProps}
      />
    </Tooltip>
  )
}

export default StringInput
