import {
  Connection,
  Node,
} from '@obvious.tech/rete'
import AreaPlugin from 'rete-area-plugin'

import {
  editor,
} from '../components/Flow'

class MountedEditorHelper {
  diagram
  initialNodes = 0
  initialConnections = 0
  renderedNodes = 0
  renderedConnections = 0
  mounted = false
  listeners = []
  _handleLoading = () => null

  set diagramToRender (diagram) {
    if (!diagram) return

    if (this.diagram?.id === diagram.id) return

    this.diagram = diagram
    this.resetRenderInfo()

    const nodes = diagram.data?.nodes
      ? diagram.data.nodes
      : {
        }

    this.initialNodes = Object.keys(nodes).length
    if (!this.initialNodes) {
      // there may be previous blueprint with nodes unmounting
      !editor?.nodes?.length && this.setRendered()
      return
    }

    this.initialConnections = this.countTotalConnections(nodes)
  }

  set loader (loader) {
    this._handleLoading = loader
  }

  isDiagramEmpty (diagram) {
    const nodes = diagram.data?.nodes
      ? diagram.data.nodes
      : {
        }
    return !Object.keys(nodes).length
  }

  handleClearedEditor () {
    if (this.initialNodes) return
    this.diagram && this.setRendered()
  }

  exitDiagramEditor () {
    this.diagram = null
    this.resetRenderInfo()
  }

  toggleMiniMap () {
    if (!editor?.view) return
    const map = editor.view.container.querySelector('div.minimap')
    if (!map) return
    map.style.display = this.mounted ? (map.style.display = 'block') : 'none'
  }

  countTotalConnections (nodes) {
    const nodesArr = Object.values(nodes) || []
    const totalConnections = nodesArr.reduce((acc, { id, inputs, outputs }) => {
      const nodeInputConnections = inputs
        ? Object.values(inputs).reduce(
          (acc, cur) => [
            ...acc,
            ...Object.values(cur)
              .flat()
              .map((connection) => ({
                ...connection,
                originId: id,
              })),
          ],
          [],
        )
        : []
      const nodeOutputConnections = outputs
        ? Object.values(outputs).reduce(
          (acc, cur) => [
            ...acc,
            ...Object.values(cur)
              .flat()
              .map((connection) => ({
                ...connection,
                originId: id,
              })),
          ],
          [],
        )
        : []
      return [...acc, ...nodeInputConnections, ...nodeOutputConnections]
    }, [])
    const connectionsCount = totalConnections.length / 2

    return connectionsCount
  }

  resetRenderInfo () {
    this.initialNodes = 0
    this.initialConnections = 0
    this.resetRenderedInfo()
  }

  resetRenderedInfo () {
    this.renderedNodes = 0
    this.renderedConnections = 0
    this.mounted = false
  }

  checkRenderedEditor (data) {
    if (data instanceof Node) {
      this.renderedNodes += 1
    }

    if (data instanceof Connection) {
      this.renderedConnections += 1
    }

    if (
      this.renderedConnections === this.initialConnections &&
      this.renderedNodes === this.initialNodes
    ) {
      this.setRendered()
    }
  }

  setRendered () {
    this.mounted = true
    this.runListeners()

    if (!this.diagram.position && editor) { AreaPlugin.zoomAt(editor, editor.nodes) }

    if (this.diagram.position) {
      const { x, y } = this.diagram.position
      editor.view.area.translate(x, y)
    }
    if (this.diagram.zoom) {
      editor.view.area.zoom(this.diagram.zoom)
    }

    this._handleLoading(false)
  }

  addListeners (...listeners) {
    listeners.forEach((listener) => this.listeners.push(listener))
  }

  runListeners () {
    this.listeners.forEach((listener) => {
      if (!listener || typeof listener !== 'function') return

      listener()
    })
  }
}

export default new MountedEditorHelper()
