import jwtDecode from 'jwt-decode'

const validateToken = (token) => {
  const jwtDecoded = jwtDecode(token)
  const currentTime = new Date().getTime() / 1000
  return currentTime < jwtDecoded.exp
}

const saveAuthDataToLS = ({ passport, token }) => {
  localStorage.setItem('username', passport.username)
  localStorage.setItem('passportId', passport.id)
  localStorage.setItem('token', token)
}

const removeAuthDataFromLS = () => {
  localStorage.removeItem('username')
  localStorage.removeItem('passportId')
  localStorage.removeItem('token')
}

const authProvider = {
  login: async (params) => {
    const { username, password } = params
    const request = new Request(
      `${window._env_.API_URL}/authentication/api/signin?username=${username}&password=${password}`,
      {
        method: 'GET',
      },
    )

    const response = await fetch(request)
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText)
    }
    const res = await response.json()
    saveAuthDataToLS(res)
    return Promise.resolve()
  },
  logout: (params) => {
    removeAuthDataFromLS()
    return Promise.resolve()
  },
  checkAuth: (params) => {
    return localStorage.getItem('token') && validateToken(localStorage.getItem('token'))
      ? Promise.resolve()
      : Promise.reject()
  },
  checkError: (error) => {
    const { status } = error

    if (status === 401 || status === 403) {
      removeAuthDataFromLS()
      return Promise.reject()
    }
    return Promise.resolve()
  },
  getPermissions: (params) => Promise.resolve(),
}

export default authProvider
