import * as React from 'react'
import {
  Login,
} from 'react-admin'

const CustomLoginPage = () => {
  return <Login />
}

export default CustomLoginPage
