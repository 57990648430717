import Rete from '@obvious.tech/rete'

import {
    getSocketFromType,
} from '../../Sockets/utils'
import SelectControl from '../../Controls/SelectControl'
import {
    getControl,
} from '../../../../helpers/ControlHelper'
import {
    isRequired,
} from '../../../../helpers/validationHelper'

export default class DateFunctionComponent extends Rete.Component {
    timeOptions = [{
            value: 'years',
            label: 'Years',
        },
        {
            value: 'months',
            label: 'Months',
        },
        {
            value: 'weeks',
            label: 'Weeks',
        },
        {
            value: 'days',
            label: 'Days',
        },
        {
            value: 'hours',
            label: 'Hours',
        },
        {
            value: 'minutes',
            label: 'Minutes',
        },
        {
            value: 'seconds',
            label: 'Seconds',
        },
    ]

    task = {
        outputs: {
            result: 'output',
        },
    }

    validation = {
        errorRules: {
            date: [isRequired('connection is required')],
            amount: [isRequired()],
            timeUnit: [isRequired('value is required')],
        },
    }

    contextMenuPath = ['Functions', 'date']

    constructor(name, types, returnType, action) {
        super(name)
        this.action = action
        this.returnType = returnType
        this.types = types
    }

    drawInputs(node) {
        const timeTypeControl = new SelectControl(
            this.editor,
            node,
            'timeUnit',
            'Time unit',
            () => null,
            this.timeOptions,
            '',
            false,
        )

        const numberInput = new Rete.Input(
            'amount',
            'Amount',
            getSocketFromType('number', undefined),
        )
        const numberControl = getControl(
            this.editor,
            node,
            'number',
            'amount',
            'Amount',
        )
        numberInput.addControl(numberControl)

        node.addControl(timeTypeControl)
        node.addInput(numberInput)
    }

    builder(node) {
        this.drawInputs(node)

        const dateInput = new Rete.Input(
            'date',
            'date',
            getSocketFromType(this.types[0], undefined, 'date-time'),
        )
        node.addInput(dateInput)

        const dateOutput = new Rete.Output(
            'result',
            'Date',
            getSocketFromType(this.returnType, undefined, 'date-time'),
        )
        node.addOutput(dateOutput)
    }
}