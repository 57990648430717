import Rete from '@obvious.tech/rete'
import {
    sockets,
} from '../..'
import {
    getSocketFromType,
} from '../../Sockets/utils'

export default class MergeArraysComponent extends Rete.Component {
    constructor() {
        super('Merge Array')
        this.task = {
            outputs: {
                arr: 'output',
            },
        }
        this.contextMenuPath = ['Arrays']
    }

    reDrawNode(node) {
        node.update()
        this.editor.view.updateConnections({
            node,
        })
    }

    removeOut(node) {
        node.outputs.forEach((o) => {
            o.connections.forEach((connection) => {
                this.editor.removeConnection(connection)
            })
            node.removeOutput(o)
        })
    }

    areAllsocketsOneType(node) {
        return node.inputs.get('arrays').connections.reduce((acc, conn) => {
            if (!acc) return acc

            if (acc === true) {
                acc = conn.output.socket.name
            }
            if (acc !== conn.output.socket.name) {
                acc = false
            }
            return acc
        }, true)
    }

    getSocketName(node) {
        return node.inputs.get('arrays').connections[0].output.socket.name
    }

    updateOutSocket(node) {
        this.removeOut(node)
        this.addActualOutput(node)
        this.reDrawNode(node)
    }

    addActualOutput(node) {
        let output
        if (
            node.inputs.get('arrays').connections.length === 0 ||
            !this.areAllsocketsOneType(node) ||
            this.areAllsocketsOneType(node) === 'Array'
        ) {
            output = new Rete.Output('arr', 'Array', sockets.arraySocket)
            node.data.socketType = 'Array'
        } else {
            output = new Rete.Output(
                'arr',
                `Array of ${this.getSocketName(node).replace('Array', '')}`,
                getSocketFromType(
                    'array',
                    this.getSocketName(node).replace('Array', ''),
                ),
            )
            node.data.socketType = this.getSocketName(node)
        }
        node.addOutput(output)
    }

    updateOuputSocketName(node, socketType) {
        if (node.data.socketType !== socketType) {
            this.updateOutSocket(node)
        }
    }

    checkOutputSocketName(node) {
        this.updateOutSocket(node)
    }

    builder(node) {
        const inp = new Rete.Input(
            'arrays',
            'Any arrays',
            sockets.arraySocket,
            true,
        )
        node.addInput(inp)

        if (!node.data.socketType || node.data.socketType === 'Array') {
            node.addOutput(new Rete.Output('arr', 'Array', sockets.arraySocket))
        } else {
            node.addOutput(
                new Rete.Output(
                    'arr',
                    `Array of ${node.data.socketType.replace('Array', '')}`,
                    getSocketFromType('array', node.data.socketType.replace('Array', '')),
                ),
            )
        }

        return node
    }

    connected = (connection) => {
        if (connection.input.key === 'arrays') {
            if (!this.editor.silent) {
                this.updateOuputSocketName(
                    connection.input.node,
                    connection.output.socket.name,
                )
            }
        }
    }

    disconnected = (connection) => {
        if (connection.input.key === 'arrays') {
            setTimeout(() => {
                this.checkOutputSocketName(connection.input.node)
            })
        }
    }

    worker(node, inputs) {}
}