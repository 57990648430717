import React from 'react'
import {
  useSelector,
  useDispatch,
} from 'react-redux'
import {
  useHistory,
} from 'react-router-dom'
import {
  Box,
  capitalize,
  List,
  makeStyles,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'

import HelpDialog from 'src/components/flow/HelpDialog'
import * as editorActions from 'src/redux/editor/editor.actions'

import {
  editor,
} from './components/Flow'
import flowStyles from './theme/styles'
import FlowSquareIconButton from './theme/components/flowSquareIconButton'
import AddMenu from './AddMenu'
import {
  TRootState,
} from 'src/redux'
import {
  Diagram,
} from '@obvious.tech/constellation'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  blueprintTab: {
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: '5px',
    color: theme.palette.text.primary,
    display: 'inline-block',
    paddingLeft: '10px',
    marginRight: '10px',
    boxShadow: '0px 0px 2px 1px rgba(0,0,0,0.3)',
    cursor: 'pointer',
    fontFamily: flowStyles.fontFamily,
    '&.diagram': {
      backgroundColor: '#fa6c51',
    },
    '&.module': {
      backgroundColor: '#fdcd56',
    },
    '& .blueprintTabInner': {
      borderLeft: `1px solid ${theme.palette.text.primary}`,
      height: '100%',
      paddingLeft: '10px',
      paddingRight: '3px',
      borderRadius: '0 4px 4px 0 ',
      backgroundColor: 'white',
    },
    '&.selected .blueprintTabInner': {
      backgroundColor: flowStyles.palette.primary.main,
      color: 'white',
    },
    '&.selected:hover .blueprintTabInner': {
      backgroundColor: '#3aadd9',
    },
    '& .flowTabIcon': {
      margin: '2px 2px 2px 5px',
      padding: 0,
    },
    '& .flowTabIcon:hover': {
      backgroundColor: flowStyles.palette.primary.main,
      color: 'white',
    },
    '&.selected .flowTabIcon:hover': {
      backgroundColor: 'white',
      color: '#3aadd9',
    },
  },
}))

interface IDiagram extends Diagram {
  type: 'diagram' | 'module'
  id: string
  updatedLocally: boolean
}

const BlueprintTab = ({ closeBlueprint, addTab }): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const openedBlueprints: IDiagram[] = useSelector(
    (state: TRootState) => state.editor.openedBlueprints,
  )
  const currentBlueprint: string = useSelector(
    (state: TRootState) => state.editor.currentBlueprint,
  )

  const handleChangeTab = (blueprint: IDiagram): void => {
    const prevBlueprint = openedBlueprints.find(
      ({ id }) => id === currentBlueprint,
    )

    if (window.isNullish(prevBlueprint)) return

    const editorAreaValues = editor.view.area.transform

    dispatch(
      editorActions.updateBlueprintInOpenedList({
        ...prevBlueprint,
        data: editor.toJSON(),
        position: {
          x: editorAreaValues.x,
          y: editorAreaValues.y,
        },
        zoom: editorAreaValues.k,
      }),
    )
    history.push(`/flow/api/${capitalize(blueprint.type)}/${blueprint.id}`)
  }

  return (
    <Box className={classes.root}>
      <Box display="flex">
        <AddMenu addTab={addTab} />
        <List disablePadding>
          {openedBlueprints.map((blueprint: IDiagram, index: number) => {
            return (
              <Box
                key={`${blueprint.name.replace(/\s/g, '')}${index}`}
                onClick={() => handleChangeTab(blueprint)}
                className={`${classes.blueprintTab} ${blueprint.type} ${
                  blueprint.id === currentBlueprint ? 'selected' : ''
                }`}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  className="blueprintTabInner"
                >
                  <span>{`${blueprint.name}${
                    blueprint.updatedLocally ? '*' : ''
                  }`}</span>
                  <FlowSquareIconButton
                    color="inherit"
                    additionalIconClass="flowTabIcon"
                    onClick={(e: MouseEvent) => {
                      e.stopPropagation()
                      closeBlueprint(blueprint)
                    }}
                  >
                    <CloseIcon />
                  </FlowSquareIconButton>
                </Box>
              </Box>
            )
          })}
        </List>
      </Box>
      <HelpDialog />
    </Box>
  )
}

export default BlueprintTab
