import {
  TEntityOverride,
} from 'src/types'

const override: TEntityOverride = {
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
    },
  },
  list: {
    properties: {
    },
  },
  create: {
    hidden: true,
    properties: {
    },
  },
  edit: {
    hidden: true,
    properties: {
    },
  },
}

export default override
