import englishMessages from 'ra-language-english'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import frenchMessages from 'ra-language-french'

import frenchTranslationFile from './fr/french.json'
import englishTranslationFile from './en/english.json'

const messages = {
  fr: { ...frenchMessages, ...frenchTranslationFile },
  en: { ...englishMessages, ...englishTranslationFile }
}

const i18nProvider = polyglotI18nProvider(
  locale => {
    return messages[locale]
  },
  localStorage.getItem('language') ?? 'en',
  {
    allowMissing: true
  }
)

export default i18nProvider
