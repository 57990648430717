import {
  TEntityOverride,
} from 'src/types'

const override: TEntityOverride = {
  titleField: 'name',
  global: {
    properties: {
      authorizedPassports: {
        displayedName: 'Authorized Passports',
        referenceTo: 'authentication/api/Passport',
        referencedField: 'username',
      },
      groups: {
        type: 'groups',
      },
      imageId: {
        displayedName: 'Image Name',
        referenceTo: 'resources/api/Image',
        referencedField: 'name',
      },
    },
  },
  list: {
    properties: {
      imageId: {
        type: 'Image',
        displayedName: 'Image',
        referenceTo: 'resources/api/Image',
        referencedField: 'imageData',
      },
    },
  },
}

export default override
