export default async (apiPromise, options = {}) => {
  const api = await apiPromise;
  return Object.keys(api.paths).reduce((acc, pathKey) => {
    if (pathKey.indexOf('{id}') > 0) {
      const pathKeyArray = pathKey.split('/');
      const idx = pathKeyArray.findIndex((elem) => elem === '{id}');
      if (idx === pathKeyArray.length - 1) {
        const microservice = pathKeyArray[1];
        const event = pathKeyArray[pathKeyArray.length - 2];
        return {
          ...acc,
          [microservice]: acc[microservice]
            ? [...acc[microservice], event]
            : [event],
        };
      }
    }
    return acc;
  }, {});
};
