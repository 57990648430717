import React, { useEffect } from 'react'
import { IAlarm, ICamera } from '@obvious.tech/constellation'

import api, { TApiError, TEntity } from 'src/utils/api'
import Loadable, { TLoadable } from 'src/utils/Loadable'

const getCamerasByIDs = ({ ids, token}: {
  ids: string[],
  token?: string
}) => api.fetchResource<Array<ICamera>>(
  `videomanager/api/Camera?Filters=id==${ids.join('|')}`
)({
  token,
})

type TProps = {
  alarm: TEntity<IAlarm>
  authorization?: string
}

export type TUseAdditionalDetailsCamerasResult = TLoadable<
  TEntity<ICamera>[],
  TApiError
>

const useAdditionalDetailsCameras = ({
  alarm,
  authorization
}: TProps): TUseAdditionalDetailsCamerasResult => {
  const [cameras, getCameras] = api.useApiAction(getCamerasByIDs)

  const cameraIds = React.useMemo(() => {
    return (alarm.additionalDetails ?? []).reduce<string[]>(
      (acc, detail) => {
        if (
          detail.type !== 'CameraData' ||
          typeof detail.data?.ID !== 'string'
        ) return acc
        return [...acc, detail.data.ID]
      },
      [],
    )
  }, [alarm.additionalDetails])

  useEffect(() => {
    if (!cameraIds.length)
      return
    
    return getCameras({ ids: cameraIds })
  }, [cameraIds, getCameras, authorization])

  if (Loadable.isFailure(cameras))
    return Loadable.failure(cameras.error)
  if (!cameraIds.length)
    return Loadable.success([])
  return cameras
}

export default useAdditionalDetailsCameras
