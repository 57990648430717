import React, { useState } from 'react';

import { UserMenuOptions as UMO } from 'src/components/UserMenu';
import EntityPropertiesList from 'src/components/EntityPropertiesList';

import GenericField from './GenericField';
import CustomizableDatagrid from '../../adminPanel/layout/ra-customizable-datagrid/CustomizableDatagrid';

const DISABLED_SORT_FIELDS = [
  'id',
  'file',
  'boundingBox',
  'resolution',
  'imageData',
  'location',
  'additionalDetails',
  'ptzLock'
]

const sanitizeFieldProps = ({
  hasCreate,
  hasShow,
  hasEdit,
  hasList,
  currentSort,
  defaultTitle,
  displayedFilters,
  filterValues,
  hideFilter,
  loading,
  loaded,
  onToggleItem,
  onUnselectItems,
  perPage,
  selectedIds,
  setFilters,
  setPage,
  setPerPage,
  setSort,
  showFilter,
  totalPages,
  hasBulkActions,
  exporter,
  ...fieldProps
}) => fieldProps;

const sanitizeDatagridProps = ({ hasShow, hasEdit, hasList, ...datagridProps }) => datagridProps;

const FieldList = ({ entity, hasEdit, ...rest }) => {
  const [configurationOpen, setConfigurationOpen] = useState(false);

  const isFieldSortable = (propertyKey) => {
    if (DISABLED_SORT_FIELDS.includes(propertyKey)) return false

    return true
  }

  const isTextSelected = () => {
    const selection = window.getSelection();

    if (selection?.type === 'Range') return true;

    return false;
  };

  const handleRowClick = (recordId) => {
    if (isTextSelected()) return null;

    return !hasEdit ? null : 'edit';
  };

  const fields = EntityPropertiesList({
    entity: entity,
    childProps: rest,
    render: ({ propertyKey, property, index, childProps }) => (
      <GenericField
        key={`${propertyKey}_${index}`}
        name={propertyKey}
        source={propertyKey}
        sortable={isFieldSortable(propertyKey)}
        label={property.displayedName}
        property={property}
        {...sanitizeFieldProps(childProps)}
      />
    ),
  });

  return (
    <>
      <UMO>
        <UMO.Item title="Configuration" onClick={() => setConfigurationOpen(true)} />
      </UMO>
      <CustomizableDatagrid
        {...sanitizeDatagridProps(rest)}
        rowClick={handleRowClick}
        open={configurationOpen}
        closeModal={() => setConfigurationOpen(false)}
        hasEdit={hasEdit}
      >
        {fields}
      </CustomizableDatagrid>
    </>
  );
};

export default FieldList