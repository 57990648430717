// @ts-nocheck
import {
  createTheme,
} from '@material-ui/core'

import CooperHewittMediumFile from 'src/assets/fonts/CooperHewitt-Medium.otf'
import CooperHewittSemiboldFile from 'src/assets/fonts/CooperHewitt-Semibold.otf'
import CooperHewittBoldFile from 'src/assets/fonts/CooperHewitt-Bold.otf'

const _CooperHewittBase = {
  fontFamily: 'CooperHewitt',
  fontDisplay: 'swap',
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}
const CooperHewittMedium = {
  ..._CooperHewittBase,
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    local('CooperHewitt-Medium'),
    local('CooperHewitt'),
    url(${CooperHewittMediumFile}) format('opentype')
  `,
}
const CooperHewittSemibold = {
  ..._CooperHewittBase,
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
    local('CooperHewitt-Semibold'),
    local('CooperHewitt'),
    url(${CooperHewittSemiboldFile}) format('opentype')
  `,
}
const CooperHewittBold = {
  ..._CooperHewittBase,
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local('CooperHewitt-Bold'),
    local('CooperHewitt'),
    url(${CooperHewittBoldFile}) format('opentype')
  `,
}

const customTheme = {
  inputs: {
    textfield: {
      margin: 8,
      padding: 4,
    },
  },
  sidebarWidth: '200px',
  headerHeight: '48px',
  contentPadding: '24px',
}

/*
 ** TO-DO : find better way to do this
 **
 ** @material-ui declaration was overriden globally to allow for extra
 ** properties to be added. We should probably create our own makeStyles-like
 ** function to be able to add the customTheme properties to Mui's one.
 ** Or find a standard way to do it, as it could be problematic later on.
 **
 ** see src/types/global/material-ui.d.ts
 */
const theme = createTheme<typeof customTheme>({
  palette: {
    primary: {
      main: '#4fc0e8',
      contrastText: '#fff',
    },
    action: {
      // default value converted to hex
      hover: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: 'CooperHewitt, sans-serif',
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontSize: 14,
  },
  overrides: {
    MuiTablePagination: {
      toolbar: {
        backgroundColor: '#eee',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          CooperHewittMedium,
          CooperHewittSemibold,
          CooperHewittBold,
        ],
      },
    },
  },
  ...customTheme,
})

export default theme
