// @ts-nocheck
import {
  NodeEditor,
  Selected,
} from '@obvious.tech/rete'
import bindEvents from './events'

import BoxSelection from './BoxSelection'

export default class Editor extends NodeEditor {
  selected = new Selected()

  boxSelection: BoxSelection

  constructor (id: string, container: HTMLElement) {
    super(id, container)

    bindEvents(this)

    this.boxSelection = new BoxSelection(container, this)
    this.on('selectAllNodes', this.selectAllNodes)

    let pointerDownX = 0
    let pointerDownY = 0

    this.trigger('componentDidMount')

    container.addEventListener('pointerdown', e => {
      pointerDownX = e.pageX
      pointerDownY = e.pageY
    })

    container.addEventListener('pointerup', e => {
      const wasPointerDragged = pointerDownY !== e.pageY || pointerDownX !== e.pageX

      if (!wasPointerDragged) this.unselectNodes()
    })
  }

  unselectNodes = (): void => {
    this.selected.clear()
  }

  selectAllNodes = (): void => {
    this.nodes.forEach(node => this.selected.select(node))

    this.trigger('allNodesSelected', this.selected.list)
  }
}
