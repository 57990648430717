import React from 'react'
import {
  useDispatch,
} from 'react-redux'
import {
  BulkDeleteButton,
} from 'react-admin'

import CustomBulkActions from '../../../../adminPanel/screens/List/BulkActionButtons'
import {
  removeOpenedBlueprint,
} from '../../../../redux/editor/editor.actions'

const ListBulkActions = (props) => {
  const { selectedIds } = props
  const dispatch = useDispatch()

  const onClick = () => {
    selectedIds.forEach((id) => {
      dispatch(removeOpenedBlueprint(id))
    })
  }

  return (
    <CustomBulkActions withDelete={false}>
      <BulkDeleteButton {...props} onClick={onClick} />
    </CustomBulkActions>
  )
}

export default ListBulkActions
