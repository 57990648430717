// @ts-nocheck
import {
  Node,
  listenWindow,
  Selected,
} from '@obvious.tech/rete'

import Editor from './Editor'
import BoxSelectionPan from './BoxSelectionPan'

const BOX_SELECT_KEY = 'KeyB'
const BOX_SELECT_MODIFIER_KEY = 'ControlLeft'

class BoxSelection {
  isBoxSelecting = false

  isModified = false

  selectionEl = document.createElement('div')

  startPosition = {
    x: 0,
    y: 0,
  }

  currentPosition = {
    x: 0,
    y: 0,
  }

  isPrevSelectionEnabled = false

  preSelected: string[] = []

  boxSelectionPan: BoxSelectionPan

  container: HTMLElement

  nodes: Node[]

  selected: Selected

  constructor (container: HTMLElement, editor: Editor) {
    this.container = container
    this.nodes = editor.nodes
    this.selected = editor.selected
    this.boxSelectionPan = new BoxSelectionPan({
      editor,
      boxSelection: this,
    })

    this.createSelectionBox()
    this.subscribe(editor)
  }

  handleSelectionStatus (bool: boolean): void {
    this.isBoxSelecting = bool
    if (!bool) {
      this.selectionEl.style.display = 'none'
      this.container.style.cursor = 'inherit'
      return
    }

    this.container.style.cursor = 'crosshair'
    this.startPosition = {
      ...this.currentPosition,
    }
  }

  handlePrevSelectionStatus (bool: boolean): void {
    if (!bool) {
      this.preSelected = []
      this.isPrevSelectionEnabled = false
      return
    }

    this.isPrevSelectionEnabled = true
  }

  mapSelectedList (): string[] {
    return this.selected.list.map(({ id }) => id.toString())
  }

  boxSelect = (e: MouseEvent): void => {
    const { clientX, clientY } = e

    const { scrollTop, scrollLeft } = this.scroll()
    const top = this.getTop(this.container)
    const left = this.getLeft(this.container)

    const x = clientX - left + scrollLeft
    const y = clientY - top + scrollTop
    this.currentPosition = {
      x,
      y,
    }

    if (!this.isBoxSelecting) return

    this.addSelectionRectStyles(x, y)
    this.boxSelectionPan.handleTranslateStart({
      x,
      y,
    })
    this.handleNodesSelection()
  }

  getNodeElsArr (): HTMLDivElement[] {
    return Array.from(document.querySelectorAll('.node'))
  }

  handleNodesSelection (): void {
    const nodeEls = this.getNodeElsArr()

    nodeEls.forEach(nodeEl => {
      const nodeId = nodeEl.attributes?.['data-id']?.value
      const reteNode = this.nodes.find(({ id }) => id.toString() === nodeId)

      if (window.isNullish(reteNode)) return

      const isPreSelected = this.preSelected.includes(nodeId)
      const isInPath = this.isInPath(nodeEl, this.selectionEl)

      if (isPreSelected) {
        if (isInPath) {
          this.selected.deselect(reteNode)
          return
        }
        this.selected.select(reteNode)
        return
      }

      if (isInPath) {
        this.selected.select(reteNode)
        return
      }

      this.selected.deselect(reteNode)
    })
  }

  addSelectionRectStyles (x: number, y: number): void {
    const { x: startX, y: startY } = this.startPosition
    const { style } = this.selectionEl

    style.left = (x > startX ? startX - 1 : x + 1).toString() + 'px'
    style.top = (y > startY ? startY - 1 : y + 1).toString() + 'px'
    style.width = Math.abs(x - startX).toString() + 'px'
    style.height = Math.abs(y - startY).toString() + 'px'
    style.display = 'block'
  }

  createSelectionBox (): void {
    this.selectionEl.classList.add('selection-element')
    this.container.append(this.selectionEl)
  }

  subscribe (editor: Editor): void {
    editor.on('destroy', listenWindow('mousemove', this.boxSelect))

    editor.on('keydown', e => {
      if (e.code === BOX_SELECT_KEY && !this.isBoxSelecting) {
        this.handleSelectionStatus(true)
        if (this.isPrevSelectionEnabled) {
          this.preSelected = this.mapSelectedList()
        }
      }

      if (e.code === BOX_SELECT_MODIFIER_KEY && !this.isPrevSelectionEnabled) {
        this.handlePrevSelectionStatus(true)
      }
    })
    editor.on('keyup', e => {
      if (e.code === BOX_SELECT_KEY && this.isBoxSelecting) {
        this.handleSelectionStatus(false)
        this.boxSelectionPan.handleTranslateEnd()
      }

      if (e.code === BOX_SELECT_MODIFIER_KEY && this.isPrevSelectionEnabled) {
        this.handlePrevSelectionStatus(false)
      }
    })

    editor.on('zoom', e => {
      if (this.isBoxSelecting) {
        this.handleNodesSelection()
      }
    })
  }

  getTop (e): number {
    let offset: number = e.offsetTop
    if (e.offsetParent != null) offset += this.getTop(e.offsetParent)
    return offset
  }

  getLeft (e): number {
    let offset: number = e.offsetLeft
    if (e.offsetParent != null) offset += this.getLeft(e.offsetParent)
    return offset
  }

  scroll (): {
    scrollLeft: number
    scrollTop: number
  } {
    if (!window.isNullish(window.pageYOffset)) {
      return {
        scrollLeft: window.pageXOffset,
        scrollTop: window.pageYOffset,
      }
    }

    if (document.compatMode === 'CSS1Compat') {
      return {
        scrollLeft: document.documentElement.scrollLeft,
        scrollTop: document.documentElement.scrollTop,
      }
    }

    return {
      scrollLeft: document.body.scrollLeft,
      scrollTop: document.body.scrollTop,
    }
  }

  isInPath (target: Element, wrapper: HTMLElement): boolean {
    const targetRect = target.getBoundingClientRect()
    const selectionRect = wrapper.getBoundingClientRect()

    if (
      targetRect.top + targetRect.height > selectionRect.top &&
      targetRect.left + targetRect.width > selectionRect.left &&
      targetRect.bottom - targetRect.height < selectionRect.bottom &&
      targetRect.right - targetRect.width < selectionRect.right
    ) {
      return true
    }

    return false
  }
}

export default BoxSelection
