import React from 'react'
import {
  ColorField,
} from 'react-admin-color-input'

const CustomBooleanField = ({ property, name, ...props }) => {
  return (
    <ColorField
      source={name}
      label={property.displayedName || name}
      {...props}
    />
  )
}

export default CustomBooleanField
