import React from 'react'
import { Loading } from 'react-admin'
import { makeStyles, Typography } from '@material-ui/core'

import useLogs, { TUseLogsResult } from './useLogs'
import useUserTasks, { TUseUserTasksResult } from './useUserTasks'
import useAssignedPassport, { TUseAssignedPassportResult } from './useAssignedPassport'
import MapProvider from './MapProvider'
import useAttachments, { TUseAttachmentsResult } from './useAttachments'
import { useTranslation } from 'react-i18next'
import { IAlarm } from '@obvious.tech/constellation'
import useAdditionalDetails, {
  TUseAdditionalDetailsResult
} from './useAdditionalDetails'
import useAdditionalDetailsCameras, { TUseAdditionalDetailsCamerasResult } from './useAdditionalDetailsCameras'
import Loadable, {
  TLoadableGetData,
} from 'src/utils/Loadable'
import { TEntity } from 'src/utils/api'
import { Alert, AlertTitle } from '@material-ui/lab'

const useStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    display: 'flex',
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading: {
    flex: 1,
    zIndex: 1,
  },
})

const PDFLoading = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div
      className={classes.container}
    >
      <Loading
        className={classes.loading}
        loadingPrimary={t("alarm_report.pdf_loading")}
        loadingSecondary=""
      />
    </div>
  )
}

export type TAlarmReportProps = {
  map: any
  alarm: TEntity<IAlarm>
  additionalDetails: TLoadableGetData<TUseAdditionalDetailsResult>
  cameras: TLoadableGetData<TUseAdditionalDetailsCamerasResult>
  logs: TLoadableGetData<TUseLogsResult>
  tasks: TLoadableGetData<TUseUserTasksResult>
  assignedPassport: TLoadableGetData<TUseAssignedPassportResult>
  attachments: TLoadableGetData<TUseAttachmentsResult>
}

type TLoadedProps = React.PropsWithChildren<TAlarmReportProps>

// @ts-expect-error
const Loaded = ({ loading, children, ...rest }: TLoadedProps) => {
  return loading ? (
    <PDFLoading />
  ) : (
  /* @ts-expect-error */
    React.cloneElement(children, { ...rest })
  )
}

export type TProps = React.PropsWithChildren<{
  alarm: TEntity<IAlarm>
  authorization?: string
}>

export default ({ alarm, authorization, children }: TProps) => {
  const loadables = Loadable.all({
    additionalDetails: useAdditionalDetails({ alarm }),
    cameras: useAdditionalDetailsCameras({ alarm, authorization }),
    logs: useLogs({ alarm, authorization }),
    tasks: useUserTasks({ alarm, authorization }),
    assignedPassport: useAssignedPassport({ alarm, authorization }),
    attachments: useAttachments({ alarm, authorization }),
  })

  if (Loadable.isFailure(loadables)) {
    return (
      <Alert severity="error">
        <AlertTitle>Failed to create Alarm Report</AlertTitle>
        {(loadables.error)
          .map(([from, error]) => {
            const errors = Array.isArray(error) ? error : [error]

            return (
              <>
              {errors.map(error => (
                <Typography>{`${from}: ${error.message}`}</Typography>
              ))}
              </>
            )
          })
        }
      </Alert>
    )
  }

  if (loadables.loading || !loadables.loaded) return <PDFLoading />

  return (
    <>
      {/** @ts-ignore */}
      <MapProvider alarm={alarm} authorization={authorization}>
        {/** @ts-ignore */}
        <Loaded
          {...loadables.data}
        >
          {children}
        </Loaded>
      </MapProvider>
    </>
  )
}
