import React from 'react'
import {
  Admin,
  Resource,
  TranslationProvider,
} from 'react-admin'
import {
  useSelector,
} from 'react-redux'

import theme from 'src/theme/theme'

import Layout from './layout'
import CustomList from './screens/List/CustomList'
import CustomEdit from './screens/Edit/CustomEdit'
import CustomCreate from './screens/Create/CustomCreate'
import customRoutes from './customRoutes'
import DashBoard from './screens/Custom/DashBoard'
import CustomLoginPage from './login/Login'
import {
  useLocalSwaggerFiles,
} from 'src/utils/hooks'

const AdminPanel = ({ dataProvider, authProvider, history, i18nProvider }) => {
  const entities = useSelector((state) => state.definitions.entities)
  const microservices = useSelector((state) => state.microservices)

  useLocalSwaggerFiles()

  if (!entities) return null

  const layoutComponent = (microservices) => (props) =>
    <Layout microservices={microservices} {...props} />

  return (
    <TranslationProvider locale="en" i18nProvider={i18nProvider}>
      <Admin
        loginPage={CustomLoginPage}
        dashboard={DashBoard}
        i18nProvider={i18nProvider}
        theme={theme}
        customRoutes={customRoutes}
        layout={layoutComponent(microservices)}
        title="Constellation Administration"
        authProvider={authProvider}
        dataProvider={dataProvider}
        history={history}
      >
        {Object.keys(microservices).map((microservice) =>
          microservices[microservice].map((entityKey) => {
            let entity = entities[entityKey]

            if (!entity) {
              if (entityKey !== 'Licence') entity = entities.ProductLicense
              else return <span />
            }

            return (
              <Resource
                key={entityKey}
                name={`${microservice}/api/${entityKey}`}
                options={{
                  label: entityKey,
                }}
                list={(props) => (
                  <CustomList name={entityKey} entity={entity} {...props} />
                )}
                edit={
                  !entity.edit.hidden
                    ? (props) => (
                        <CustomEdit
                          name={entityKey}
                          entity={entity}
                          {...props}
                        />
                      )
                    : null
                }
                create={
                  !entity.create.hidden
                    ? (props) => (
                        <CustomCreate
                          name={entityKey}
                          entity={entity}
                          {...props}
                        />
                      )
                    : null
                }
                show={null}
              />
            )
          }),
        )}
      </Admin>
    </TranslationProvider>
  )
}

export default AdminPanel
