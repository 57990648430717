import React, {
  useEffect,
  useState,
} from 'react'
import {
  useSelector,
  useDispatch,
} from 'react-redux'
import {
  useDataProvider,
  useEditContext,
} from 'react-admin'

import {
  makeStyles,
} from '@material-ui/core/styles'
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

import * as editorActions from '../../redux/editor/editor.actions'
import * as editorConstants from '../../redux/editor/editor.constants'
// TODO: remove later
import packageJson from '../../../../../package.json'
import {
  ErrorInfoText,
  FixWarningsButton,
  UsePreSaveValidation,
} from './PreSaveValidation'
import {
  editor,
} from './components/Flow'
import defaultClasses from './theme/classes'
import {
  DialogButton,
} from './components/shared'
import useErrorFetch from 'src/hooks/useErrorFetch'

const useStyles = makeStyles({
  ...defaultClasses,
  dialogTitle: {
    fontSize: '15pt',
    fontFamily: 'CooperHewitt',
  },
})

const CreateNewDiagramDialog = ({ type, addBlueprint, getBlueprintData }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const fetchError = useErrorFetch()
  const dataProvider = useDataProvider()
  const editContext = useEditContext()

  const newBlueprintDialogDetails = useSelector(
    (state) => state.editor.newBlueprintDialog || false,
  )

  const [errors, , isEditorValid] = UsePreSaveValidation()
  const [name, setName] = useState('')
  const [newBlueprintDialog, setNewBlueprintDialog] = useState(
    newBlueprintDialogDetails,
  )

  useEffect(() => {
    if (
      editor &&
      newBlueprintDialogDetails?.variant ===
        editorConstants.SAVE_WITH_NAME_DIALOG
    ) {
      isEditorValid()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBlueprintDialogDetails])

  useEffect(() => {
    if (newBlueprintDialogDetails) {
      setNewBlueprintDialog(newBlueprintDialogDetails)
    }
    const variants = [
      editorConstants.SAVE_WITH_NAME_DIALOG,
      editorConstants.SHOW_CHANGE_TITLE_DIALOG,
    ]

    if (
      newBlueprintDialogDetails.blueprint &&
      variants.includes(newBlueprintDialogDetails.variant)
    ) {
      setName(newBlueprintDialogDetails.blueprint.name)
    }
  }, [newBlueprintDialogDetails])

  const createNewBlueprint = async ({ name, type, nodes, events }) => {
    try {
      const [flow, api] = editContext.resource.split('/')

      const newBlueprint = {
        name,
        status: 'stopped',
        createdBy: localStorage.getItem('username') || 'flow_worker',
        data: {
          id: `${packageJson.reteApplicationName}@${packageJson.version}`,
          nodes: nodes || {
          },
        },
        events: events || [],
      }

      const { data } = await dataProvider.create(`${flow}/${api}/${type}`, {
        data: newBlueprint,
      })

      const savedBlueprint = {
        ...data,
        type,
      }

      dispatch(editorActions.addBlueprint(savedBlueprint))
      dispatch(editorActions.addFetchedBlueprint(savedBlueprint))

      addBlueprint && addBlueprint(savedBlueprint)
    } catch (e) {
      fetchError(e)
    }
  }

  const handleChangeTitle = ({ target: { value } }) => setName(value)

  const handleChangeBlueprintName = async () => {
    const params = {
      id: newBlueprintDialog.blueprint.id,
      data: {
        ...newBlueprintDialog.blueprint,
        name,
      },
    }

    const { data } = await dataProvider.update(editContext.resource, params)
    const updatedBlueprint = {
      ...data,
      updatedLocally: false,
    }

    dispatch(editorActions.updateBlueprintInOpenedList(updatedBlueprint))
    dispatch(editorActions.updateFetchedBlueprint(updatedBlueprint))
  }

  const drawButtons = () => {
    const defaultButtons = (
      <>
        <DialogButton
          onClick={() => createNewBlueprint({
            name,
            type: 'diagram',
          })}
        >
          Create new diagram
        </DialogButton>
        <DialogButton
          onClick={() => createNewBlueprint({
            name,
            type: 'module',
          })}
        >
          Create new module
        </DialogButton>
      </>
    )
    const variant = newBlueprintDialog?.variant
    const variants = {
      [editorConstants.SHOW_NEWBLUEPRINT_DIALOG]: () => defaultButtons,
      [editorConstants.SAVE_WITH_NAME_DIALOG]: () => {
        const { nodes, events } =
          (getBlueprintData && getBlueprintData()) || {
          }

        return (
          <>
            {errors && (
              <FixWarningsButton
                onClose={(clientPointer, name) =>
                  dispatch(
                    editorActions.hideNewBlueprintDialog(clientPointer, name),
                  )
                }
                errors={errors}
              >
                fix warnings
              </FixWarningsButton>
            )}
            <DialogButton
              onClick={() =>
                createNewBlueprint({
                  name,
                  nodes,
                  events,
                  type: newBlueprintDialog.blueprint.type,
                })
              }
            >
              Save with entered name
            </DialogButton>
          </>
        )
      },
      [editorConstants.SHOW_CHANGE_TITLE_DIALOG]: () => (
        <DialogButton onClick={handleChangeBlueprintName}>Save</DialogButton>
      ),
    }

    return variants[variant] ? variants[variant]() : defaultButtons
  }

  const getTitle = () => {
    const defaultTitle = 'New blueprint'
    const variant = newBlueprintDialog?.variant
    const variants = {
      [editorConstants.SHOW_NEWBLUEPRINT_DIALOG]: defaultTitle,
      [editorConstants.SAVE_WITH_NAME_DIALOG]: `Save ${type} with new title`,
      [editorConstants.SHOW_CHANGE_TITLE_DIALOG]: `Change title for the ${type}`,
    }

    return variants[variant] ? variants[variant] : defaultTitle
  }

  return (
    <div>
      <Dialog
        open={Boolean(newBlueprintDialogDetails)}
        onClose={(clientPointer, name) =>
          dispatch(editorActions.hideNewBlueprintDialog(clientPointer, name))
        }
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          className={classes.dialogTitle}
          disableTypography
          id="form-dialog-title"
        >
          {getTitle()}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{`Chose a title for you ${type}`}</DialogContentText>
          {errors && <ErrorInfoText mb={1} />}
          <TextField
            value={name}
            placeholder="Title"
            onChange={handleChangeTitle}
            margin="dense"
            id="title "
            label="Title"
            fullWidth
          />
        </DialogContent>
        <DialogActions className={classes.dialogActionsContainer}>
          <DialogButton
            onClick={(clientPointer, name) =>
              dispatch(
                editorActions.hideNewBlueprintDialog(clientPointer, name),
              )
            }
          >
            Cancel
          </DialogButton>
          {drawButtons()}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CreateNewDiagramDialog
