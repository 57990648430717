import React from 'react';
import { Create } from 'react-admin';
import { connect } from 'react-redux';

import InputList from '../inputs/InputList';
import CustomTitle from '../Title/Title';
import extendGlobalEntity from 'src/parser/entities/extendGlobalEntity';

export const CustomCreate = ({ entity = {}, name, ...props }) => {
  const { id, basePath, resource } = props;
  let { properties, ...restCreateEntity } = extendGlobalEntity(
    entity,
    'create'
  );

  const createEntity = {
    ...restCreateEntity,
    properties: Object.fromEntries(
      Object.entries(properties).map(([key, property]) => [
        key,
        {
          ...property,
          hidden: property.readOnly || property.hidden,
        },
      ])
    ),
  };

  const transformNumberValue = ({ record, srcRecord, key }) => {
    const required = properties[key]?.required ?? false
    const value = srcRecord[key]
    if (window.isNullish(value) && !required) return

    record[key] = value
  }

  const transform = (srcRecord) => {
    const record = {}

    Object.keys(srcRecord).forEach((key) => {
      const property = properties[key]

      if (property?.type === 'number' || property?.type === 'integer') {
        transformNumberValue({
          key,
          srcRecord,
          record
        })

        return
      }

      record[key] = srcRecord[key]
    })

    return record
  }

  const DisplayedComponent = createEntity.displayedComponent;

  return DisplayedComponent ? (
    <DisplayedComponent
      entity={createEntity}
      name={name}
      id={id}
      basePath={basePath}
      resource={resource}
    />
  ) : (
    <Create
      title={<CustomTitle entity={createEntity} {...props} />}
      redirect={
        createEntity.redirect === undefined ? 'list' : createEntity.redirect
      }
      transform={transform}
      basePath={basePath}
      resource={resource}
      id={id}
      key={id}
    >
      <InputList entity={createEntity} {...props} />
    </Create>
  );
};

const mapStateToProps = (state) => {
  return {
    entities: state.definitions.entities || {},
    microservices: state.microservices,
  };
};

export default connect(mapStateToProps, null)(CustomCreate);