import {
  TEntityOverride,
} from 'src/types'

const override: TEntityOverride = {
  titleField: 'name',
  global: {
    properties: {
      port: {
        type: 'number',
      },
      groups: {
        type: 'groups',
      },
    },
  },
}

export default override
