import React from 'react'
import { Document, Page, StyleSheet, Text } from '@react-pdf/renderer'

import Attachments from './Attachments'
import Title from './Title'
import Header from './Header'
import Sections from './Sections'
import Logs from './sections/Logs'
import UserTasks from './sections/UserTasks'
import Description from './sections/Description'
import Location from './sections/Location'
import Medias from './sections/Medias'
import * as theme from './theme'
import { TAlarmReportProps } from './Provider'

const PageFooter = () => {
  const render = React.useCallback(
    ({
      pageNumber,
      totalPages,
    }) => `${pageNumber} / ${totalPages}`
  , [])

  return (
    <Text
      fixed
      style={styles.pageFooter}
      render={render}
    />
  )
}

export type TProps = TAlarmReportProps

export default (props: TProps) => {
  return (
    <Document>
      <Page style={styles.page} wrap>
        <Title {...props} />
        <Header {...props} />
        <Sections
          sectionProps={props}
          sections={[
            {
              title: 'alarm_report.description.title',
              Component: Description,
            },
            {
              title: 'alarm_report.location.title',
              Component: Location,
            },
            {
              title: 'alarm_report.medias.title',
              Component: Medias,
            },
            {
              title: 'alarm_report.logs.title',
              Component: Logs,
            },
            {
              title: 'alarm_report.user_tasks.title',
              Component: UserTasks,
            }
          ]}
        />
        <PageFooter />
      </Page>
      {(props.attachments.length > 0 ||
        (props.additionalDetails.videoUrls?.length ?? 0) > 0 ||
        (props.additionalDetails.imageUrls?.length ?? 0) > 0 ||
        (props.additionalDetails.audioUrls?.length ?? 0) > 0) && (
        <Page style={styles.page} wrap>
          <Title {...props} />
          <Attachments {...props} />
          <PageFooter />
        </Page>
      )}
    </Document>
  );
}

const FOOTER_FONTSIZE = theme.fontSize.text
const FOOTER_PADDING = theme.padding(4)

const styles = StyleSheet.create({
  page: {
    fontFamily: theme.fontFamily,
    paddingTop: theme.spacing(2),
    paddingBottom: (
      FOOTER_FONTSIZE +
      FOOTER_PADDING * 2
    ),
  },
  pageFooter: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: 'center',
    color: theme.colors.text,
    fontSize: FOOTER_FONTSIZE,
    lineHeight: 1,
    padding: FOOTER_PADDING,
  },
})