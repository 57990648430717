import React from 'react'
import {
  ReferenceField,
} from 'react-admin'
import {
  connect,
} from 'react-redux'
import CustomFields from '.'

const getCustomFieldSpecificProps = (type, property) => {
  switch (type) {
    case 'Image':
      return {
        source: property.referencedField || property.source,
      }
    default:
      return {
      }
  }
}

const CustomReferenceField = ({ name, property, entities, ...props }) => {
  const referencedEntityName = property.referenceTo?.split('/')?.pop()
  if (!referencedEntityName) return <span />
  const referencedEntity = entities[referencedEntityName]
  const referencedProperty =
    referencedEntity &&
    referencedEntity.list &&
    referencedEntity.list.properties &&
    referencedEntity.list.properties[property.referencedField || 'id']
      ? {
          ...referencedEntity.global.properties[
            property.referencedField || 'id'
          ],
          ...referencedEntity.list.properties[property.referencedField || 'id'],
        }
      : referencedEntity.global.properties[property.referencedField || 'id']
  return (
    <ReferenceField
      {...props}
      label={property.displayedName || name}
      reference={property.referenceTo}
    >
      {React.createElement(CustomFields[referencedProperty.type], {
        label: property.displayedName || name,
        name: property.referencedField || 'id',
        property,
        ...getCustomFieldSpecificProps(referencedProperty.type, property),
      })}
    </ReferenceField>
  )
}

const mapStateToProps = (state) => {
  return {
    entities: state.definitions.entities,
  }
}

export default connect(mapStateToProps, null)(CustomReferenceField)
