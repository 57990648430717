import Rete from '@obvious.tech/rete'

import {
    compareNodeOptions,
} from 'src/utils/flow'

import {
    sockets,
} from '../..'
import filterArraysSockets from '../../../../helpers/FilterArraySocket'
import SelectSocketForOutputControl from '../../Controls/Select/SelectSocketForOutputControl'

export default class ArrayCollectorComponent extends Rete.Component {
    constructor() {
        super('Array Collector')
        this.task = {
            outputs: {
                collectedArray: 'output',
            },
        }
        this.contextMenuPath = ['Arrays']
    }

    builder(node) {
        const out = new Rete.Output('collectedArray', 'Array', sockets.arraySocket)
        node.addOutput(out)

        const options = Object.keys(sockets)
            .filter(filterArraysSockets)
            .map((s) => ({
                value: s,
                label: s.split('Socket')[0],
            }))
            .sort(compareNodeOptions)

        const handleOutput = (name) => {
            node.outputs.forEach((o) => {
                const conns = [...o.connections]
                conns.forEach((connection) => {
                    this.editor.removeConnection(connection)
                })
                node.removeOutput(o)
            })

            node.update()
            this.editor.view.updateConnections({
                node,
            })

            if (name) {
                node.addOutput(
                    new Rete.Output(
                        'collectedArray',
                        name.split('Socket')[0],
                        sockets[name],
                    ),
                )
            }
        }

        node.addControl(
            new SelectSocketForOutputControl({
                emitter: this.editor,
                node,
                key: 'Type of Array',
                label: 'Type of Array',
                onChange: handleOutput,
                options,
                defaultValue: 'arraySocket',
                output: 'collectedArray',
            }),
        )

        return node
    }
}