// @ts-nocheck
import createRequest from './createRequest';

const ParseSwaggerPathResponses = ({
  responses = {},
  entity = 'unknown',
  fctName,
}) => {
  return Object.keys(responses).map((responseKey) => {
    const type = fctName === 'getAll' ? 'array' : 'object';

    return {
      name: responseKey,
      type,
      entity: responseKey < 400 ? entity : 'ErrorResponse',
    };
  });
};

const ParseSwaggerPathParameters = (parameters = []) =>
  parameters.map((parameter) => {
    return {
      name: parameter.name,
      type: parameter.type ? parameter.type : 'object',
      entity:
        parameter.name === 'entity' ||
        (!parameter.type && parameter.schema && parameter.schema['$ref'])
          ? parameter.schema['$ref'].substring(14)
          : undefined,
      required: !!parameter.required,
    };
  });

export default async (apiPromise, endpoint, fetch) => {
  let paths = {};
  const api = await apiPromise;
  Object.keys(api.paths).forEach((pathKey) => {
    Object.keys(api.paths[pathKey]).forEach((actionType) => {
      const operationId = api.paths[pathKey][actionType].operationId;
      const client = operationId.substring(0, operationId.indexOf('_'));
      if (!paths[client]) {
        paths[client] = {
          functions: {},
        };
      }
      const fctName = `${operationId[
        operationId.indexOf('_') + 1
      ].toLowerCase()}${operationId.substring(operationId.indexOf('_') + 2)}`;
      if (pathKey.split('/')[1] !== 'api') {
        paths[client].microservice = pathKey.split('/')[1];
        paths[client].functions = {
          ...paths[client].functions,
          [fctName]: {
            microservice: pathKey.split('/')[1],
            name: fctName,
            parameters: ParseSwaggerPathParameters(
              api.paths[pathKey][actionType].parameters
            ),
            responses: ParseSwaggerPathResponses({
              responses: api.paths[pathKey][actionType].responses,
              entity: client,
              fctName,
            }),
            call: (...args) => {
              const { url, options } = createRequest(
                actionType,
                endpoint,
                pathKey,
                api.paths[pathKey][actionType]
              )(...args);
              return fetch(url, options)
                .then((res) => {
                  return processResponse(res);
                })
                .catch(() => {});
            },
          },
        };
      } else {
        if (!paths.custom) paths.custom = {};
        const path = api.paths[pathKey][actionType];
        paths.custom[pathKey] = {
          ...((paths.custom && paths.custom[pathKey]) || {}),
          [fctName]: {
            name: fctName,
            responses: ParseSwaggerPathResponses({
              responses: path.responses,
              entity: pathKey,
              fctName,
            }),
            parameters: ParseSwaggerPathParameters(path.parameters),
            call: (...args) => {
              const { url, options } = createRequest(
                actionType,
                endpoint,
                pathKey,
                api.paths[pathKey][actionType]
              )(...args);
              return fetch(url, options)
                .then((res) => {
                  return processResponse(res);
                })
                .catch(() => {});
            },
          },
        };
      }
    });
  });
  return paths;
};

function processResponse(response) {
  return response.text().then(function (_responseText) {
    return JSON.parse(_responseText);
  });
}
