import React from 'react'
import {
  Map,
  TileLayer,
  MapProps,
  ScaleControl,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

type TProps = MapProps

export default ({ children, ...props }: TProps) => {
  return window._env_.OSM_ENDPOINT
    ? (
    <div
      style={{
        flex: 1,
      }}
    >
      <Map
        attributionControl={false}
        {...props}
      >
        <TileLayer
          url={window._env_.OSM_ENDPOINT}
          noWrap
        />
        <ScaleControl />
        {children}
      </Map>
    </div>
      )
    : null
}
