import React, {
  useState,
} from 'react'
import {
  connect,
} from 'react-redux'

import {
  Menu,
  MenuItem,
} from '@material-ui/core'
import {
  makeStyles,
} from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

import FlowToggleButton from './theme/components/flowToggleButton'
import flowClasses from './theme/classes'
import {
  showNewBlueprintDialog,
} from '../../../src/redux/editor/editor.actions'

import ListOfBlueprints from './ListOfBlueprints'

const useStyles = makeStyles((theme) => ({
  addMenuButton: {
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: '5px',
    backgroundColor: 'white',
    padding: '2px',
    marginRight: '10px',
    fontSize: '15pt',
    color: theme.palette.text.primary,
    boxShadow: '0px 0px 2px 1px rgba(0,0,0,0.3)',
    '&:hover': {
      backgroundColor: '#66d4f1',
      color: 'white',
    },
    '&.selected': {
      backgroundColor: '#3aadd9',
      color: 'white',
    },
  },
  addMenuList: {
    marginTop: theme.spacing(1),
  },
  flowMenuItem: flowClasses.flowMenuItem,
}))

const AddMenu = (props) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [listAnchorEl, setListAnchorEl] = useState(null)
  const [showList, setShowList] = useState(false)

  const handleClick = (event, stateChanger) => {
    stateChanger(event.currentTarget)
  }
  const handleClose = (stateChanger) => {
    stateChanger(null)
  }

  return (
    <div>
      <FlowToggleButton
        aria-controls="add-menu"
        aria-haspopup="true"
        onClick={(e) => {
          handleClick(e, setAnchorEl)
        }}
        additionalStyles={`${classes.addMenuButton} ${
          anchorEl ? 'selected' : ''
        }`}
      >
        <AddIcon />
      </FlowToggleButton>
      <Menu
        MenuListProps={{
          ref: (r) => setListAnchorEl(r),
        }}
        classes={{
          paper: classes.addMenuList,
        }}
        id="add-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          handleClose(setAnchorEl)
        }}
      >
        <MenuItem
          className={classes.flowMenuItem}
          onClick={(e) => {
            setShowList(true)
          }}
        >
          Open Blueprint
        </MenuItem>

        <MenuItem
          className={classes.flowMenuItem}
          onClick={() => {
            props.showNewBlueprintDialog()
            handleClose(setAnchorEl)
          }}
        >
          New Blueprint
        </MenuItem>
      </Menu>
      <ListOfBlueprints
        open={showList}
        listAnchorEl={listAnchorEl}
        close={() => {
          handleClose(setListAnchorEl)
          handleClose(setAnchorEl)
          setShowList(false)
        }}
        addTab={props.addTab}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    showNewBlueprintDialog: () => {
      dispatch(showNewBlueprintDialog())
    },
  }
}

export default connect(null, mapDispatchToProps)(AddMenu)
