import React from 'react'
import Chip from '@material-ui/core/Chip'

const CustomArrayField = ({ property, name, record, ...rest }) => {
  if (!property.items.type) return <span />
  return (
    <div style={{
      display: 'flex',
    }}>
      {record &&
        record[name] &&
        record[name].map((item) => (
          <Chip key={item} label={item} size="small" />
        ))}
    </div>
  )
}

CustomArrayField.defaultProps = {
  addLabel: true,
}

export default CustomArrayField
