import React from 'react'
import {
  Divider as MuiDivider,
} from '@material-ui/core'

const Divider = ({ classes }) => (
  <MuiDivider orientation="vertical" flexItem classes={classes} />
)

export default Divider
