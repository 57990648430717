import React from 'react'

import SubComponents from 'src/components/DropdownMenu/SubComponents'
import {
  UserMenuContext,
} from './'

export default function UserMenuOptions ({ children }) {
  const { setOptions } = React.useContext(UserMenuContext)
  React.useEffect(() => {
    setOptions(children)
    return () => {
      setOptions([])
    }
  }, [children, setOptions])

  return null
}

Object.assign(UserMenuOptions, SubComponents)
