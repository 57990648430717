import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { usePDF } from '@react-pdf/renderer'
import { Loading } from 'react-admin'
import { useTranslation } from 'react-i18next'
import qs from 'query-string'
import { Box, IconButton, Typography, Tooltip, CircularProgress, SvgIcon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Alert, AlertTitle } from '@material-ui/lab'

import Preview from './Preview/Preview'
import Provider from './Preview/Provider'
import useDownloadZip from './UserMenu/useDownloadZip'
import api from 'src/utils/api'
import { IAlarm } from '@obvious.tech/constellation'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#323639',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#424649',
    },
  },
}))

const AlarmPDFViewer = (props) => {
  const [pdf, ] = usePDF({
    document: <Preview {...props} mode={'download'} />
  })
  const [downloadZip, loading] = useDownloadZip({ ...props, pdf })
  const classes = useStyles()

  if (pdf.loading || pdf.error !== null || pdf.blob === null)
    return null

  const src = pdf.url
    ? `${pdf.url}#toolbar=1`
    : undefined

  return (
    <Box display="flex" flex={1} position="relative">
      <Box position="absolute" bottom="20px" right="40px">
        <Tooltip title="Download ZIP">
          {loading ? (
            <CircularProgress style={{ color: 'white' }} />
          ) : (
            <IconButton
              classes={classes}
              size="medium"
              // @ts-ignore
              onClick={downloadZip}
              aria-label="upload picture"
              component="span"
            >
              <SvgIcon htmlColor="white">
                <path d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-2 6h-2v2h2v2h-2v2h-2v-2h2v-2h-2v-2h2v-2h-2V8h2v2h2v2z"></path>
              </SvgIcon>
            </IconButton>
          )}
        </Tooltip>
      </Box>
      <iframe
        src={src}
        title={props.title}
        style={{ flex: 1, height: '100vh' }}
      />
    </Box>
  )
}

const getAlarmByID = api.getResourceByID<IAlarm>('alarms/api/Alarm')

const ExternalAlarmPDF = () => {
  const { id } = useParams<Record<string, string>>()
  const { i18n } = useTranslation()
  const location = useLocation()
  const [alarm, getAlarm] = api.useApiAction(getAlarmByID)
  const { authorization, lang } = qs.parse(location.search)
  const browserLanguage = navigator.language
  useEffect(() => {
    if (typeof authorization !== 'string')
      return

    const language = typeof lang === 'string' && /^(fr|en)(-.*)?/i.test(lang)
    ? lang.toLowerCase().split('-')
    : browserLanguage

    if (language.includes('fr')) i18n.changeLanguage('fr')
    else i18n.changeLanguage('en')

    return getAlarm({
      id,
      token: authorization,
    })
  }, [location.search, id, authorization, i18n, lang, browserLanguage, getAlarm])

  if (alarm.error !== null || typeof authorization !== 'string') {
    return (
      <Alert severity="error">
        <AlertTitle>Failed to fetch alarm.</AlertTitle>
        <Typography>{alarm.error?.message ?? 'Invalid authorization'}</Typography>
      </Alert>
    )
  }

  if (!alarm.loaded) {
    return <Loading loadingPrimary="Fetching Alarm..." loadingSecondary="" />
  }

  return (
    <Provider
      authorization={authorization}
      alarm={alarm.data}
    >
      <AlarmPDFViewer />
    </Provider>
  )
}

export default ExternalAlarmPDF
