import React from 'react';
import { Node } from 'rete-react-render-plugin';
import { withStyles, Box, Tooltip, Typography } from '@material-ui/core';

import { withThemeProvider } from 'src/utils/hocs';
import nodeStyles from './nodeStyles';
import { defaultClasses } from './styles';
import flowClasses from '../../../theme/classes';
import UsualInputComponent from './UsualInputComponent';
import OutputsWithDecomposeButton from './OutputsWithDecomposeButton';
import Control from 'src/components/flow/Control';

const styles = (theme) => ({
  ...defaultClasses,
  nodeHeader: {
    boxShadow: '0px -0.5px 0px 0.5px rgba(255,255,255,1)',
    borderRadius: '4px 4px 0 0',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  flowNodeTitle: flowClasses.flowNodeTitle,
});

class DefaultNode extends Node {
  hasIssues = (type) => {
    const { validation } = this.props.node;
    if (!validation?.[type]) return false;

    const issues = Object.values(validation[type]).find((issue) => issue);

    return issues;
  };

  renderInputIssues = (type) => {
    if (!type) return null;

    const { validation } = this.props.node;
    if (!validation?.[type]) return null;

    const inputIssue = Object.values(validation[type]).filter((issue) => issue);

    return (
      <Box display="flex" flexDirection="column">
        {inputIssue.map((issue) => (
          <Box key={issue} mb="2px">
            <Typography variant="caption">{issue}</Typography>
          </Box>
        ))}
      </Box>
    );
  };

  handleTooltipTitle = ({ isActive, hasWarns, hasError }) => {
    const inactiveMessage = 'Node is not active.';
    const errorMessage = 'Node has errors.';

    if (hasError)
      return (
        <Box>
          {!isActive && (
            <Typography variant="caption" align="center" display="block">
              {inactiveMessage}
            </Typography>
          )}
          <Typography variant="caption" align="center" display="block">
            {errorMessage}
          </Typography>
          {this.renderInputIssues('errors')}
          {this.renderInputIssues('warnings')}
        </Box>
      );

    if (hasWarns)
      return (
        <Box>
          {!isActive && (
            <Typography variant="caption" align="center" display="block">
              {inactiveMessage}
            </Typography>
          )}
          {this.renderInputIssues('warnings')}
        </Box>
      );

    return '';
  };

  render() {
    const {
      node,
      bindSocket,
      bindControl,
      classes,
      type = 'default',
      InputBlockComponent = UsualInputComponent,
    } = this.props;
    const { outputs, controls, inputs, selected } = this.state;

    const isActive = node.validation ? node.validation.isActive : true;
    const hasWarns = !isActive || this.hasIssues('warnings');
    const hasError = this.hasIssues('errors');

    return (
      <Tooltip
        title={this.handleTooltipTitle({ isActive, hasWarns, hasError })}
        arrow
        data-id={node.id}
        placement="top"
      >
        <Box className={`node ${selected} node-${node.id}`}>
          <Box
            className={`node-hover  ${hasWarns && 'node-inactive'} 
        ${hasError && 'node-error'}`}
          >
            <Box className="node-paper">
              <Box
                pb={2}
                bgcolor={nodeStyles.nodes[type].color}
                className={`node-main node-${type}`}
              >
                <Box
                  className={classes.nodeHeader}
                  bgcolor={nodeStyles.nodes[type].contrastColor}
                  color={nodeStyles.nodes[type].color}
                  textAlign="center"
                >
                  <span
                    className={`${classes.flowNodeTitle} node-title-${type}`}
                  >
                    {node.name}
                  </span>
                </Box>

                {/* Outputs */}
                <Box px={2} className={`node-socket-block-${type}`}>
                  <OutputsWithDecomposeButton
                    outputs={outputs}
                    node={node}
                    bindSocket={bindSocket}
                    bindControl={bindControl}
                  />
                </Box>
                {/* Controls */}
                {controls.map((control, i) => (
                  <Control
                    key={i}
                    className="control"
                    control={control}
                    bindControl={bindControl}
                  />
                ))}
                {/* Inputs */}
                <Box px={2} className={`node-socket-block-${type}`}>
                  <InputBlockComponent
                    inputs={inputs}
                    node={node}
                    bindSocket={bindSocket}
                    bindControl={bindControl}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Tooltip>
    );
  }
}

export default withThemeProvider(withStyles(styles)(DefaultNode));