import Action from '../action';

function reassign(connection) {
  const { input, output } = connection;

  return output.connections.find((c) => c.input === input);
}

class ConnectionAction extends Action {
  constructor(editor, connection) {
    super(editor);
    this.editor = editor;
    this.connection = connection;
  }

  removeConnectionView() {
    const { input, output } = this.connection;
    for (const connection of this.editor.view.connections) {
      const [key, value] = connection;

      const { input: inputView, output: outputView } = key;

      // check if the connection view wasn't removed from DOM
      if (
        inputView.key !== input.key ||
        inputView.node.id !== input.node.id ||
        outputView.key !== output.key ||
        outputView.node.id !== output.node.id
      )
        continue;

      try {
        value.el.style.display = 'none';
      } catch {
        console.error("couldn't find DOM connection element");
      }
      break;
    }
  }
}

export class AddConnectionAction extends ConnectionAction {
  constructor(editor, connection) {
    super(editor, connection);
  }

  undo() {
    if (!this.isExecutableConnectionAction(this.connection)) return;
    this.editor.removeConnection(this.connection);
    this.removeConnectionView();
  }

  redo() {
    if (!this.isExecutableConnectionAction(this.connection)) return;
    this.editor.connect(this.connection.output, this.connection.input);
    this.connection = reassign(this.connection);
  }
}

export class RemoveConnectionAction extends ConnectionAction {
  constructor(editor, connection) {
    super(editor, connection);
  }

  undo() {
    if (!this.isExecutableConnectionAction(this.connection)) return;
    this.editor.connect(this.connection.output, this.connection.input);
    this.connection = reassign(this.connection);
  }
  redo() {
    if (!this.isExecutableConnectionAction(this.connection)) return;
    this.editor.removeConnection(this.connection);
    this.removeConnectionView();
  }
}
