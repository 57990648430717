// @ts-nocheck
import BoxSelection from './BoxSelection'
import PanEditor, {
  ITranslateEvent,
} from './PanEditor'

type IProps = ConstructorParameters<typeof PanEditor>[0] & {
  boxSelection: BoxSelection
}

export default class BoxSelectionPan extends PanEditor {
  boxSelection: BoxSelection

  translateInterval: number | undefined

  constructor (props: IProps) {
    const { boxSelection, ...rest } = props
    super(rest)
    this.boxSelection = boxSelection
  }

  onTranslate ({ mouse, delta, translated }: ITranslateEvent) {
    this.editor.view.area.translate(translated.x, translated.y)
    this.boxSelection.addSelectionRectStyles(mouse.x, mouse.y)
    this.boxSelection.handleNodesSelection()

    if (window.isNullish(this.container)) return

    const { startPosition } = this.boxSelection
    startPosition.x -= delta.x
    startPosition.y -= delta.y
  }
}
