import * as constants from './api.constants'

export const initialState = {
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_API:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
