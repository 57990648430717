import React from 'react'
import {
  makeStyles,
} from '@material-ui/core'
import {
  DatePicker,
} from '@material-ui/pickers'

import {
  IInputProps,
} from 'src/types'

const useStyles = makeStyles((theme: any) => ({
  datePicker: {
    ...theme.inputs.textfield,
    margin: 0,
    padding: 0,
    marginBottom: theme.spacing(1),
  },
}))

const DateTimeInput = ({
  property,
  value,
  hasError,
  errorMsg,
  onChange,
}: IInputProps<Date>) => {
  const classes = useStyles()

  const inputValue = value || null

  return (
    <DatePicker
      fullWidth
      className={classes.datePicker}
      error={hasError}
      helperText={errorMsg}
      value={inputValue}
      openTo="year"
      disableFuture
      format="MM/dd/yyyy"
      views={['year', 'month', 'date']}
      label={property.displayedName}
      onChange={(e) => {
        onChange(e.toUTCString())
      }}
    />
  )
}

export default DateTimeInput
