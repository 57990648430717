import {
  editor,
} from '../components/Flow'

const isReqDefaultErrorMsg = 'connection or input value is required.'

const notEmptyInputCheck = (value) => {
  if (value === undefined) return false

  if (typeof value === 'string') {
    return value.trim() !== ''
  }

  return true
}

const validateSocket = (socket, errorMsg) => {
  const isConnected = socket.connections.length

  const hasValue = notEmptyInputCheck(socket.control?.props.value)

  if (isConnected || hasValue) return ''

  return `${socket.name} - ${errorMsg}`
}

const validateControl = (control, errorMsg) => {
  const hasValue = notEmptyInputCheck(control?.props.value)

  if (hasValue) return ''

  return `${control.props.label} - ${errorMsg}`
}

export const isRequired = (errorMsg = isReqDefaultErrorMsg) => (key, node) => {
  const socket = node.inputs.get(key) || node.outputs.get(key)
  if (socket) return validateSocket(socket, errorMsg)

  const control = node.controls.get(key)
  if (control) return validateControl(control, errorMsg)

  return ''
}

export const isNodeActive = (node) => {
  const inputs = node.inputs.values()
  const outputs = node.outputs.values()

  let isConnectedInput = false
  let isConnectedOutput = false

  for (const input of inputs) {
    if (!input.connections.length) continue

    isConnectedInput = true
  }

  for (const output of outputs) {
    if (!output.connections.length) continue

    isConnectedOutput = true
  }

  return isConnectedInput || isConnectedOutput
}

export const validate = (node) => {
  const nodeComponent = editor.components.get(node.name)

  if (!nodeComponent?.validation) return validateIsActive(node)

  const copyNode = {
    ...node,
    validation: nodeComponent.validation,
  }

  const validateRules = (rules) => {
    if (!copyNode.validation[rules]) {
      return {
      }
    }

    return Object.entries(copyNode.validation[rules]).reduce(
      (acc, [key, rules]) => {
        const errors = rules.map((rule) => rule(key, copyNode))
        if (!errors?.[0]) return acc

        return {
          ...acc,
          [key]: errors[0],
        }
      },
      {
      },
    )
  }

  const errors = validateRules('errorRules')
  const warnings = validateRules('warnRules')

  return {
    errors,
    warnings,
    isActive: isNodeActive(copyNode),
  }
}

export const validateIsActive = (node) => {
  const nodeComponent = editor.components.get(node.name)
  if (!nodeComponent) return

  const copyNode = {
    ...node,
    validation: nodeComponent.validation
      ? {
          ...nodeComponent.validation,
        }
      : {
        },
  }

  return {
    ...copyNode.validation,
    isActive: isNodeActive(copyNode),
  }
}

export const validateTouched = (node) => {
  const copyNode = {
    ...node,
    validation: node.validation
      ? {
          ...node.validation,
        }
      : {
        },
  }

  if (copyNode.validation?.invalid) {
    return {
      ...copyNode.validation,
      ...validate(copyNode),
    }
  }

  return validateIsActive(copyNode)
}
