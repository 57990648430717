import React from 'react'
import Chip from '@material-ui/core/Chip'
import {
  useQuery,
} from 'react-admin'
import {
  CircularProgress,
} from '@material-ui/core'
import {
  makeStyles,
} from '@material-ui/styles'

import TApi from 'src/types/api'

// eslint-disable-next-line
const passportReg = /\/passport\/[^\/]+\//;

const useClasses = makeStyles({
  chipsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  chip: {
    '&:not(:last-child)': {
      marginRight: '0.5em',
    },
  },
})

const PassportGroupChips = ({ ids }: { ids: string[] }) => {
  const classes = useClasses()
  const apiPassports = useQuery({
    resource: '/authentication/api/Passport',
    type: 'getMany',
    payload: {
      ids,
    },
  })

  if (apiPassports.loading) {
    return (
      <CircularProgress
        classes={{
          root: classes.chip,
        }}
        size="1.5em"
      />
    )
  }
  const passports = apiPassports?.data as TApi.Entity.Passport[]

  return (
    <>
      {ids.map((id) => {
        const passport = passports.find((passport) => passport.id === id)
        const group = `/passport/${id}/`
        const username = passport?.username ?? group

        return (
          <Chip
            className={classes.chip}
            key={group}
            label={username}
            size="small"
          />
        )
      })}
    </>
  )
}

const GroupsField = ({ property, name, record, ...rest }) => {
  const classes = useClasses()

  const maybePassportGroups: string[] = record?.[name] ?? []
  const passportIds = maybePassportGroups
    .filter((group) => passportReg.test(group))
    .map((group) => group.split('/')[2])
  const groups = maybePassportGroups.filter(
    (group) => !passportReg.test(group),
  )

  return (
    <div className={classes.chipsContainer}>
      {groups.map((group) => {
        return (
          <Chip
            className={classes.chip}
            key={group}
            label={group}
            size="small"
          />
        )
      })}
      {passportIds.length > 0 && <PassportGroupChips ids={passportIds} />}
    </div>
  )
}

export default GroupsField
