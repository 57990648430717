// @ts-nocheck
import React from 'react'
import {
  makeStyles,
} from '@material-ui/styles'
import {
  IconButton,
  SvgIcon,
  Box,
  Typography,
  Theme,
} from '@material-ui/core'
import {
  useLogout,
  useSetLocale,
} from 'react-admin'
import ToggleButton from '@material-ui/lab/ToggleButton'
import {
  useTranslation,
} from 'react-i18next'

import DropdownMenu from 'src/components/DropdownMenu'
import {
  UserMenuContext,
} from 'src/components/UserMenu'
import DropdownMenuSubComponents from 'src/components/DropdownMenu/SubComponents'

import {
  Menu as MenuIcon,
} from '@material-ui/icons'
import {
  ReactComponent as FranceFlagIcon,
} from 'src/assets/flags/france.svg'
import {
  ReactComponent as GreatBritainFlagIcon,
} from 'src/assets/flags/great-britain.svg'

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    borderRadius: '3px',
    fontSize: 15,
    padding: theme.spacing(1),
    border: 'none',
    '&.Mui-selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
  },
  menuButtonIcon: {
    color: theme.palette.primary.contrastText,
  },
  menuTitle: {
    justifyContent: 'center',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  dropdown: {
    paddingTop: 0,
    '& > li:nth-of-type(1)': {
      marginTop: theme.spacing() * 1,
    },
  },
  flagsContainer: {
    marginLeft: '-12px',
    marginRight: '-12px',
  },
  languageBtn: {
    transition: `all ${theme.transitions.duration.short}ms`,
    transform: 'scale(0.8)',
    filter: 'brightness(90%)',
  },
  activeLangBtn: {
    transform: 'scale(1.2)',
    filter: 'brightness(100%)',
  },
}))

const { Item, Divider } = DropdownMenuSubComponents

const UserMenu = (): JSX.Element => {
  const { options } = React.useContext(UserMenuContext)
  const logout = useLogout()
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const changeLocale = useSetLocale()

  const [menuOpen, setMenuOpen] = React.useState(false)

  const getLocaleSetter = (language: string) => async () => {
    await i18n.changeLanguage(language)
    await changeLocale(language)
    localStorage.setItem('language', language)
  }

  const onClose = (): void => {
    setMenuOpen(false)
  }

  const handleLangButtonClassnames = (lang: 'en' | 'fr'): string => {
    const { language } = i18n

    return language === lang
      ? `${classes.languageBtn} ${classes.activeLangBtn}`
      : classes.languageBtn
  }

  return (
    <div>
      <ToggleButton
        value={menuOpen}
        selected={menuOpen}
        className={classes.menuButton}
        onClick={() => setMenuOpen(true)}
      >
        <MenuIcon className={classes.menuButtonIcon} />
      </ToggleButton>
      <div id="react-admin-usermenu" />
      <DropdownMenu
        id="app-menu"
        container={menuOpen ? 'react-admin-usermenu' : null}
        onClose={onClose}
        className={classes.dropdown}
      >
        <Item className={classes.menuTitle} closeMenu={onClose}>
          <Box textAlign="center" py={2}>
            <Typography variant="h6">{t('menu.menu')}</Typography>
          </Box>
        </Item>
        {React.Children.map(
          options,
          (child) =>
            !window.isNullish(child) &&
            React.cloneElement(child, {
              closeMenu: onClose,
            }),
        )}
        {!window.isNullish(React.Children.count(options)) && <Divider />}
        <Item title={t('menu.language')} closeMenu={onClose}>
          <div className={classes.flagsContainer}>
            <IconButton
              onClick={getLocaleSetter('fr')}
              className={handleLangButtonClassnames('fr')}
            >
              <SvgIcon
                component={FranceFlagIcon}
                viewBox="0 85.33 512 341.337"
              />
            </IconButton>
            <IconButton
              onClick={getLocaleSetter('en')}
              className={handleLangButtonClassnames('en')}
            >
              <SvgIcon
                component={GreatBritainFlagIcon}
                viewBox="0 85.33 512 341.337"
              />
            </IconButton>
          </div>
        </Item>
        <Divider />
        <Item title={t('menu.logout')} closeMenu={onClose} onClick={logout} />
      </DropdownMenu>
    </div>
  )
}

export default UserMenu
