// @ts-nocheck
import {
  makeStyles,
  Theme,
} from '@material-ui/core'
import React from 'react'
import {
  Record,
  ToolbarProps,
} from 'react-admin'

import Toolbar from 'src/adminPanel/screens/Actions/FormToolbar/FormToolbar'
import {
  DeleteWithConfirmButton,
} from 'src/components/Buttons'

const useStyles = makeStyles((theme: Theme) => ({
  deleteBtn: {
    margin: `0 ${theme.spacing(1)}px`,
  },
}))

interface AuthorizationRecord extends Record {
  name?: string
  username?: string
}
interface IAuthorizationToolbarProps extends ToolbarProps<AuthorizationRecord> {
  recordNameField: 'username' | 'name'
}

const DEFAULT_CONFIRM_TITLE = 'ra.message.delete_title'

const EditAuthorizationToolbar = ({
  recordNameField,
  ...props
}: IAuthorizationToolbarProps): JSX.Element => {
  const classes = useStyles()

  const recordName = props.record?.[recordNameField]
  const displayedTitle = !window.isNullish(recordName)
    ? `Delete ${recordName}`
    : DEFAULT_CONFIRM_TITLE

  return (
    <Toolbar {...props} withDelete={false}>
      <DeleteWithConfirmButton
        notificationTimeout={5000}
        confirmTitle={displayedTitle}
        size="medium"
        className={classes.deleteBtn}
      />
    </Toolbar>
  )
}

export default EditAuthorizationToolbar
