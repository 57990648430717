// @ts-nocheck
import React, {
  useState,
} from 'react'
import {
  useFormState,
} from 'react-final-form'
import {
  SaveButton,
  useNotify,
  useRedirect,
  Toolbar,
  DeleteButton,
  Identifier,
  ToolbarProps,
} from 'react-admin'
import isEqual from 'lodash.isequal'
import {
  makeStyles,
} from '@material-ui/core'

import useErrorFetch from 'src/hooks/useErrorFetch'

const styles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
  },
}))

interface INotificationType {
  id: Identifier
  imageFile: File | null
  soundFile: File | null
  name: string
  color: string
  groups: string[]
}

type TNotificationTypeValues = Omit<
INotificationType,
'imageFile' | 'soundFile'
>

interface IUpdateNotificationType extends TNotificationTypeValues {
  imageFile?: null
  soundFile?: null
}
interface IProps extends ToolbarProps {
  invalid: boolean
  resource: string
  record: INotificationType
  basePath: string
}

interface IOptions {
  body: string | FormData
  method: string
  headers: {
    [key: string]: string
  }
}

const EditNotificationTypeToolbar = ({
  width,
  ...props
}: IProps): JSX.Element => {
  const { invalid, resource, record, basePath } = props

  const fetchError = useErrorFetch()
  const classes = styles()
  const notify = useNotify()
  const redirect = useRedirect()
  const formState = useFormState<INotificationType>()

  const [loading, setLoading] = useState(false)

  const handleSendRequest = async (
    url: string,
    options: IOptions,
  ): Promise<void> => {
    return await fetch(url, options).then(async (res) => {
      if (res.ok) return await res.json()

      const error = await res.json()
      let errorMessage = error.errorMessage

      try {
        const errorMessages: string[][] = Object.values(
          JSON.parse(JSON.parse(error.errorMessage)),
        )
        const invalidDataMessages: string[] = errorMessages
          .filter((errorArr) => errorArr?.length)
          .map((err) => err[0])

        errorMessage =
          invalidDataMessages?.length > 0
            ? invalidDataMessages[0]
            : errorMessage
      } catch {}

      throw new Error(errorMessage)
    })
  }

  const handleUpdateNotificationType = async (
    record: IUpdateNotificationType,
  ): Promise<void> => {
    const url = `${window._env_.API_URL}/${resource}/${record.id}`
    const options = {
      method: 'PATCH',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json-patch+json',
        authorization: localStorage.getItem('token') ?? '',
      },
      body: JSON.stringify(record),
    }

    return await handleSendRequest(url, options)
  }

  const pickValues = ({
    soundFile,
    imageFile,
    ...record
  }: INotificationType): TNotificationTypeValues => record

  const pickFile = (
    record: INotificationType,
    fileType: 'soundFile' | 'imageFile',
  ): File | null => record[fileType]

  const handleUpdateFile = async (
    record: INotificationType,
    fileType: 'image' | 'sound',
  ): Promise<void> => {
    const recordFileName = `${fileType}File`
    const file = record?.[recordFileName]?.rawFile

    if (window.isNullish(file)) {
      return
    }

    const formDataFile = new FormData()
    formDataFile.append('file', file)

    const url = `${window._env_.API_URL}/${resource}/${record.id}/${fileType}/upload`
    const options = {
      method: 'POST',
      headers: {
        authorization: localStorage.getItem('token') ?? '',
      },
      body: formDataFile,
    }

    return await handleSendRequest(url, options)
  }

  const handleSubmit = async (): Promise<void> => {
    setLoading(true)
    const editedRecord = {
      ...formState.values,
    }
    const editedRecordValues: IUpdateNotificationType =
      pickValues(editedRecord)

    const editedRecordSound = pickFile(editedRecord, 'imageFile')
    const editedRecordImage = pickFile(editedRecord, 'soundFile')

    const hasImageChanged = !isEqual(
      editedRecordSound,
      pickFile(record, 'imageFile'),
    )
    const hasSoundChanged = !isEqual(
      editedRecordImage,
      pickFile(record, 'soundFile'),
    )

    try {
      const updatePromises: Array<Promise<void>> = []

      if (hasImageChanged) {
        window.isNullish(editedRecordSound)
          ? (editedRecordValues.imageFile = null)
          : updatePromises.push(handleUpdateFile(editedRecord, 'image'))
      }

      if (hasSoundChanged) {
        window.isNullish(editedRecordImage)
          ? (editedRecordValues.soundFile = null)
          : updatePromises.push(handleUpdateFile(editedRecord, 'sound'))
      }

      updatePromises.push(handleUpdateNotificationType(editedRecordValues))

      await Promise.all(updatePromises)

      redirect(basePath)
      notify('NotificationType updated.', 'info', {
        smart_count: 1,
      })
    } catch (e) {
      await fetchError(e)
      setLoading(false)
    }
  }

  return (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton
        label="Save"
        disabled={invalid || loading}
        handleSubmitWithRedirect={handleSubmit}
      />

      <DeleteButton />
    </Toolbar>
  )
}

export default EditNotificationTypeToolbar
