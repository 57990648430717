import React, { useEffect } from 'react'
import { Loading, Title } from 'react-admin'
import { Alert, AlertTitle } from '@material-ui/lab'
import { usePDF } from '@react-pdf/renderer'

import Preview from './Preview/Preview'
import Provider from './Preview/Provider'
import CustomTitle from 'src/adminPanel/screens/Title/Title'
import { UserMenuOptions as UMO } from 'src/components/UserMenu'
import DownloadZip from './UserMenu/DownloadZip'
import api from 'src/utils/api'
import { Typography } from '@material-ui/core'
import { IAlarm } from '@obvious.tech/constellation'

const AlarmPDFViewer = (props) => {
  const [pdf, ] = usePDF({
    document: <Preview {...props} mode={'download'} />
  })

  if (pdf.loading || pdf.error !== null || pdf.blob === null)
    return null

  const src = pdf.url
    ? `${pdf.url}#toolbar=1`
    : undefined

  return (
    <>
      <UMO>
        <DownloadZip
          {...{
            ...props,
            pdf,
          }}
        />
      </UMO>
      <iframe
        src={src}
        title={props.title}
        style={{ flex: 1, height: '100%' }}
      />
    </>
  )
}

const getAlarmByID = api.getResourceByID<IAlarm>('alarms/api/Alarm')

const App = ({ id, entity }) => {
  const [alarm, getAlarm] = api.useApiAction(getAlarmByID)
  
  useEffect(() => {    
    return getAlarm({
      id,
    })
  }, [id, getAlarm])

  if (alarm.error !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>Failed to fetch alarm.</AlertTitle>
        <Typography>{alarm.error.message}</Typography>
      </Alert>
    )
  }

  if (!alarm.loaded) {
    return <Loading loadingPrimary="Fetching Alarm..." loadingSecondary="" />
  }

  return (
    <>
      <Title
        title={
          <CustomTitle
            record={undefined}
            entity={entity}
          />
        }
      />
      <Provider
        alarm={alarm.data}
        authorization={undefined}
      >
        <AlarmPDFViewer />
      </Provider>
    </>
  )
}

export default React.memo(App)
