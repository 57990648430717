import { TEntityOverride } from 'src/types/index';

import AlarmToPdf from './AlarmToPdf';

const override: TEntityOverride = {
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
    },
  },
  create: {
    hidden: true,
  },
  edit: {
    displayedComponent: AlarmToPdf,
  },
};

export default override;