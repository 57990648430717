// Current shadows are buggy, set size to 0;
export const SHADOW_SETTINGS = {
  SHADOW_SIZE: 0,
  SHADOW_UNIT: 'px',
}

/*
  TO-DO:
  These constants shouldn't be constants.
  They should be retrieved from the DOM.
  Related bug: When zooming in-out the window, the shadows are misplaced.
*/

export const SIZES = {
  TABLE_HEADER_HEIGHT: 36.38,
  TABLE_FIXED_COL_HEIGHT: 52,
  TABLE_FOOTER_HEIGHT: 52,
}

export const scrollShadowStyle = {
  content: '""',
  position: 'absolute',
  pointerEvents: 'none',
  zIndex: 4,
}
