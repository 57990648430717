import React from 'react'
import { TAlarmReportProps } from './Provider'

import Section from './Section'
import * as theme from './theme'

export type TContentProps = {
  bgMain: string,
  bgSecondary: string,
} & TAlarmReportProps

export type TEvenOddProps = {
  bgMain: string,
  bgSecondary: string,
}

export type TSectionContent = (props: TContentProps) => JSX.Element

export type TProps = {
  sections: Array<{
    title: string,
    Component: TSectionContent,
  }>,
  sectionProps: Omit<TContentProps, keyof TEvenOddProps>,
}

export default ({ sections, sectionProps }: TProps) => {
  return (
    <>
      {sections.map(
        (({ title, Component }, index) => {
          const odd = index % 2 === 1
          const bgMain = odd
            ? theme.colors.sectionOddBg
            : theme.colors.sectionOddSecondaryBg
          const bgSecondary = odd
            ? theme.colors.sectionEvenBg
            : theme.colors.sectionEvenSecondaryBg

          return (
            <Section
              key={title}
              title={title}
              bgMain={bgMain}
            >
              <Component
                {...{
                  ...sectionProps,
                  bgMain,
                  bgSecondary,
                }}
              />
            </Section>
          )
        })
      )}
    </>
  )
}