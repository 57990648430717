// @ts-nocheck
import CustomMarker from 'src/components/Map/Marker';
import React, { useState, useEffect, useRef } from 'react';
import { Map, TileLayer, withLeaflet, Circle, Polyline } from 'react-leaflet';
import PrintControlDefault from 'src/utils/leaflet-easyprint/react-leaflet-easyprint';

const MAP_HEIGHT = 400
const MAP_WIDTH = 1000

const PrintControl = withLeaflet(PrintControlDefault);

export default ({
  alarm,
  loading: currentLoading,
  circles = [],
  polylines = [],
  children,
  ...rest
}) => {
  let { location } = alarm;
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(!location ? 'Ready' : null);
  const printEl = useRef(null);

  alarm.additionalDetails.forEach(detail => {
    if (detail.type === 'Circle') circles.push(detail.data)
    if (detail.type === 'Polyline') polylines.push(detail.data)
  })
  
  const transformCoords = (polyline) => {
    const adaptedCoords = []
    polyline.points.map(coords => adaptedCoords.push([coords.lat, coords.lon]))
    return adaptedCoords
  }

  /*
  ** All this is pretty ugly, but looks like leaflet doesn't have a proper defined way to know when all in-view map tiles are loaded.
  */
  useEffect(() => {
    if (location && printEl && loading === 'MapLoaded') {
      const getMapSnapshot = async () => {
        const onFinished = (e) => {
          setMap(e.event);
          setLoading('Ready');
        };
        const onError = (e) => {
          console.error(e);
          setLoading('Ready');
        }

        const map = printEl.current.leafletElement._map;

        map.addEventListener('easyPrint-finished', onFinished);
        map.addEventListener('easyPrint-error', onError);

        printEl.current.printMap('A4Landscape', 'MyFileName');

        return () => {
          map.removeEventListener('easyPrint-finished', onFinished);
          map.removeEventListener('easyPrint-error', onError);
        };

      };
      getMapSnapshot();
    }
  }, [printEl, loading, location]);

  const renderPDFContent = () => (
    <div
        style={{
          position: 'absolute',
          /** renders the Map outside of the parent element (hidden) */
          top: -MAP_HEIGHT,
        }}
      >
        <Map
          center={[location.latitude, location.longitude]}
          fadeAnimation={false} // needed so that pdf doesn't include faded tiles
          zoom={15}
          style={{
            height: MAP_HEIGHT,
            width: MAP_WIDTH,
          }}
        >
          {window._env_.OSM_ENDPOINT && (
            <>
              <TileLayer
                url={window._env_.OSM_ENDPOINT}
                onload={() => setLoading('MapLoaded')}
              />
              <CustomMarker coordinates={location} />
              {polylines.map((points) => (
                <Polyline positions={transformCoords(points)} key={polylines.indexOf(points)}/>
              ))}
              {circles.map((circle) => (
                <Circle
                  radius={circle.radius}
                  color={circle.color}
                  fill={circle.filled}
                  key={circles.indexOf(circle)}
                  center={{ lat: location.latitude, lng: location.longitude }}
                />
              ))}
            </>
          )}
          <PrintControl
            ref={printEl}
            tileWait={5000}
            outputMode={'event'}
            sizeModes={['A4Landscape']}
          />
        </Map>
      </div>
  );

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
      }}
    >
      {
        loading === 'Ready'
          ? null
          : renderPDFContent()
      }
      {React.cloneElement(children, {
        ...rest,
        map,
        alarm,
        loading: loading !== 'Ready' || currentLoading,
      })}
    </div>
  );
};
