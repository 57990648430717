import React from 'react'
import InputSocketAndControllerComponent from './InputSocketAndControllerComponent'

const UsualInputComponent = ({ inputs, node, ...binds }) => {
  const hasError = (inputKey) => node.validation?.errors?.[inputKey]
  const hasWarning = (inputKey) => node.validation?.warnings?.[inputKey]

  return inputs.map((input) => (
    <div className="input" key={input.key}>
      <InputSocketAndControllerComponent
        error={hasError(input.key)}
        warning={hasWarning(input.key)}
        input={input}
        {...binds}
      />
    </div>
  ))
}

export default UsualInputComponent
