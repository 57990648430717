import Rete from '@obvious.tech/rete'

import {
    sockets,
    definitions,
} from '../../..'
import {
    getSocketFromType,
} from '../../../Sockets/utils'
import CheckboxesModalControl from '../../../Controls/Modal/CheckboxesModal'

class DecomposeComponent extends Rete.Component {
    objectInput = {
        key: 'iData',
        reteInput: (inputName = 'Any Obj') => new Rete.Input(this.objectInput.key, inputName, sockets.objectSocket),
        changeName: (node, name = 'Any Obj') => {
            const input = node.inputs.get(this.objectInput.key)
            if (!input) return
            input.name = name
        },
    }

    constructor() {
        super('Decompose')
        this.contextMenuPath = ['Objects']
        this.checkboxes = []
        this.task = {
            outputs: {
                0: 'output',
                1: 'output',
                2: 'output',
                3: 'output',
                4: 'output',
                5: 'output',
                6: 'output',
                7: 'output',
                8: 'output',
                9: 'output',
            },
        }
    }

    handleOutput = (node) => (output, checked) => {
        if (checked && !node.outputs.has(output.key)) {
            const out = new Rete.Output(
                output.key,
                output.name,
                getSocketFromType(output.type.type, output.type.entity, output.type.format),
            )
            node.addOutput(out)
        }

        if (!checked && node.outputs.has(output.key)) {
            node.outputs.get(output.key).connections.map((connection) => this.editor.removeConnection(connection))
            node.removeOutput(node.outputs.get(output.key))
        }

        node.update()
        setTimeout(() => {
            this.editor.view.updateConnections({
                node,
            })
        }, 1)
    }

    builder(node) {
        node.addInput(this.objectInput.reteInput())

        if (!node.data.entity) return

        this.building(node, node.data.entity)
    }

    building(node, entity) {
        this.objectInput.changeName(node, entity)

        entity ? this.buildEntityComponent(node, entity) : this.cleanEntityComponent(node)

        setTimeout(() => {
            node.update()
            this.editor.view.updateConnections({
                node,
            })
        }, 1)
    }

    buildEntityComponent(node, entity) {
        node.data[entity] = {}

        this.handleCheckboxControl(node, entity)
        if (node.data.entity === entity) this.buildOutputs(node)
        node.data.entity = entity
    }

    buildOutputs(node) {
        if (!node.data.checkboxes) return

        Object.keys(node.data.checkboxes)
            .filter((checkbox) => node.data.checkboxes[checkbox] === true)
            .forEach((checkboxKey) => {
                const existentOutput = node.outputs.get(checkboxKey)
                if (existentOutput) return

                const property = this.checkboxes.find((checkbox) => {
                    const same = Boolean(checkbox.key === checkboxKey)

                    return same
                })

                if (!property) return

                const out = new Rete.Output(
                    checkboxKey,
                    node.data[node.data.entity][checkboxKey],
                    getSocketFromType(property.type.type, property.type.entity, property.type.format),
                )
                node.addOutput(out)
            })
    }

    handleCheckboxControl(node, entity) {
        if (node.data.entity !== entity) {
            node.data.checkboxes = {}
        }

        const entityValues = definitions.entities[entity]
        if (!entityValues) return

        const { properties = [] } = entityValues
        this.checkboxes = properties.map((p, i) => {
            node.data[entity][i.toString()] = p.name
            return {
                name: p.name,
                type: {
                    type: p.type,
                    entity: p.entity,
                    format: p.format,
                },
                key: i.toString(),
            }
        })

        if (this.getCheckboxControl(node)) return
        node.addControl(
            new CheckboxesModalControl(this.editor, node, 'checkboxes', this.handleOutput(node), this.checkboxes, 'Outputs'),
        )
    }

    getCheckboxControl(node) {
        return node.controls.get('checkboxes')
    }

    cleanEntityComponent(node) {
        const connections = node.getConnections()
        connections.forEach((connection) => this.editor.removeConnection(connection))

        node.outputs.forEach((o) => node.removeOutput(o))

        delete node.data[node.data.entity]

        const checkboxesControl = this.getCheckboxControl(node)
        if (!checkboxesControl) return
        node.removeControl(checkboxesControl)
    }

    connected = (connection) => {
        if (connection && connection.input.key === this.objectInput.key) {
            setTimeout(() => {
                this.building(connection.input.node, connection.output.socket.name)
            })
        }
    }

    disconnected = (connection) => {
        if (connection.input.key === this.objectInput.key) {
            setTimeout(() => {
                this.building(connection.input.node)
            })
        }
    }
}

export default DecomposeComponent