// @ts-nocheck
import React, {
  Component,
  createRef,
} from 'react'
import {
  DataProvider,
} from 'react-admin'
import {
  IDiagram,
} from '@obvious.tech/constellation'

import EditorBgHelper from './helpers/EditorBgHelper'
import {
  reteEngine,
} from './components/Flow'

interface IProps {
  diagram: IDiagram
  type: 'diagram' | 'module'
  onZoom: () => void
  handleNotifyEditorError: () => void
  dataProvider: DataProvider
}

export const EDITOR_CONTAINER_CLASS = 'blueprint'

class ReteContainer extends Component<IProps> {
  blueprintContainerRef = createRef<HTMLDivElement>()

  editorRef = createRef<HTMLDivElement>()

  shouldComponentUpdate (nextProps: IProps): boolean {
    if (nextProps.type !== this.props.type) return true
    return false
  }

  async componentDidMount (): Promise<void> {
    const { diagram, type, onZoom, handleNotifyEditorError, dataProvider } = this.props

    const imageSize = await this.processImageSize(this.getImageSrc())
    const bgHelper = new EditorBgHelper(imageSize)

    const editorParams = {
      container: this.editorRef.current,
      diagram,
      bluePrintType: type,
      onZoom,
      changeBg: (zoomValue: number) => {
        bgHelper.zoomBg(zoomValue)
      },
      translateBg: ({ x, y }: { x: number, y: number }) => {
        bgHelper.translateBgThrottle({
          x,
          y,
        })
      },
      notifyErrorCb: handleNotifyEditorError,
      dataProvider,
    }

    await reteEngine(editorParams)
  }

  getImageSrc (): string {
    if (window.isNullish(this.blueprintContainerRef.current)) return ''

    return window
      .getComputedStyle(this.blueprintContainerRef.current, ':after')
      .getPropertyValue('background-image')
      .replace(/url\((['"])?(.*?)\1\)/gi, '$2')
      .split(',')[0]
  }

  async processImageSize (src: string): Promise<[number, number]> {
    return await new Promise((resolve, reject) => {
      const image = new Image()
      image.onload = () => {
        resolve([image.width, image.height])
      }
      image.onerror = reject
      image.src = src
    })
  }

  render (): JSX.Element {
    return (
      <div
        className={`${EDITOR_CONTAINER_CLASS} scaled-background`}
        ref={this.blueprintContainerRef}
      >
        <div key='Editor' ref={this.editorRef} />
      </div>
    )
  }
}

export default ReteContainer
