import React from 'react'
import {
  Box,
} from '@material-ui/core'
import {
  useEditController,
  EditContextProvider,
} from 'react-admin'
import {
  IRole,
} from '@obvious.tech/constellation'

import {
  TEntityOverrideView,
} from 'src/types'
import EditAuthorizationForm from 'src/components/Authorizations/EditAuthorizationForm'
import Container from 'src/components/Container'

import Authorizations from '../Authorizations'

interface IProps {
  resource: string
  entity: TEntityOverrideView<'edit', IRole>
  basePath: string
  id: string
}

const EditRole = ({ entity, basePath, id, resource }: IProps): JSX.Element => {
  const controllerProps = useEditController({
    basePath,
    id,
    resource,
  })

  return (
    <EditContextProvider value={controllerProps}>
      <Box display="flex" alignItems="flex-start">
        <EditAuthorizationForm
          resource={resource}
          entity={entity}
          id={id}
          basePath={basePath}
          recordNameField="name"
          title="Role"
        />

        <Container minWidth="300px" data-app="CustomDatagridContainer">
          <Authorizations id={id} />
        </Container>
      </Box>
    </EditContextProvider>
  )
}

export default EditRole
