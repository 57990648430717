import {
  makeStyles,
} from '@material-ui/styles'

export default makeStyles({
  '@global': {
    body: {
      margin: 0,
    },
    html: {
      boxSizing: 'border-box',
    },
    '*, *:before, *:after': {
      boxSizing: 'inherit',
    },
  },
})
