import React from 'react'
import {
  withStyles,
} from '@material-ui/styles'
import ToggleButton from '@material-ui/lab/ToggleButton'

const styles = theme => ({
  flowToggleButton: {
    borderRadius: '3px',
    fontSize: 15,
    padding: '6px',
    border: 'none',
    '&.Mui-selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
  },
})

class FlowToggleButton extends React.Component {
  render () {
    const { classes, children, additionalStyles, ...other } = this.props
    return (
      <ToggleButton
        {...other}
        value="1"
        className={`${classes.flowToggleButton} ${(additionalStyles || '')}`}
      >
        {children}
      </ToggleButton>
    )
  }
}

export default withStyles(styles)(FlowToggleButton)
