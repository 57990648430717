import SelectControl from '../SelectControl'

export default function UpdatedSelectControl (data) {
  const {
    emitter,
    node,
    key,
    label,
    onChange,
    options,
    defaultValue,
    run = true,
    output,
  } = data
  const selectControl = new SelectControl(
    emitter,
    node,
    key,
    label,
    onChange,
    options,
    defaultValue,
    run,
  )

  return Object.assign(selectControl, {
    output,
  })
}
