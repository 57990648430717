export const getStringFromStatusCode = statusCode => {
  switch (statusCode) {
    case 200:
      return 'Success'
    case 201:
      return 'Success'
    case 400:
      return 'Bad Request'
    case 401:
      return 'Authentication failed'
    case 403:
      return 'Access denied'
    case 404:
      return 'Not found'
    case 500:
      return 'Internal Server Error'
    case 502:
      return 'Bad Gateway'
    case 503:
      return 'Service Unavailable'
    case 504:
      return 'Gateway Timeout'
    default:
      return 'Other'
  }
}
