import React from 'react'
import {
  MenuItem,
  Box,
} from '@material-ui/core'
import {
  makeStyles,
} from '@material-ui/styles'

import SubComponents from './ItemSubComponents'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 5 * theme.spacing(),
    paddingRight: 5 * theme.spacing(),
    paddingTop: 0.5 * theme.spacing(),
    paddingBottom: 0.5 * theme.spacing(),
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
  },
  shortcut: {
    paddingLeft: 5 * theme.spacing(),
    fontWeight: theme.typography.fontWeightLight,
  },
}))

const useSpacingDividerStyles = makeStyles((theme) => ({
  root: {
    marginRight: 5 * theme.spacing(),
    marginLeft: 5 * theme.spacing(),
  },
}))

export default function Item ({
  children,
  onClick,
  closeMenu,
  shortcut,
  disabled,
  title,
  className,
}) {
  const classes = useStyles()
  const spacingDividerClasses = useSpacingDividerStyles()

  const handleClick = () => {
    onClick && onClick()
    closeMenu()
  }

  const Component = onClick ? MenuItem : Box
  const componentProps = onClick
    ? {
        onClick: handleClick,
        disabled,
      }
    : {
      }
  const hasChildren = !!React.Children.count(children)

  return (
    <Component
      className={[classes.root, className].join(' ')}
      {...componentProps}
    >
      {!!title && <div className={classes.title}>{title}</div>}
      {hasChildren && !!title && (
        <SubComponents.Divider classes={spacingDividerClasses} />
      )}
      {children}
      {!!hasChildren && !!shortcut && (
        <SubComponents.Divider classes={spacingDividerClasses} />
      )}
      {!!shortcut && <span className={classes.shortcut}>{shortcut}</span>}
    </Component>
  )
}

Object.assign(Item, SubComponents)
