import {
  Box,
  Switch,
  Typography,
} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import React, {
  FunctionComponent,
} from 'react'

import {
  IInputProps,
} from 'src/types'

const StatusInput: FunctionComponent<IInputProps<string>> = ({
  property,
  name,
  onChange,
  value,
}: IInputProps<string>) => {
  return (
    <FormGroup>
      <Box m={1}>
        <Typography color="textSecondary" display="block" variant="caption">
          {property.displayedName}
        </Typography>
        <FormControlLabel
          control={
            <Switch
              disabled={property.readOnly}
              checked={value === 'active'}
              onChange={(e) =>
                onChange(e.target.checked ? 'active' : 'stopped')
              }
              name={name}
            />
          }
          label={value}
        ></FormControlLabel>
      </Box>
    </FormGroup>
  )
}

export default StatusInput
