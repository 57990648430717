import React from 'react'
import {
  Text,
} from '@react-pdf/renderer'

import styles from './styles'
import {
  TColumn,
} from './Table'
import {
  toStyleArray,
} from './utils'

const TableCell = <
  Cols extends string,
  Row extends Record<Cols, unknown>,
>({
  column,
  columnKey,
  row,
}: {
  column: TColumn<Cols, Row[Cols]>
  columnKey: Cols
  row: Row
}): JSX.Element => {
  return (
      <Text
        style={[
          styles.cell,
          styles.contentCell,
          ...toStyleArray(column.style),
        ]}
      >
        {column.render(row[columnKey], columnKey)}
      </Text>
  )
}

export default TableCell