// @ts-nocheck
import React from 'react'
import {
  SaveButton,
  Toolbar,
  DeleteButton,
  ToolbarProps,
} from 'react-admin'
import {
  makeStyles,
} from '@material-ui/styles'
import {
  Theme,
} from '@material-ui/core'

const useToolbarStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(1)}px`,
  },
  toolbarBtn: {
    margin: `0 ${theme.spacing(1)}px`,
  },
}))

interface IProps extends ToolbarProps {
  children?: React.ReactNode
  withSave?: boolean
  withDelete?: boolean
}

const FormToolbar = ({
  children,
  withSave = true,
  withDelete = true,
  ...props
}: IProps): JSX.Element => {
  const classes = useToolbarStyles()

  return (
    <Toolbar classes={{
      toolbar: classes.toolbar,
    }} {...props}>
      {withSave && <SaveButton className={classes.toolbarBtn} />}
      {children}
      {withDelete && <DeleteButton className={classes.toolbarBtn} />}
    </Toolbar>
  )
}

export default FormToolbar
