import React from 'react';
import { List } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import extendGlobalEntity from 'src/parser/entities/extendGlobalEntity';
import Pagination from 'src/components/DataGrid/Pagination';

import CustomFilter from '../Filter/CustomFilter';
import CustomBulkActionButtons from './BulkActionButtons';
import FieldList from '../../../components/fields/FieldList';
import CustomTitle from '../Title/Title';
import ListActions from './Actions';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  main: {
    display: 'block',
  },
}));

export const CustomList = ({
  entity,
  name,
  hasEdit,
  hasShow,
  hasCreate,
  hasList,
  ...props
}) => {
  const { id, basePath, resource } = props;

  const classes = useStyles();
  const listEntity = extendGlobalEntity(entity, 'list');

  const DisplayedEmptyList = entity.list.displayedEmptyList;
  const DisplayedActions = entity.list.displayedActions;
  const DisplayedListToolbar = entity.list.displayedListToolbar;
  return (
    <List
      data-app='CustomDatagridContainer'
      empty={
        DisplayedEmptyList && (
          <DisplayedEmptyList entity={listEntity} {...props} />
        )
      }
      title={<CustomTitle entity={listEntity} {...props} />}
      basePath={basePath}
      resource={resource}
      className={classes.root}
      classes={{
        main: classes.main,
      }}
      id={id}
      key={id}
      hasEdit={hasEdit}
      hasCreate={hasCreate}
      hasList
      pagination={<Pagination />}
      hasShow={false}
      filters={<CustomFilter entity={listEntity} name={name} />}
      actions={
        DisplayedActions ? (
          <DisplayedActions
            entity={listEntity}
            hasCreate={hasCreate}
            {...props}
          />
        ) : (
          <ListActions />
        )
      }
      bulkActionButtons={
        DisplayedListToolbar ? (
          <DisplayedListToolbar entity={listEntity} {...props} />
        ) : (
          <CustomBulkActionButtons />
        )
      }
    >
      <FieldList entity={listEntity} {...props} hasEdit={hasEdit} />
    </List>
  );
};

export default CustomList;