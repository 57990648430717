import {
  arrayUnique,
} from '../utils/leaflet-easyprint/array-utils'
import menuOverride from './overrides/Menu/menu'

export default (microservices) => {
  const res = [
    ...menuOverride.additionnalRoutes,
    ...Object.keys(microservices),
  ].reduce((acc, menuKey) => {
    const {
      submenu = {
      }, hidden, ...rest
    } = menuOverride.menu[menuKey] || {
    }
    if (hidden) return acc
    const entitites = microservices[menuKey] || []
    const subMenuKeys = arrayUnique([
      ...entitites,
      ...Object.keys(submenu),
    ]).filter(
      (subMenuName) =>
        (!submenu[subMenuName] || !submenu[subMenuName].hidden) &&
        !menuOverride.hiddenEntities.includes(subMenuName),
    )
    return {
      ...acc,
      [menuKey]: {
        ...rest,
        submenu: subMenuKeys.reduce(
          (acc, entityName) => ({
            ...acc,
            [entityName]: submenu && submenu[entityName],
          }),
          {
          },
        ),
      },
    }
  }, {
  })
  return res
}
