import FormToolbar from '../../../../adminPanel/screens/Actions/FormToolbar/FormToolbar'
import React from 'react'
import PostMapButton from './PostMapButton'

export default ({ ...props }) => {
  return (
    <FormToolbar withSave={false}>
      <PostMapButton />
    </FormToolbar>
  )
}
