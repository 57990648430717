import React, { createRef } from 'react';
import AreaPlugin from 'rete-area-plugin';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, useEditContext, useNotify } from 'react-admin';

import {
  showChangeTitleDialog,
  showSaveAsDialog,
  toggleSimulationDialog,
} from '../../../redux/editor/editor.actions';
import downloadObject from '../helpers/downloadObject';
import { editor } from '../components/Flow';

import EditorHotKeys from './EditorHotKeys';
import FlowUserMenu from './FlowUserMenu';
import { PreSaveModal, UsePreSaveValidation } from '../PreSaveValidation';

import * as editorActions from '../../../redux/editor/editor.actions';
import { areBlueprintsEqual } from '../helpers/openedListFunctions';
import useErrorFetch from 'src/hooks/useErrorFetch';

const readJSON = (file, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', (event) => {
    let scheme;
    try {
      scheme = JSON.parse(event.target.result);
    } catch (error) {
      alert(`Can't parse blueprint`);
      return;
    }
    callback(scheme);
  });
  reader.readAsText(file);
};

export default function DiagramMenuWithHotkeys() {
  const inputElRef = createRef();
  const dispatch = useDispatch();
  const fetchError = useErrorFetch();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const editContext = useEditContext();

  const openedBlueprints = useSelector(({ editor }) => editor.openedBlueprints);
  const currentBlueprintId = useSelector(
    ({ editor }) => editor.currentBlueprint
  );
  const zoomValue = useSelector((state) => state.editor.zoomValue);

  const [errors, setErrors, isEditorValid] = UsePreSaveValidation();
  const currentBlueprint = openedBlueprints?.find(
    ({ id }) => id === currentBlueprintId
  );

  const handleSaveBlueprint = async () => {
    try {
      if (!currentBlueprint) {
        notify('No blueprint found', 'warning');
        return;
      }

      const params = {
        id: currentBlueprintId,
        data: { ...currentBlueprint, ...prepareBlueprint() },
      };

      const { data } = await dataProvider.update(editContext.resource, params);
      const updatedBlueprint = {
        ...data,
        updatedLocally: false,
      };

      dispatch(editorActions.updateBlueprintInOpenedList(updatedBlueprint));
      dispatch(editorActions.updateFetchedBlueprint(updatedBlueprint));
      notify(`${data.name} ${currentBlueprint.type} updated.`, 'success');
    } catch (e) {
      fetchError(e);
    }
  };

  const saveBlueprint = async () => {
    if (!isEditorValid()) return;

    await handleSaveBlueprint();
  };

  const prepareBlueprint = () => {
    const blueprint = editor.toJSON();
    const events = Object.keys(blueprint.nodes || {})
      .filter(
        (node) =>
          blueprint.nodes[node].name.indexOf('- On ') !== -1 ||
          blueprint.nodes[node].name === 'Schedule with interval'
      )
      .map((node) => blueprint.nodes[node].name);

    return {
      data: blueprint,
      events,
    };
  };

  const uploadBlueprint = (event) => {
    readJSON(event.target.files[0], (scheme) => {
      if (!areBlueprintsEqual(currentBlueprint.data, scheme)) {
        editor && editor.fromJSON(scheme);
        editorActions.updateBlueprintInOpenedList({
          ...currentBlueprint,
          ...prepareBlueprint(scheme),
          updatedLocally: true,
        });
      }
    });
  };

  const centerBlueprint = () => {
    AreaPlugin.zoomAt(editor, editor.nodes);
  };

  const zoom = (direction) => {
    const editorZoomValue = editor.view.area.transform.k;
    let newZoomValue;
    if (direction < 0) {
      newZoomValue = editorZoomValue - editorZoomValue / 6;
    } else {
      newZoomValue = Math.round((editorZoomValue + 1 / 6) * 100) / 100;
    }
    if (newZoomValue > 3) newZoomValue = 3;
    editor.view.area.zoom(newZoomValue);
  };

  function toggleMinimap() {
    const map = editor.view.container.querySelector('div.minimap');
    if (map.style.display === 'none') {
      map.style.display = 'block';
    } else {
      map.style.display = 'none';
    }
  }

  const openFileChooser = () => {
    inputElRef.current.click();
  };

  const showSimulationDialog = () => {
    dispatch(toggleSimulationDialog(true));
  };

  const showDialogForSaveAs = () => {
    dispatch(showSaveAsDialog(currentBlueprint));
  };

  const showDialogForChangeTitle = () => {
    dispatch(showChangeTitleDialog(currentBlueprint));
  };

  const handlers = {
    showDialogForSaveAs,
    toggleMinimap,
    showDialogForChangeTitle,
    showSimulationDialog,
    centerBlueprint,
    saveBlueprint,
    zoom: {
      value: Math.round(zoomValue * 100),
      zoomIn: () => {
        zoom(1);
      },
      zoomOut: () => {
        zoom(-1);
      },
    },
    openFileChooser,
    downloadObject
  };

  return (
    <>
      <EditorHotKeys {...handlers} />
      <FlowUserMenu {...handlers} />
      <PreSaveModal
        onSave={handleSaveBlueprint}
        open={Boolean(errors)}
        errors={errors}
        onClose={() => setErrors(null)}
      />
      <input hidden type="file" ref={inputElRef} onChange={uploadBlueprint} />
    </>
  );
}