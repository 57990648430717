import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { useTranslation,  } from 'react-i18next';

import shortenUUID from 'src/utils/shortenUUID'
import * as theme from './theme'
import { TAlarmReportProps } from './Provider'

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.documentHeaderBg,
    color: theme.colors.documentHeader,
    fontWeight: 'bold'
  },
  title: {
    textTransform: 'uppercase',
    fontSize: theme.fontSize.pageTitle,
    padding: theme.padding(2),
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: theme.fontSize.pageSubtitle,
    fontWeight: 'normal',
  },
})

export type TProps = TAlarmReportProps

export default ({ alarm }: TProps) => {
  const { t } = useTranslation()

  return (
    <View fixed style={styles.root}>
      <Text style={styles.title}>
        {t("alarm_report.header.title")}
      </Text>
      <Text style={styles.subtitle}>
        {`${shortenUUID(alarm.id)}`}
      </Text>
    </View>
  );
};
