import React, {
  useState,
} from 'react'
import {
  useNotify,
} from 'react-admin'
import {
  Button,
  Box,
} from '@material-ui/core'
import {
  saveAs,
} from 'file-saver'

const FileIdField = ({ name, record, resource }) => {
  const notify = useNotify()

  const [fetching, setFetching] = useState(false)

  const fetchFile = async () => {
    const url = `${window._env_.API_URL}/${resource}/${record.id}/download`
    const options = {
      headers: new Headers({
        authorization: localStorage.getItem('token'),
      }),
      method: 'GET',
    }

    try {
      setFetching(true)
      const file = await fetch(url, options)
        .then((response) => {
          if (!response.ok) {
            throw new Error()
          }

          return response.blob()
        })
        .then((blob) => window.URL.createObjectURL(blob))
      saveAs(file, 'Licence.ccp')
    } catch (e) {
      notify('Failed to download template file.', 'error')
    } finally {
      setFetching(false)
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <Button disabled={fetching} onClick={fetchFile}>
        Download
      </Button>
    </Box>
  )
}

export default FileIdField
