import React from 'react'
import {
  View,
} from '@react-pdf/renderer'

import {
  TProps,
  TColumn,
} from './Table'
import TableCell from './TableCell'
import styles, {
  getRowStyles,
} from './styles'

const TableRow = <
  Cols extends string,
  Row extends Record<Cols, unknown>,
>({
  rowKey,
  index,
  columns,
  row,
}: {
  rowKey: string | number,
  index: number,
  columns: TProps<Cols, Row>['columns'],
  row: Row,
}): JSX.Element => {
  const columnEntries = Object.entries(columns) as
    Array<[Cols, TColumn<Cols, unknown>]>

  return (
    <View
      style={[
        styles.row,
        ...getRowStyles({ rowIndex: index })
      ]}
    >
      {columnEntries.map(
        ([columnKey, column]) => (
          <TableCell {...{ columnKey, column, row }}
            key={`${columnKey}:${rowKey}`}
          />
        )
      )}
    </View>
  )
}

export default TableRow