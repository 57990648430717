import React from 'react'
import { useTranslation } from 'react-i18next'

import NoContent from '../NoContent'
import Table from '../Table'
import { TSectionContent } from '../Sections'

const Logs: TSectionContent = ({ logs }) => {
  const { t } = useTranslation()

  if (!logs.length)
    return <NoContent />

  return (
    <Table
      columns={{
        date: {
          title: t('alarm_report.logs.date'),
          render: date => {
            return t(
              'alarm_report.logs.date_format',
              { date: new Date(date) },
            )
          },
          style: {
            flex: 0.2,
          },
        },
        source: {
          title: t('alarm_report.logs.source'),
          render: v => v,
          style: {
            flex: 0.15,
          },
        },
        activity: {
          title: t('alarm_report.logs.activity'),
          render: v => v,
          style: {
            flex: 0.65,
          },
        },
      }}
      rows={
        logs.map(log => ({
          date: log.date,
          source: log.origin,
          activity: log.message,
        }))
      }
      getRowKey={log => log.date.toString()}
    />
  )
}

export default Logs