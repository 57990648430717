export default {
  palette: {
    primary: {
      main: '#4fc0e8',
      contrastText: '#fff',
    },
    background: {
      dark: '#424852',
      gray: '#636c77',
    },
    text: {
      placeholder: '#424852',
      primary: '#424852',
    },
  },
  fontFamily: 'CooperHewitt, sans-serif',
}
