// @ts-nocheck
import React from 'react'
import {
  Box,
  Divider,
  Typography,
  Theme,
  makeStyles,
} from '@material-ui/core'
import {
  Create,
  SimpleForm,
  Record,
  Toolbar,
} from 'react-admin'

import ConnectedInput from 'src/adminPanel/screens/inputs/ConnectedInput'
import EntityPropertiesList from 'src/components/EntityPropertiesList'
import {
  TEntityOverrideView,
} from 'src/types'
import CustomTitle from 'src/adminPanel/screens/Title/Title'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  noActions: {
    marginTop: 0,
  },
}))

type TProps = {
  transform: (data: Record) => Record
  resource: string
  entity: TEntityOverrideView<'create'>
  basePath: string
}

const CreateForm = ({ transform, resource, entity, basePath }: TProps) => {
  const classes = useStyles()
  return (
    <>
      <Box p={2}>
        <Typography variant="h5">Role template</Typography>
      </Box>
      <Divider />
      <Create
        title={<CustomTitle record={null} entity={entity} />}
        classes={{
          root: classes.root,
          noActions: classes.noActions,
        }}
        transform={transform}
        resource={resource}
        basePath={basePath}
      >
        <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
          {/* @ts-expect-error */}
          <EntityPropertiesList
            entity={entity}
            childProps={{
            }}
            render={({ propertyKey, property, entity }) => (
              <ConnectedInput
                classes={{
                }}
                id={propertyKey}
                key={propertyKey}
                name={propertyKey}
                resource={resource}
                property={property}
              />
            )}
          />
        </SimpleForm>
      </Create>
    </>
  )
}

export default CreateForm
