import React, { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { SaveButton, useRedirect, useNotify } from 'react-admin';
import { connect } from 'react-redux';
import useErrorFetch from 'src/hooks/useErrorFetch';

const PostMapButton = ({ postMap, basePath, redirect, ...props }) => {
    const fetchError = useErrorFetch();
    const redirectTo = useRedirect();
    const notify = useNotify();
    // get values from the form
    const formState = useFormState();
    const { name, manifest = {}, fileId } = formState.values;
    const { boundingBox = {}, origin = {}, scale, zoomLevel } = manifest ?? {};
    const { north, south, east, west } = boundingBox;
    const { latitude, longitude, altitude } = origin;

    const isFormValid = useCallback(() => {
        const requiredNumberValues = [zoomLevel, latitude, longitude, scale, north, west, south, east];
        const invalidNumbers = requiredNumberValues.filter((value) => isNaN(value));

        return Boolean(!invalidNumbers.length && name && fileId);
    }, [zoomLevel, latitude, longitude, scale, north, west, south, east, name, fileId]);

    const handleClick = useCallback(async() => {
        if (!isFormValid()) return;

        const args = [
            localStorage.getItem('token'),
            name,
            latitude,
            longitude,
            altitude,
            scale,
            north,
            west,
            south,
            east,
            zoomLevel,
            fileId,
        ];

        const res = await postMap.call(...args);

        if (res?.statusCode >= 400) {
            const message = 'Failed to create Map.';
            fetchError({ status: res.statusCode, message });
            return;
        }

        // call dataProvider.update() manually
        //redirectTo(redirect, basePath, passport.id, passport);
        notify('Map is uploading', 'info', {
            smart_count: 1,
        });
        redirectTo('/cartography/api/Map/');
    }, [
        altitude,
        east,
        latitude,
        longitude,
        name,
        north,
        notify,
        postMap,
        redirectTo,
        scale,
        south,
        west,
        zoomLevel,
        fileId,
        fetchError,
        isFormValid,
    ]);

    return ( <
        SaveButton {...props }
        label = "Post Map"
        redirect = "list"
        submitOnEnter = { false }
        variant = "text"
        handleSubmitWithRedirect = { handleClick }
        disabled = {!isFormValid() }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        postMap: state.api?.Map?.functions?.upload,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ConnectedSaveButton = connect(mapStateToProps, mapDispatchToProps)(PostMapButton);

export default ConnectedSaveButton;