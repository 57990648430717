import React from 'react'
import {
  useInput,
  useTranslate,
} from 'react-admin'
import {
  ColorPicker,
} from 'material-ui-color'
import {
  Box,
  TextField,
} from '@material-ui/core'

const CustomColorInput = ({ property, name, valid, error, ...props }) => {
  const translate = useTranslate()

  const { input: { onChange, value } } = useInput({
    source: name,
    ...props,
  })

  const handleChange = (e) => {
    const color = e.hex ? `#${e.hex}` : e
    onChange(color)
  }

  const handleHelperText = () => {
    if (!error) return ''

    return translate(error.message ? error.message : error)
  }

  return (
    <Box display="flex" alignItems="flex-end">
      <ColorPicker value={value} onChange={handleChange} hideTextfield />

      <TextField
        value={value}
        label={property.displayedName ?? name}
        helperText={handleHelperText()}
        onChange={handleChange}
      />
    </Box>
  )
}

export default CustomColorInput
