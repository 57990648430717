const flowShortcuts = {
  SHOW_NEW_BLUEPRINT_DIALOG: 'ctrl+alt+n',
  SAVE_AS: 'ctrl+shift+s',
  SAVE: 'ctrl+s',
  DOWNLOAD: 'ctrl+shift+alt+d',
  UPLOAD: 'ctrl+alt+u',
  RENAME: 'f2',
  SELECT_ALL_NODES: 'ctrl+shift+a',
  CLEAN_BOARD: 'ctrl+shift+l',
  DELETE_ALL_LINKS: 'ctrl+shift+j',
  DELETE_LINKS: 'ctrl+alt+j',
  DUPLICATE_NODES: 'ctrl+alt+d',
  ZOOM_IN: 'alt++',
  ZOOM_OUT: 'alt+-',
  CENTER: 'ctrl+alt+0',
  SIMULATE: 'ctrl+alt+r',
  DELETE_NODES: ['backspace', 'del'],
  TOGGLE_MAP: 'ctrl+alt+m',
  UNDO: 'ctrl+z',
  REDO: 'ctrl+y',
}

export default flowShortcuts
