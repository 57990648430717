// @ts-nocheck
import * as React from 'react'
import {
  Route,
} from 'react-router-dom'
import {
  RouteWithoutLayout,
} from 'react-admin'

import Dashboard from 'src/adminPanel/screens/Custom/DashBoard'
import Applications from 'src/adminPanel/screens/Custom/Applications'
import GroupList from 'src/adminPanel/screens/Custom/Group/GroupList'
import {
  withAuthentication,
} from 'src/utils/hocs'
import ExternalAlarmPDF from 'src/parser/entities/Alarm/ExternalAlarmPDF'

export default [
  <Route
    exact
    path="/dashboard"
    component={withAuthentication(Dashboard)}
    key="dashboard_route"
  />,
  <Route
    exact
    path="/apps"
    component={withAuthentication(Applications)}
    key="apps_route"
  />,
  <Route
    exact
    path="/groups"
    component={withAuthentication(GroupList)}
    key="groups_route"
  />,
  <RouteWithoutLayout
    exact
    noLayout
    path="/pdf/alarm/:id"
    component={ExternalAlarmPDF}
    key="alarm_pdf_route"
  />,
]
