import {
  TEntityOverride,
} from 'src/types'

const defaultRotation = {
  yaw: 0,
  pitch: 0,
  zoom: 1,
}

const override: TEntityOverride = {
  titleField: 'displayName',
  global: {
    properties: {
      fileId: {
        hidden: true,
      },
      group: {
        displayedName: 'folder',
      },
      groups: {
        type: 'groups',
      },
    },
  },
  list: {
    properties: {
      location: {
        hidden: true,
      },
      rotation: {
        hidden: true,
      },
    },
  },
  create: {
    properties: {
      rotation: {
        defaultValue: defaultRotation,
      },
    },
  },
}

export default override
