const getTopicList = (api) => () => {
  let entities = Object.keys(api.paths)
    .map((path) => ({
      microservice: path.split('/')[1],
      entity: path.split('/')[3],
    }))
    .filter(
      (entity, index, currentArray) =>
        currentArray.findIndex(
          (tmp) =>
            entity.microservice === tmp.microservice &&
            entity.entity === tmp.entity
        ) === index
    );
  return entities.reduce((acc, entity, index) => {
    let firstSameEntityIndex = entities.findIndex(
      (tmp) => tmp.entity === entity.entity
    );
    let secondSameEntityIndex = entities
      .slice(firstSameEntityIndex + 1)
      .findIndex((tmp) => {
        return tmp.entity === entity.entity;
      });
    if (firstSameEntityIndex !== index || -1 !== secondSameEntityIndex) {
      return [
        ...acc,
        `Event${
          entity.microservice.charAt(0).toUpperCase() +
          entity.microservice.slice(1)
        }${entity.entity}`,
      ];
    } else {
      return [...acc, `Event${entity.entity}`];
    }
  }, []);
};

export default async (apiPromise) => {
  const api = await apiPromise;
  return {
    getTopicList: getTopicList(api),
  };
};
