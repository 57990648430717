import {
  TEntityOverride,
} from 'src/types'

const override: TEntityOverride = {
  titleField: 'name',
  global: {
    properties: {
      groups: {
        type: 'groups',
      },
    },
  },
}

export default override
