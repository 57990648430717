import {
  NodeEditor,
} from '@obvious.tech/rete'

const bindEvents = (editor: NodeEditor) => {
  editor.bind('selectAllNodes')
  editor.bind('allNodesSelected')
  editor.bind('componentDidMount')
}

export default bindEvents
